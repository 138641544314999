import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./EventDetails.css";
import { debounce, functions, result, set } from "lodash";
import { emailReg } from "../../constants/regex";
import DatePicker from "react-datepicker";
import Cropper from "react-easy-crop";
import { ReactComponent as ClockFilled } from "../../assets/svgs/ClockFilled.svg";
import { ReactComponent as CalendarFilled } from "../../assets/svgs/CalendarFilled.svg";
import { ReactComponent as LocationFilled } from "../../assets/svgs/LocationFilled.svg";
import { ReactComponent as WarningFilled } from "../../assets/svgs/WarningFilled.svg";
import { ReactComponent as BinFilled } from "../../assets/svgs/BinFilled.svg";
import { ReactComponent as EditFilled } from "../../assets/svgs/EditFilled.svg";
import { ReactComponent as AddFilled } from "../../assets/svgs/AddFilled.svg";
import { ReactComponent as RecordCircle } from "../../assets/svgs/RecordCircle.svg";
import { ReactComponent as CheckBoxCheckedCircle } from "../../assets/svgs/CheckBoxCheckedCircle.svg";
import { ReactComponent as CheckBoxUncheckCircle } from "../../assets/svgs/CheckBoxUncheckCircle.svg";
import { ReactComponent as CheckBoxUncheckedCircleBlue } from "../../assets/svgs/CheckBoxUncheckedCircleBlue.svg";
import { ReactComponent as SuccessFilledGreen } from "../../assets/svgs/SuccessFilledGreen.svg";
import { ReactComponent as SuccessFilledWhite } from "../../assets/svgs/SuccessFilledWhite.svg";
import { ReactComponent as MailChimpIcon } from "../../assets/svgs/MailChimp.svg";
import { ReactComponent as EmailAttendee } from "../../assets/svgs/EmailAttendee.svg";
import { ReactComponent as CalendarSmall } from "../../assets/svgs/CalendarSmall.svg";
import { ReactComponent as SocialSmall } from "../../assets/svgs/SocialSmall.svg";
import { ReactComponent as Copied } from "../../assets/svgs/Copied.svg";
import EventDetailsComponent from "../../Component/CreateEvent/EventDetails/EventDetails";
import VenueAddress from "../../Component/CreateEvent/Venue/VenueAddress";
import VenueMap from "../../Component/CreateEvent/Venue/VenueMap";
import SocialLink from "../../Component/BusinessSignUp/SocialLink";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import RadioButton from "../../Component/Common/Controls/RadioButton";
import DropdownInput from "../../Component/Common/DropdownInput/DropdownInput";
import InfoTags from "../../Component/Common/InfoTags/InfoTags";
import TotalTickets from "../../Component/CreateEvent/Ticket/TotalTickets";
import Ticket from "../../Component/CreateEvent/Ticket/Ticket";
import Guests from "../../Component/CreateEvent/Guests/Guests";
import Discount from "../../Component/CreateEvent/Discount/Discount";
import Timing from "../../Component/CreateEvent/EventDetails/Timing";
import KYC from "../../Component/CreateEvent/Requirement/KYC";
import Age from "../../Component/CreateEvent/Requirement/Age";
// import OtherRequirements from "../../Component/CreateEvent/Requirement/OtherRequirements";
import RefundPolicy from "../../Component/CreateEvent/Requirement/RefundPolicy";
import TicketAddOn from "../../Component/CreateEvent/TicketAddOn/TicketAddOnNew";
import Fees from "../../Component/CreateEvent/Fees/Fees";
import SummaryLabeledView from "../../Component/CreateEvent/SummaryLabeledView/SummaryLabeledView";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import Text from "../../Component/Common/Text";
import Tags from "../../Component/Common/Tags/Tags";
import MenuLink from "../../Component/Common/MenuLink/MenuLink";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import EventStatistics from "../../Component/EventDetails/EventStatistics/EventStatistics";
import CentralModalWithInput from "../../Component/Common/CentralModals/CentralModalWithInput";
import CentralCustomModal from "../../Component/Common/CentralModals/CentralCustomModal";
import CentralModalSuccess from "../../Component/Common/CentralModals/CentralModalSuccess";
import CentralModalSuccessV2 from "../../Component/Common/CentralModals/CentralModalSuccessV2";

import GetCroppedImage from "../../Component/Common/GetCroppedImage";
import {
  getEventStatistics,
  updateEvent,
  deleteEvent,
  getEventDetails,
  getEventList,
  GetEventTicketTypes,
  GetEventStatisticsByTicketType,
  emailInUse,
  addGuestList,
  getGuestListToSendRevokeTickets,
  removeGuestList,
  getDraftEventExist,
  updateEventTicketSellingFlag,
  getUrlKycVerify,
  StripeAccountConnect,
  validateGuestTicketQuantity,
  venueUserInUser,
  createEventDiscountCode,
  deleteEventDiscountCode,
  UpdateEventDiscountCode,
  getStripeAccountStatus,
  getUserDetails,
  deleteInactiveDraftEvent,
  updateInactiveDraftEvent,
  getInactiveDraftEventList,
  createEvent,
  sendEventEmailToAttendees,
  getEventOrganiserEmailHistory,
  DisconnectMailChimpAccount,
  getMailChimpAccountDetails,
  connectMailChimpAccount,
  getMailChimpLoginURL,
  duplicateEvent,
} from "../../services/api";
import {
  BANNER_CONTENT,
  SELECTED_EVENT_CATEGORY_DATA,
  SHOW_ACTIVITY_INDICATOR,
  EVENT_DATA,
  SHOW_DRAFT,
  SELECTED_EVENT_DATA,
  USER_DATA,
} from "../../actions";

function EventDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const userInfo = useSelector((state) => state.userData?.userData);
  const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const countryList = useSelector((state) => state.countryData?.countryData);
  const categoryList = useSelector(
    (state) => state.eventCategoryData?.eventCategoryData
  );
  const selectedEventCategoryData = useSelector(
    (state) => state.selectedEventCategoryData?.selectedEventCategoryData
  );

  const eventList = useSelector((state) => state.eventData?.eventData);

  const { state } = location;
  let eventId = state?.eventId;
  let nonActiveEventDetailsParams = state?.nonActiveEventDetails || false;
  if (!eventId) {
    const parts = window.location.pathname.split("/");
    eventId = parseInt(parts[parts.length - 1], 10);
  }
  const type = state?.type;

  function getTimeDiffStatus(startDate, endDate) {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (currentDate < start) {
      // Event is upcoming
      const diffTime = start - currentDate;
      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diffTime % (1000 * 60)) / 1000);

      if (days > 0) {
        return "Upcoming";
      } else {
        return `${hours}h ${minutes}m ${seconds}s`;
      }
    } else if (currentDate >= start && currentDate <= end) {
      // Event is live
      return "Live";
    } else {
      // Event has expired
      return "Expired";
    }
  }

  const checkDaysToGo = (targetDate) => {
    const currentTime = new Date();
    const targetDateValue = new Date(targetDate);
    const timeDifference = targetDateValue - currentTime;
    const days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return days + " days to go";
  };
  const [isLoading, setIsLoading] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventDescriptionApp, setEventDescriptionApp] = useState("");
  const [eventCurrency, setEventCurrency] = useState("Select an option");
  const [eventCurrencyId, setEventCurrencyId] = useState("");
  const [venueName, setVenueName] = useState("");
  const [venueDescription, setVenueDescription] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [countryId, setCountryId] = useState("");
  const [city, setCity] = useState("");
  const [venueAddress, setVenueAddress] = useState("");
  const [venueSearchAddress, setVenueSearchAddress] = useState("");
  const [gateKeeperUserName, setGateKeeperUserName] = useState("");
  const [gateKeeperPassword, setGateKeeperPassword] = useState("");
  const [gateKeeperUserNameAssistiveText, setGateKeeperUserNameAssistiveText] =
    useState("");
  const [kycType, setKycType] = useState("");
  const [minimumAge, setMinimumAge] = useState("-");
  const [entranceList, setEntranceList] = useState([]);
  const [totalTicketQuantity, setTotalTicketQuantity] = useState("");
  const [ticketList, setTicketList] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [bannerImageBase64, setBannerImageBase64] = useState("");
  const [eventCategory, setEventCategory] = useState(["Select an option"]);
  const [eventCategoryId, setEventCategoryId] = useState([]);
  const [startDate, setStartDate] = useState("DD/MM/YYYY");
  const [endDate, setEndDate] = useState("DD/MM/YYYY");
  const [startTime, setStartTime] = useState("00:00 ");
  const [endTime, setEndTime] = useState("00:00 ");
  const [eventStatisticsObj, setEventStatisticsObj] = useState(false);
  const [selectedEventOptionIndex, setSelectedEventOptionIndex] = useState(0);
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0);
  const [selectedStageReleaseIndex, setSelectedStageReleaseIndex] = useState(0);
  const [
    selectedGuestIndexToSendOrRevokeTicket,
    setSelectedGuestIndexToSendOrRevokeTicket,
  ] = useState(0);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [shouldShowSummaryEventDetails, setShouldShowSummaryEventDetails] =
    useState(true);
  const [shouldShowSummaryTiming, setShouldShowSummaryTiming] = useState(true);
  const [shouldShowSummaryVenueAbout, setShouldShowSummaryVenueAbout] =
    useState(true);
  const [shouldShowSummaryVenueAddress, setShouldShowSummaryVenueAddress] =
    useState(true);
  const [shouldShowSummaryVenueMap, setShouldShowSummaryVenueMap] =
    useState(true);
  const [shouldShowSummaryEntrance, setShouldShowSummaryEntrance] =
    useState(true);
  const [shouldShowSummaryKYC, setShouldShowSummaryKYC] = useState(true);
  const [shouldShowSummaryRefundPolicy, setShouldShowSummaryRefundPolicy] =
    useState(true);
  const [shouldShowSummaryFees, setShouldShowSummaryFees] = useState(true);
  const [shouldShowSummaryTotalTickets, setShouldShowSummaryTotalTickets] =
    useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isTicketOrAddOnDeleted, setIsTicketOrAddOnDeleted] = useState(false);
  const [newTicketCategoryValue, setNewTicketCategoryValue] = useState("");
  // const [capacity, setCapacity] = useState(10000000);
  const [isRefundAcceptable, setIsRefundAcceptable] = useState(null);
  const [addFeesToTicketPrice, setAddFeesToTicketPrice] = useState(null);
  const [ticketAddOnList, setTicketAddOnList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([
    { name: "General Admission" },
    { name: "VIP Admission" },
    { name: "Early Bird" },
    { name: "Full Weekend Admission" },
    { name: "Full Weekend Camping" },
  ]);
  const [addOnCategoryList, setAddOnCategoryList] = useState([]);
  const [selectedAddOnIndex, setSelectedAddOnIndex] = useState(0);
  const [item, setItem] = useState();
  const [eventType, setEventType] = useState("");
  const [ticketSellingEnabled, setTicketSellingEnabled] = useState(false);
  const [guestList, setGuestList] = useState([
    {
      ticketCategory: "Select an option",
      email: "",
      status: "notActive",
      emailError: false,
      quantityError: false,
      id: null,
      eventTicketTypeId: null,
      quantity: "-",
      ticketIds: [],
      guestTicketIsPending: false,
    },
  ]);
  const [discountList, setDiscountList] = useState([]);
  const [discountCodeName, setDiscountCodeName] = useState("");
  const [discountAmountType, setDiscountAmountType] = useState(0);
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountEligibilityType, setDiscountEligibilityType] = useState(null);
  const [discountEligibility, setDiscountEligibility] = useState([
    { name: "Select an option", id: 1 },
  ]);
  const [discountMaxUsesType, setDiscountMaxUsesType] = useState(null);
  const [discountMaxUses, setDiscountMaxUses] = useState("");
  const [discountStartDate, setDiscountStartDate] = useState("DD/MM/YYYY");
  const [discountStartTime, setDiscountStartTime] = useState("00:00 ");
  const [discountEndDate, setDiscountEndDate] = useState("DD/MM/YYYY");
  const [discountEndTime, setDiscountEndTime] = useState("00:00 ");
  const [selectedDiscountIndex, setSelectedDiscountIndex] = useState(null);
  const [
    isDatePickerVisibleDiscountStartDate,
    setIsDatePickerVisibleDiscountStartDate,
  ] = useState(false);
  const [
    isDatePickerVisibleDiscountEndDate,
    setIsDatePickerVisibleDiscountEndDate,
  ] = useState(false);

  const [selectedGuestEventTicketTypeId, setSelectedGuestEventTicketTypeId] =
    useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);
  const [isGuestModalVisible, setIsGuestModalVisible] = useState(false);
  const [confirmTicketToGuest, setConfirmTicketToGuest] = useState(false);
  const [guestAssetOptions, setGuestAssetOptions] = useState([]);
  const [selectedSegmentGuestIndexAsset, setSelectedSegmentGuestIndexAsset] =
    useState(0);

  const [ticketTypesList, setTicketTypesList] = useState([
    { name: "All sales", id: undefined },
  ]);
  const [selectedTicketType, setSelectedTicketType] = useState("All sales");

  const [isCopiedURLVisible, setIsCopiedURLVisible] = useState(false);

  const [isComposeEmailActive, setIsComposeEmailActive] = useState(false);
  const [attendeeEmailList, setAttendeeEmailList] = useState([]);
  const [emailComposeSubject, setEmailComposeSubject] = useState("");
  const [emailComposeMessage, setEmailComposeMessage] = useState("");
  const [isEmailComposeAgreed, setIsEmailComposeAgreed] = useState(false);

  const [sideBarReloadKey, setSideBarReloadKey] = useState(0);
  const [isCropModalVisible, setIsCropModalVisible] = useState(false);
  const [toCropImage, setToCropImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [mailchimpUserInfo, setMailchimpUserInfo] = useState(false);

  const [eventOptions, setEventOptions] = useState([
    { name: "Overview", id: 1 },
    { name: "Event details", id: 2 },
    { name: "Venue", id: 3 },
    { name: "Requirements", id: 4 },
    { name: "Fees", id: 5 },
    { name: "Tickets", id: 6 },
    { name: "Add Ons", id: 7 },
  ]);

  const discountOptions = [
    { name: "Percentage", id: 1 },
    { name: "Fixed amount", id: 2 },
  ];

  const [discountEligibilityOptions, setDiscountEligibilityOptions] = useState(
    []
  );

  const [isStripeDetailsSubmitted, setIsStripeDetailsSubmitted] =
    useState("no");
  const [isReadyToPublishModalVisible, setIsReadyToPublishModalVisible] =
    useState(false);

  const discountMaxUsesOptions = [
    {
      text: "Set a maximum use limit for code",
      value: 1,
    },
    {
      text: "Set a limit to one per customer",
      value: 2,
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [instanceComponentOptions, setInstanceComponentOptions] = useState([
    {
      label: "[Date]",
      subText: "",
      icon: <CalendarFilled />,
    },
    {
      label: "",
      subText: "",
      icon: <LocationFilled />,
    },
    {
      label: "",
      subText: "",
      icon: null,
    },
  ]);

  const [isAddBookingFees, setIsAddBookingFees] = useState(0);
  const [bookingPercentValue, setBookingPercentValue] = useState(5);
  const [nonActiveEventDetails, setNonActiveEventDetails] = useState({});

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const mailChimpCode = query.get("code");

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await GetCroppedImage(
        toCropImage,
        croppedAreaPixels
      );
      console.log(
        "donee",
        { croppedImage },
        selectedAddOnIndex,
        isCropModalVisible
      );
      // setBannerImage(croppedImage);
      if (isCropModalVisible === "posterCrop") {
        setBannerImage(croppedImage);
      } else {
        let tempArray = [...ticketAddOnList];
        tempArray[selectedAddOnIndex].addOnImage = croppedImage;
        setTicketAddOnList(tempArray);
      }
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    let filteredArr = [];
    filteredArr = [
      { name: "All tickets and add ons", id: -1 },
      { name: "All tickets", id: -2 },
      { name: "All add ons", id: -3 },
      ...addOnCategoryList,
      ...ticketAddOnList,
    ].filter(
      (item1) => !discountEligibility.some((item2) => item2.name === item1.name)
    );
    console.log("filteredArr", filteredArr, discountEligibility);
    setDiscountEligibilityOptions(filteredArr);
  }, [addOnCategoryList, ticketAddOnList, discountEligibility]);

  useEffect(() => {
    const filteredList = ticketCategoryList.filter(
      (category) => category.name !== "+ Create category"
    );

    setTicketCategoryList([...filteredList, { name: "+ Create category" }]);
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("kycBeingVerified") === "true" &&
      userInfo?.kycStatus === "InProgress"
    ) {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Your account is currently being reviewed. This may take up to x days to complete",
          headingText: "Verification pending...",
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    localStorage.setItem("kycBeingVerified", false);
  }, [userInfo]);

  useEffect(() => {
    // localStorage.getItem("isAccountVerificationRunning") === "true" &&
    getUserDetailsRequest();
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("isAccountVerificationRunning") === "true" &&
      isStripeDetailsSubmitted === true &&
      userInfo?.kycStatus === "Verified"
    ) {
      localStorage.setItem("isAccountVerificationRunning", false);
      eventType === "draft1" && setIsReadyToPublishModalVisible(true);
    }

    if (
      isStripeDetailsSubmitted === true &&
      userInfo?.kycStatus === "Verified" &&
      // getTimeDiffStatus(item?.startDate, item?.endDate) !== "Expired" &&
      // item?.isPublished == true &&
      !eventOptions.some((option) => option.name === "Guest list") &&
      !eventOptions.some((option) => option.name === "Marketing") &&
      !eventOptions.some((option) => option.name === "Communications")
    ) {
      const tempEventOptions = [...eventOptions];
      tempEventOptions.push({ name: "Guest list", id: 8 });
      tempEventOptions.push({ name: "Marketing", id: 9 });
      tempEventOptions.push({ name: "Communications", id: 9 });
      setEventOptions(tempEventOptions);
    }

    // if (
    //   (getTimeDiffStatus(item?.startDate, item?.endDate) === "Expired" ||
    //     item?.isPublished !== true) &&
    //   eventOptions.some((option) => option.name === "Guest list") &&
    //   eventOptions.some((option) => option.name === "Marketing") &&
    //   eventOptions.some((option) => option.name === "Communications")
    // ) {
    //   const tempEventOptions = [...eventOptions];
    //   let tempEventOptionsRefined = tempEventOptions.filter(
    //     (item) =>
    //       item.name !== "Guest list" &&
    //       item.name !== "Marketing" &&
    //       item.name !== "Communications"
    //   );
    //   setEventOptions(tempEventOptionsRefined);
    // }
  }, [isStripeDetailsSubmitted, userInfo, item]);

  // useEffect(() => {
  //   return () => {
  //     onValidateGuestEmail.cancel();
  //     setIsLoading(false);
  //   };
  // }, []);

  useEffect(() => {
    console.log("nonActiveEventDetails:::1234", nonActiveEventDetails);
    if (!nonActiveEventDetails?.draftEventId) {
      // dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      setIsLoading(true);
      debouncedGetEventDetails(eventId, type);

      return () => {
        debouncedGetEventDetails.cancel();
        setIsLoading(false);
      };
    } else {
      setItem(nonActiveEventDetails);
      initializeValue(nonActiveEventDetails, "");
    }
  }, [eventId, nonActiveEventDetails]);

  useEffect(() => {
    if (nonActiveEventDetailsParams?.draftEventId) {
      getDraftEventData([]);
    }
  }, [nonActiveEventDetailsParams]);

  const debouncedGetEventDetails = debounce((eventId, type) => {
    getEventDetailsRequest(eventId, type);
  }, 2000);

  useEffect(() => {
    console.log("selectedTicketType:::1234", selectedTicketType);
    selectedTicketType !== "All sales" &&
    selectedTicketType !== "All add-ons" &&
    selectedTicketType !== "All tickets" &&
    eventId !== undefined
      ? GetEventStatisticsByTicketTypeRequest(
          eventId,
          ticketTypesList.find(
            (ticketType) => ticketType.name === selectedTicketType
          )?.id
        )
      : getEventStatisticsRequestForAll(eventId);
  }, [selectedTicketType]);

  useEffect(() => {
    selectedEventCategoryData
      ? setSelectedEventOptionIndex(selectedEventCategoryData)
      : setSelectedEventOptionIndex(0);
  }, [selectedEventCategoryData]);

  useEffect(() => {
    return () => {
      dispatch({
        type: SELECTED_EVENT_CATEGORY_DATA,
        payload: 0,
      });
    };
  }, []);

  useEffect(() => {
    (isTicketOrAddOnDeleted === "addOn" ||
      isTicketOrAddOnDeleted === "tickets") &&
      onSaveClicked(isTicketOrAddOnDeleted);
    setIsTicketOrAddOnDeleted(false);
  }, [isTicketOrAddOnDeleted]);

  const showBanner = (msg, error) => {
    dispatch({
      type: BANNER_CONTENT,
      payload: {
        text: msg,
        headingText: "",
        showBanner: true,
        type: error ? "Error" : "Success",
        withClose: true,
        withIcon: true,
        position: "top",
        width: 391,
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const imageBlob = await fetchImage(bannerImage);
        const base64Data = await blobToBase64(imageBlob);
        setBannerImageBase64(base64Data);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }
    bannerImage !== "" && fetchData();
  }, [bannerImage]);

  useEffect(() => {
    async function fetchAddOnData() {
      try {
        const imageBlob = await fetchImage(
          ticketAddOnList[selectedAddOnIndex]?.addOnImage
        );
        const base64Data = await blobToBase64(imageBlob);
        let tempArray = [...ticketAddOnList];
        tempArray[selectedAddOnIndex].addOnBase64Image = base64Data;
        setTicketAddOnList(tempArray);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }

    ticketAddOnList[selectedAddOnIndex]?.addOnImage !== "" && fetchAddOnData();
  }, [ticketAddOnList[selectedAddOnIndex]?.addOnImage]);

  useEffect(() => {
    if (mailChimpCode) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const newQuery = new URLSearchParams(location.search);
      newQuery.delete("code");
      const newUrl = `${window.location.pathname}?${newQuery.toString()}`;
      window.history.replaceState(null, "", newUrl);
      setTimeout(() => {
        connectMailChimpAccountRequest(mailChimpCode);
      }, 1000);
    } else {
      setTimeout(() => {
        getMailChimpAccountDetailsRequest();
      }, 2000);
    }
  }, [mailChimpCode]);

  const deleteEventRequest = async (willGoHome) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = nonActiveEventDetails?.draftEventId
      ? await deleteInactiveDraftEvent({
          draftEventId: item?.draftEventId,
        })
      : await deleteEvent({
          eventId: item?.eventId,
        });
    if (result.status) {
      willGoHome && showBanner(eventName + ` has been deleted successfully.`);
      willGoHome &&
        getEventData(eventType === "draft1" ? false : true, true, false);
    } else {
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getEventData = async (isPublished, isDeleting, newEventId) => {
    let isoStartDateString =
      startDate !== "DD/MM/YYYY" && startTime !== "00:00 "
        ? getISOStringDate(`${startDate} ${startTime}`)
        : "";

    let isoEndDateString =
      endDate !== "DD/MM/YYYY" && endTime !== "00:00 "
        ? getISOStringDate(`${endDate} ${endTime}`)
        : "";

    const result = await getEventList({
      isPublished: isPublished,
      type:
        isPublished === false
          ? "All"
          : getTimeDiffStatus(isoStartDateString, isoEndDateString) ===
            "Expired"
          ? "Past"
          : "Current",
    });
    eventType === "draft1" &&
      isDeleting &&
      dispatch({
        type: SHOW_DRAFT,
        payload: result?.response?.length > 0 ? true : false,
      });

    if (result.status) {
      dispatch({ type: EVENT_DATA, payload: result.response });
      if (isDeleting === true) {
        localStorage.setItem("selectedSegmentIndex", 0);
      } else {
        setSideBarReloadKey((prevKey) => prevKey + 1);

        navigate(`/EventDetails/${newEventId ? newEventId : eventId}`, {
          state: {
            eventId: newEventId ? newEventId : eventId,
          },
        });
        setSelectedTicketType("All sales");

        localStorage.setItem(
          "selectedSegmentIndex",
          getTimeDiffStatus(isoStartDateString, isoEndDateString) === "Expired"
            ? 1
            : 0
        );

        if (newEventId) {
          dispatch({
            type: SELECTED_EVENT_DATA,
            payload:
              result.response?.findIndex(
                (item) => item?.eventId === newEventId
              ) + 3,
          });
        } else {
          dispatch({
            type: SELECTED_EVENT_DATA,
            payload:
              result.response?.findIndex((item) => item?.eventId === eventId) +
              3,
          });
        }

        checkForDraft();
      }

      isDeleting === true && getCurrentEventData(isDeleting);
    } else {
      localStorage.setItem("selectedSegmentIndex", 0);
      dispatch({ type: EVENT_DATA, payload: [] });
      getCurrentEventData(isDeleting);
    }
  };

  const getDraftEventData = async (currentDraftItem) => {
    const result = await getEventList({
      isPublished: false,
      type: "All",
    });
    if (result.status) {
      getInactiveDraftEventListRequest(result.response, currentDraftItem);
    } else {
      getInactiveDraftEventListRequest([], currentDraftItem);
    }
  };

  const getInactiveDraftEventListRequest = async (
    existingDraftList,
    currentDraftItem
  ) => {
    const result = await getInactiveDraftEventList({});
    if (result.status) {
      // setItem(currentDraftItem);
      // initializeValue(currentDraftItem, "");
      dispatch({
        type: EVENT_DATA,
        payload: [...existingDraftList, ...result.response],
      });

      setNonActiveEventDetails(
        result?.response?.length > 0 &&
          result.response?.find(
            (x) => x.draftEventId === nonActiveEventDetailsParams?.draftEventId
          )
      );
    } else {
      dispatch({ type: EVENT_DATA, payload: existingDraftList });
    }
    setIsLoading(false);
  };

  const checkForDraft = async () => {
    const result = await getDraftEventExist({});
    dispatch({
      type: SHOW_DRAFT,
      payload: result?.response?.count > 0 ? true : false,
    });
  };

  const getCurrentEventData = async () => {
    const result = await getEventList({
      isPublished: true,
      type: "Current",
    });
    if (result.status) {
      localStorage.setItem("selectedSegmentIndex", 0);
      dispatch({ type: EVENT_DATA, payload: result.response });
      dispatch({
        type: SELECTED_EVENT_DATA,
        payload: 0,
      });
      navigate("/Home");
    } else {
      localStorage.setItem("selectedSegmentIndex", 0);
      dispatch({ type: EVENT_DATA, payload: [] });
      dispatch({
        type: SELECTED_EVENT_DATA,
        payload: 0,
      });
      navigate("/Home");
    }
  };

  const checkValidationUserName = async (value) => {
    const result = await venueUserInUser({ userName: value });
    if (result.response === false) {
      setGateKeeperUserNameAssistiveText("");
    } else {
      setGateKeeperUserNameAssistiveText("Username already exists");
    }
  };

  const getUserDetailsRequest = async () => {
    const result = await getUserDetails();
    if (result.status) {
      dispatch({ type: USER_DATA, payload: result.response });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      result?.response?.stripeAccountId &&
      result?.response?.stripeAccountId !== null
        ? getStripeAccountStatusRequest(result.response.stripeAccountId)
        : setIsStripeDetailsSubmitted("not");
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getStripeAccountStatusRequest = async (stripeAccountId) => {
    const result = await getStripeAccountStatus({
      stripeAccountId: stripeAccountId,
    });
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setIsStripeDetailsSubmitted(result.response.isDetailsSubmitted);
    } else {
      setIsStripeDetailsSubmitted("not");
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  function getISOStringDate(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const isoString = `${year}-${month}-${day}T${timePart}:00.000Z`;
    return isoString;
  }

  function getRequiredFormattedDate(dateString) {
    const dateObjEnd = new Date(dateString);
    const dayEnd = dateObjEnd.getDate().toString().padStart(2, "0");
    const monthEnd = (dateObjEnd.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const yearEnd = dateObjEnd.getFullYear();
    return `${dayEnd}/${monthEnd}/${yearEnd}`;
  }
  async function randomWait() {
    await new Promise((resolve) =>
      setTimeout(resolve, Math.floor(Math.random() * 100))
    );
    return;
  }
  const formattedTime = (d) =>
    `${(d.getHours() < 10 ? "0" : "") + d.getHours()}:${
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()
    }`;

  const initializeValue = async (item, type) => {
    setEventType(
      item?.isPublished === false
        ? "draft1"
        : getTimeDiffStatus(item?.startDate, item?.endDate) === "Expired"
        ? "expired"
        : "valid"
    );

    if (item?.startDate) {
      const dateObjStart = new Date(item?.startDate);
      const dayStart = dateObjStart.getDate().toString().padStart(2, "0");
      const monthStart = (dateObjStart.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Month is zero-based
      const yearStart = dateObjStart.getFullYear();
      setStartDate(`${dayStart}/${monthStart}/${yearStart}`);

      setStartTime(
        item?.startDate
          ? String(formattedTime(new Date(String(item?.startDate)))) || "-"
          : "-"
      );
    } else {
      setStartDate("DD/MM/YYYY");
      setStartTime("00:00 ");
    }

    if (item?.endDate) {
      const dateObjEnd = new Date(item?.endDate);
      const dayEnd = dateObjEnd.getDate().toString().padStart(2, "0");
      const monthEnd = (dateObjEnd.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const yearEnd = dateObjEnd.getFullYear();
      setEndDate(`${dayEnd}/${monthEnd}/${yearEnd}`);

      setEndTime(
        item?.endDate
          ? String(formattedTime(new Date(String(item?.endDate)))) || "-"
          : ""
      );
    } else {
      setEndDate("DD/MM/YYYY");
      setEndTime("00:00 ");
    }

    setEventName(item?.name ? item?.name : "");
    setEventDescription(item?.description ? item?.description : "");
    setEventDescriptionApp(item?.descriptionApp ? item?.descriptionApp : "");
    setEventCurrencyId(item?.currencyId ? item?.currencyId : "");
    setEventCurrency(
      item?.currencyId
        ? (currencyList?.find((x) => x.id === item?.currencyId)?.symbol
            ? `${currencyList?.find((x) => x.id === item?.currencyId)?.name}(${
                currencyList?.find((x) => x.id === item?.currencyId)?.symbol
              }
            )`
            : `${
                currencyList?.find((x) => x.id === item?.currencyId)?.name
              }`) || ""
        : ""
    );
    setTicketSellingEnabled(item?.ticketSellingEnabled);

    setVenueName(item?.venue?.name ? item?.venue?.name : "");
    setVenueDescription(
      item?.venue?.description ? item?.venue?.description : ""
    );
    // setCapacity(item?.venue?.capacity ? item?.venue?.capacity : "");
    setAddressLine1(
      item?.venue?.address?.addressLine1
        ? item?.venue?.address?.addressLine1
        : ""
    );
    setAddressLine2(
      item?.venue?.address?.addressLine2
        ? item?.venue?.address?.addressLine2
        : ""
    );
    setPostalCode(
      item?.venue?.address?.postalCode ? item?.venue?.address?.postalCode : ""
    );
    setCountry(
      item?.venue?.address?.country ? item?.venue?.address?.country : ""
    );
    setCountryId(
      item?.venue?.address?.countryId ? item?.venue?.address?.countryId : ""
    );
    setCity(item?.venue?.address?.city ? item?.venue?.address?.city : "");
    setVenueAddress(
      item?.venue?.address?.latitude && item?.venue?.address?.longitude
        ? {
            lat: item?.venue?.address?.latitude,
            lng: item?.venue?.address?.longitude,
          }
        : ""
    );
    setKycType(item?.ticketRequirements?.kycRequired === true ? 0 : 1);
    setMinimumAge(
      item?.ticketRequirements?.kycMinimumAgeLimit
        ? item?.ticketRequirements?.kycMinimumAgeLimit
        : "-"
    );
    setIsRefundAcceptable(
      item?.ticketRequirements?.refundsAllowed === true ? 1 : 0
    );
    setBannerImage(
      item?.imageUri ? process.env.REACT_APP_API_URL + item?.imageUri : null
    );
    setEventCategory(
      item?.eventCategoryNames && item?.eventCategoryNames?.length > 0
        ? item?.eventCategoryNames
        : ["Select an option"]
    );
    setEventCategoryId(
      item?.eventCategoryIds && item?.eventCategoryIds?.length > 0
        ? item?.eventCategoryIds
        : []
    );
    setTotalTicketQuantity(item?.ticketTypes[0]?.maxUserTicketQty || "");
    setAddFeesToTicketPrice(
      item?.ticketTypes[0]?.addFeesToTicketPrice === true
        ? setAddFeesToTicketPrice(0)
        : item?.ticketTypes[0]?.addFeesToTicketPrice === false
        ? setAddFeesToTicketPrice(1)
        : setAddFeesToTicketPrice(null)
    );

    setBookingPercentValue(item?.ticketTypes[0]?.bookingFeePct || 5);
    setIsAddBookingFees(
      item?.ticketTypes[0]?.addBookingFeeToTicketPrice ? 1 : 0
    );
    setDiscountList(item?.eventDiscountCodes || []);
    let tempArrayTicket = [];
    let tempArrayAddOn = [];
    for (let i = 0; i < item?.ticketTypes.length; i++) {
      if (!item?.ticketTypes[i]?.isAddOn) {
        tempArrayTicket.push({
          toHaveAccessibleErrorMessageTicket: [],
          releaseType:
            item?.ticketTypes[i]?.autoReleaseTickets === true
              ? "automatic"
              : item?.ticketTypes[i]?.eventTicketTypeStageReleases.length > 0
              ? "staged"
              : "manual",
          releaseDate:
            item?.ticketTypes[i]?.autoReleaseTickets === false
              ? getRequiredFormattedDate(
                  item?.ticketTypes[i]?.onSaleFromDate
                ) || "DD/MM/YYYY"
              : "DD/MM/YYYY",
          releaseTime:
            item?.ticketTypes[i]?.autoReleaseTickets === false
              ? String(
                  formattedTime(
                    new Date(String(item?.ticketTypes[i]?.onSaleFromDate))
                  )
                ) || "-"
              : "-",
          releaseEndDate:
            item?.ticketTypes[i]?.autoReleaseTickets === false
              ? getRequiredFormattedDate(item?.ticketTypes[i]?.onSaleToDate) ||
                "DD/MM/YYYY"
              : "DD/MM/YYYY",
          releaseEndTime:
            item?.ticketTypes[i]?.autoReleaseTickets === false
              ? String(
                  formattedTime(
                    new Date(String(item?.ticketTypes[i]?.onSaleToDate))
                  )
                ) || "-"
              : "-",
          name: item?.ticketTypes[i]?.name || "",
          quantity: item?.ticketTypes[i]?.quantity || "",
          description: item?.ticketTypes[i]?.description || "",
          category:
            item?.ticketTypes[i]?.eventTicketTypeCategory?.name ||
            "Select an option",
          categoryId: item?.ticketTypes[i]?.eventTicketTypeCategory?.id || null,
          price: item?.ticketTypes[i]?.price || "0.00",
          selectedAgeIndex:
            item?.ticketTypes[i]?.eventTicketTypeAgeCategory === "Child"
              ? 2
              : item?.ticketTypes[i]?.eventTicketTypeAgeCategory === "Adult"
              ? 1
              : item?.ticketTypes[i]?.eventTicketTypeAgeCategory ===
                "GeneralAdmission"
              ? 0
              : null,
          minimumAge:
            item?.ticketTypes[i]?.ticketRequirement?.minimumAgeLimit || "",
          maximumAge:
            item?.ticketTypes[i]?.ticketRequirement?.maximumAgeLimit || "",
          stageReleases:
            item?.ticketTypes[i]?.eventTicketTypeStageReleases.length > 0
              ? item?.ticketTypes[i]?.eventTicketTypeStageReleases.map(
                  (stage) => ({
                    stageId: stage?.id || 0,
                    stageOfferName: stage?.offerName || "",
                    stageReleaseDate:
                      getRequiredFormattedDate(stage?.startDate) ||
                      "DD/MM/YYYY",
                    stageReleaseTime:
                      formattedTime(new Date(String(stage?.startDate))) || "-",
                    stageReleaseEndDate:
                      getRequiredFormattedDate(stage?.endDate) || "DD/MM/YYYY",
                    stageReleaseEndTime:
                      formattedTime(new Date(String(stage?.endDate))) || "-",

                    stagePrice: stage?.price || "0.00",
                    stageQuantity: stage?.quantity || "",
                    stageCurrency: item?.currencyId
                      ? `${
                          currencyList?.find((x) => x.id === item?.currencyId)
                            ?.name
                        }(${
                          currencyList?.find((x) => x.id === item?.currencyId)
                            ?.symbol
                        }
                        )` || ""
                      : "",
                    showActivateNextRelease: true,
                    activateNextRelease: stage?.activeNextRelease || false,
                    autoTriggerNextRelease: true,
                  })
                )
              : [],
          isSaved: true,
          id: item?.ticketTypes[i]?.id,
          isAddOn: item?.ticketTypes[i]?.isAddOn || false,
          addFeesToTicketPrice:
            item?.ticketTypes[i]?.addFeesToTicketPrice === true
              ? setAddFeesToTicketPrice(0)
              : item?.ticketTypes[i]?.addFeesToTicketPrice === false
              ? setAddFeesToTicketPrice(1)
              : setAddFeesToTicketPrice(null),
          startYear: item?.ticketTypes[i]?.entryStartTime
            ? new Date(item?.ticketTypes[i]?.entryStartTime).getFullYear()
            : "",
          startMonth: item?.ticketTypes[i]?.entryStartTime
            ? new Date(item?.ticketTypes[i]?.entryStartTime).getMonth() + 1
            : "",

          startDate: item?.ticketTypes[i]?.entryStartTime
            ? new Date(item?.ticketTypes[i]?.entryStartTime).getDate()
            : "",

          startTime: item?.ticketTypes[i]?.entryStartTime
            ? new Date(item?.ticketTypes[i]?.entryStartTime).toLocaleTimeString(
                "en-US",
                {
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "",

          endYear: item?.ticketTypes[i]?.entryEndTime
            ? new Date(item?.ticketTypes[i]?.entryEndTime).getFullYear()
            : "",
          endMonth: item?.ticketTypes[i]?.entryEndTime
            ? new Date(item?.ticketTypes[i]?.entryEndTime).getMonth() + 1
            : "",

          endDate: item?.ticketTypes[i]?.entryEndTime
            ? new Date(item?.ticketTypes[i]?.entryEndTime).getDate()
            : "",

          endTime: item?.ticketTypes[i]?.entryEndTime
            ? new Date(item?.ticketTypes[i]?.entryEndTime).toLocaleTimeString(
                "en-US",
                {
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "",
        });
      } else {
        tempArrayAddOn.push({
          name: item?.ticketTypes[i]?.name || "",
          quantity: item?.ticketTypes[i]?.quantity || "",
          description: item?.ticketTypes[i]?.description || "",
          price: item?.ticketTypes[i]?.price || "0.00",
          addOnImage: item?.ticketTypes[i]?.imageUri
            ? process.env.REACT_APP_API_URL + item?.ticketTypes[i]?.imageUri
            : null,
          isSaved: true,
          id: item?.ticketTypes[i]?.id,
          isAddOn: item?.ticketTypes[i]?.isAddOn,
          addedTo:
            item?.ticketTypes[i]?.addOnAvailabilities?.length > 0
              ? item?.ticketTypes[i]?.addOnAvailabilities.map(
                  (addOn) =>
                    item?.ticketTypes?.find(
                      (x) => x.id === addOn?.eventTicketTypeId
                    )?.name || ""
                )
              : ["Select an option"],
          addedToId:
            item?.ticketTypes[i]?.addOnAvailabilities?.length > 0
              ? item?.ticketTypes[i]?.addOnAvailabilities.map((addOn) => ({
                  eventTicketTypeId: addOn?.eventTicketTypeId,
                  id: addOn?.id,
                  eventTicketTypeIndex: item?.ticketTypes?.findIndex(
                    (x) =>
                      x.id ===
                      item?.ticketTypes[i]?.addOnAvailabilities[0]
                        ?.eventTicketTypeId
                  ),
                }))
              : [{ eventTicketTypeId: 0, id: 0, eventTicketTypeIndex: null }],

          addOnCategoryList: item?.ticketTypes
            .filter(
              (ticket) =>
                !ticket?.isAddOn &&
                !item?.ticketTypes[i]?.addOnAvailabilities?.some(
                  (addOn) => addOn.eventTicketTypeId === ticket.id
                )
            )
            .map((ticket) => ({ name: ticket.name, id: ticket.id })),

          addOnAvailabilities: item?.ticketTypes[i]?.addOnAvailabilities,
        });
      }
      await randomWait();
    }

    const tempCategoryArray = [];
    tempArrayTicket.map((ticket, index) => {
      ticket.name !== "" &&
        tempCategoryArray.push({
          name: ticket?.name,
          id: index,
        });
    });
    setAddOnCategoryList(tempCategoryArray);

    let uniqueTempTicketCategoryArray = tempArrayTicket.map((ticket) => ({
      name: ticket?.category || "",
      id: ticket?.categoryId || 0,
    }));

    let mergedList = [...ticketCategoryList, ...uniqueTempTicketCategoryArray];

    let uniqueMergedList = mergedList.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const filteredList = uniqueMergedList.filter(
      (category) => category.name !== "+ Create category"
    );

    setTicketCategoryList([...filteredList, { name: "+ Create category" }]);

    setTicketList(tempArrayTicket);

    setTicketAddOnList(tempArrayAddOn);

    let tempArrayEntrance = [];
    for (let i = 0; i < item?.venue?.venueEntrances?.length; i++) {
      tempArrayEntrance.push({
        id: item?.venue?.venueEntrances[i]?.id,
        venueId: item?.venue?.venueEntrances[i]?.venueId,
        name: item?.venue?.venueEntrances[i]?.name,
        username: item?.venue?.venueEntrances[i]?.venueUserName,
        password: item?.venue?.venueEntrances[i]?.venueUserPassword,
        isSaved: true,
      });
    }
    setGateKeeperUserName(
      item?.venue?.venueEntrances[0]?.entranceUsers
        ? item?.venue?.venueEntrances[0]?.entranceUsers[0]?.userName
        : ""
    );
    setGateKeeperPassword(
      item?.venue?.venueEntrances[0]?.entranceUsers
        ? item?.venue?.venueEntrances[0]?.entranceUsers[0]?.password
        : ""
    );
    setEntranceList(tempArrayEntrance);
  };

  const getEventDetailsRequest = async (eventId, type) => {
    try {
      const result = await getEventDetails({ eventId: eventId });
      if (result.status) {
        setIsLoading(false);
        const item = result.response;
        setItem(item);
        initializeValue(item, type);
        getGuestListRequest(item.eventId, item);
        getEventStatisticsRequest(item.eventId);
        getEventTicketTypesRequest(item.eventId);
        // setTimeout(() => {
        getEventOrganiserEmailHistoryRequest(item.eventId);
        // }, 1000);
      } else {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error creating event:", error);
      setIsLoading(false);
    }
  };

  const getEventDetailsRequestForDiscountFetch = async (eventId, type) => {
    try {
      const result = await getEventDetails({ eventId: eventId });
      if (result.status) {
        setIsLoading(false);
        setDiscountList(result.response?.eventDiscountCodes || []);
      } else {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error creating event:", error);
      setIsLoading(false);
    }
  };

  const updateEventRequest = async (willPublish, isTicketUpdate = false) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    let isoStartDateString = "";
    let isoEndDateString = "";

    // let commonIds = categoryList
    //   .filter((option) => namesToMatch.has(option.name))
    //   .map((option) => option.id);

    let commonIds = categoryList
      .filter((option) => eventCategory.includes(option.name))
      .map((option) => option.id);

    if (startDate !== "DD/MM/YYYY" && startTime !== "00:00 ") {
      isoStartDateString = getISOStringDate(`${startDate} ${startTime}`);
    }

    if (endDate !== "DD/MM/YYYY" && endTime !== "00:00 ") {
      isoEndDateString = getISOStringDate(`${endDate} ${endTime}`);
    }

    // if (!eventName || !eventCategory || !eventDescription) {
    //   console.error("Mandatory fields are missing");
    //   dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });

    //   return;
    // }
    const tempAddOnArray = ticketAddOnList.map((addOn) => ({
      id: addOn?.id || 0,
      name: addOn?.name,
      imageDataBase64Enc: addOn.addOnBase64Image || "",
      imageDataContentType: "image/png",
      quantity: addOn?.quantity,
      price: addOn?.price,
      description: addOn?.description,
      eventTicketTypeId: 0,
      addOnAvailabilities: addOn.addedToId,
      isAddOn: true,
      bookingFee: 0,
      bookingFeePct: bookingPercentValue || 5,
      addFeesToTicketPrice: addFeesToTicketPrice === 0 ? true : false,
      addBookingFeeToTicketPrice: isAddBookingFees === 0 ? false : true,
    }));

    const tempTicketArray = await Promise.all(
      ticketList.map(async (ticket, index) => {
        await randomWait();
        return {
          id: ticket.id,
          indexId: index,
          name: ticket.name || "",
          description: ticket.description || "",
          eventTicketTypeCategory: {
            name: ticket.category || "",
            id: ticket.categoryId || -2,
          },
          price: ticket.price || 0,
          quantity: ticket.quantity || "",
          ticketPrice: {},
          maxNoOfTickets: ticket.quantity,
          autoReleaseTickets: ticket.releaseType === "automatic" ? true : false,
          onSaleFromDate:
            ticket.releaseType === "manual" &&
            ticket.releaseDate !== "DD/MM/YYYY"
              ? getISOStringDate(`${ticket.releaseDate} ${ticket.releaseTime}`)
              : null,
          onSaleToDate:
            ticket.releaseType === "manual" &&
            ticket.releaseEndDate !== "DD/MM/YYYY"
              ? getISOStringDate(
                  `${ticket.releaseEndDate} ${ticket.releaseEndTime}`
                )
              : null,
          platformFee: 0,
          ticketGuardFee: 0,
          totalFees: 0,
          bookingFee: 0,
          bookingFeePct: bookingPercentValue || 5,
          addFeesToTicketPrice: addFeesToTicketPrice === 0 ? true : false,
          addBookingFeeToTicketPrice: isAddBookingFees === 0 ? false : true,
          maxUserTicketQty: totalTicketQuantity || 0,
          eventTicketTypeAgeCategory:
            ticket.selectedAgeIndex === 0
              ? "GeneralAdmission"
              : ticket.selectedAgeIndex === 1
              ? "Adult"
              : "Child",
          minimumAgeLimit: ticket.minimumAge || 0,
          maximumAgeLimit: ticket.maximumAge || 100,
          eventTicketTypeStageReleases:
            ticket.releaseType === "staged" && ticket.stageReleases.length > 0
              ? ticket.stageReleases.map((stage) => ({
                  id: stage.stageId || 0,
                  offerName: stage.stageOfferName || "",
                  quantity: stage.stageQuantity || "",
                  price: stage.stagePrice || 0,
                  enabled: stage?.isStageType || false,
                  startDate:
                    stage.activateNextRelease === true
                      ? null
                      : stage.stageReleaseDate !== "DD/MM/YYYY" &&
                        stage.stageReleaseTime !== "-" &&
                        getISOStringDate(
                          `${stage.stageReleaseDate} ${stage.stageReleaseTime}`
                        ),
                  endDate:
                    stage.activateNextRelease === true
                      ? null
                      : stage.stageReleaseEndDate !== "DD/MM/YYYY" &&
                        stage.stageReleaseEndTime !== "-" &&
                        getISOStringDate(
                          `${stage.stageReleaseEndDate} ${stage.stageReleaseEndTime}`
                        ),
                  activeNextRelease: stage.activateNextRelease,
                  platformFee: 0,
                  ticketGuardFee: 0,
                  totalFees: 0,
                  bookingFee: 0,
                  bookingFeePct: bookingPercentValue || 5,
                  addFeesToTicketPrice:
                    addFeesToTicketPrice === 0 ? true : false,
                  addBookingFeeToTicketPrice:
                    isAddBookingFees === 0 ? false : true,
                }))
              : [],
        };
      })
    );
    const eventPayload = {
      id:
        nonActiveEventDetails?.draftEventId && willPublish === "publish"
          ? undefined
          : item?.id,
      eventId:
        nonActiveEventDetails?.draftEventId && willPublish === "publish"
          ? undefined
          : item?.eventId,
      name: eventName || "",
      description: eventDescription || "",
      descriptionApp: eventDescriptionApp || "",
      eventImageDataBase64Enc: bannerImageBase64,
      eventImageDataContentType: "image/png",
      startDate: isoStartDateString,
      endDate: isoEndDateString,
      eventCategoryId: eventCategoryId || 0,
      EventCategoryIds: commonIds,
      currencyId: eventCurrencyId || 0,
      eventCategoryNames: eventCategory || "",
      type: eventCategory || "",
      category: eventCategory || "",
      ticketRequirementId: item?.ticketRequirements?.id,
      isPublished:
        willPublish === "publish" || item?.isPublished === true ? true : false,
      eventTicketTypeCategories: ticketCategoryList,
      promoterId: userInfo?.businesses[userInfo?.businesses?.length - 1]?.id,

      VenueId: item?.venueId,
      venue: {
        address: {
          id: item?.venue?.address?.id || 0,
          addressLine1: addressLine1 || "",
          addressLine2: addressLine2 || "",
          city: city || "",
          postalCode: postalCode || "",
          countryId: countryId || "",
          country: country || "",
          latitude: venueAddress.lat || 0,
          longitude: venueAddress.lng || 0,
        },
        venueEntrances: entranceList?.map((entrance) => ({
          id: entrance.id || 0,
          venueId: item?.venueId,
          name: entrance.name || "",
          // venueUserName: gateKeeperUserName,
          // venueUserPassword: gateKeeperPassword,
          entranceUsers: [
            {
              // userId: item?.venue?.venueEntrances[0]?.entranceUsers ? item?.venue?.venueEntrances[0]?.entranceUsers[0]?.userId : 0,
              userId: 0,
              userName: gateKeeperUserName,
              password: gateKeeperPassword ? gateKeeperPassword : "null",
            },
          ],
        })),
        name: venueName || "",
        description: venueDescription || "",
        id: item?.venue?.id || 0,
        // capacity: capacity || 0,
      },
      ticketTypes: [...tempTicketArray, ...tempAddOnArray],
      ticketRequirements: {
        id: item?.ticketRequirements?.id,
        kycRequired: kycType === 0 ? true : false,
        kycMinimumAgeLimit: minimumAge === "-" ? 0 : minimumAge,
        refundsAllowed:
          isRefundAcceptable === null && nonActiveEventDetails?.draftEventId
            ? null
            : isRefundAcceptable === 1
            ? true
            : false,
      },
      draftEventId: item?.draftEventId,
    };
    try {
      const result =
        nonActiveEventDetails?.draftEventId && willPublish !== "publish"
          ? await updateInactiveDraftEvent(eventPayload)
          : nonActiveEventDetails?.draftEventId && willPublish === "publish"
          ? await createEvent(eventPayload)
          : await updateEvent(eventPayload);
      if (result.status) {
        showBanner(
          eventName +
            ` has been ${
              eventType === "draft1" && willPublish === "publish"
                ? "published"
                : "updated"
            } successfully.`
        );

        nonActiveEventDetails?.draftEventId &&
          willPublish !== "publish" &&
          getDraftEventData(eventPayload);

        if (
          eventType === "draft1" &&
          (willPublish === "publish" || item?.isPublished === true)
        ) {
          if (nonActiveEventDetails?.draftEventId) {
            deleteEventRequest(false);
            const updatedEventObj = removeDraftEventId(nonActiveEventDetails);
            setNonActiveEventDetails(updatedEventObj);
          }

          setEventType(
            getTimeDiffStatus(isoStartDateString, isoEndDateString) ===
              "Expired"
              ? "expired"
              : "valid"
          );
          setTicketSellingEnabled(true);
          getEventData(
            true,
            false,
            nonActiveEventDetails?.draftEventId ? result.response : undefined
          );
        } else {
          if (isTicketUpdate) {
            // getEventStatisticsRequest(item.eventId);
            // setTimeout(() => {
            // getEventTicketTypesRequest(item.eventId);
            setSelectedTicketType("All sales");
            // }, 1000);
          }
        }
        !nonActiveEventDetails?.draftEventId &&
          getEventDetailsRequest(item.eventId, eventType);
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      } else {
        const errorMessage = JSON.stringify(result.response);
        setTimeout(async () => {
          dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
          alert(
            `Error updating Events: ${convertDynamicErrorMessage(
              result.response
            )}`
          );
        }, 2500);
      }
    } catch (error) {
      console.error("Error creating event:", error);
      setTimeout(async () => {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      }, 2500);
    }
  };

  const createEventDiscountCodeRequest = async (flag) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });

    let isoStartDateString = "";
    let isoEndDateString = "";

    if (discountStartDate !== "DD/MM/YYYY" && discountStartTime !== "00:00 ") {
      isoStartDateString = getISOStringDate(
        `${discountStartDate} ${discountStartTime}`
      );
    }

    if (discountEndDate !== "DD/MM/YYYY" && discountEndTime !== "00:00 ") {
      isoEndDateString = getISOStringDate(
        `${discountEndDate} ${discountEndTime}`
      );
    }

    const ids = discountEligibility
      .filter((item) => item.id >= 0) // Filter out objects with negative id values
      .map((item) => item.id);

    const params = {
      id: flag === "edit" ? discountList[selectedDiscountIndex]?.id : 0,
      code: discountCodeName,
      discountAmount: parseFloat(discountAmount),
      isPercentage: discountAmountType === 0 ? true : false,
      customerSegmentId: 0,
      numberAvailable:
        discountMaxUsesType === 0 ? parseFloat(discountMaxUses) : null,
      numberAvailablePerCustomer:
        discountMaxUsesType === 1 ? parseFloat(discountMaxUses) : null,
      startDate: isoStartDateString ? isoStartDateString : null,
      endDate: isoEndDateString ? isoEndDateString : null,
      eventTicketTypeDiscountCodeUsageRestriction: discountEligibility.some(
        (item) => item.name === "All tickets and add ons"
      )
        ? "AllTicketsAddons"
        : discountEligibility.some((item) => item.name === "All tickets")
        ? "AllTickets"
        : discountEligibility.some((item) => item.name === "All add ons")
        ? "AllAddons"
        : null,
      eventTicketTypeIds: ids,
    };
    const result =
      flag === "edit"
        ? await UpdateEventDiscountCode(params, item?.eventId)
        : await createEventDiscountCode(params, item?.eventId);
    if (result.status) {
      initializeDiscountValue();
      getEventDetailsRequestForDiscountFetch(eventId, eventType);
      showBanner(
        `Discount code ${flag === "edit" ? `updated` : `created`} successfully.`
      );
    } else {
      const errorMessage = JSON.stringify(result.response);
      showBanner(errorMessage, "error");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const deleteEventDiscountCodeRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await deleteEventDiscountCode({
      eventId: item?.eventId,
      eventDiscountCodeId: discountList[selectedDiscountIndex]?.id,
    });
    setIsDeleteModalVisible(false);
    if (result.status) {
      const newArray = [
        ...discountList.slice(0, selectedDiscountIndex),
        ...discountList.slice(selectedDiscountIndex + 1),
      ];
      setDiscountList(newArray);
      showBanner(
        discountList[selectedDiscountIndex]?.code +
          ` has been deleted successfully.`
      );
    } else {
      showBanner(result.response, "Error");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const duplicateEventRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await duplicateEvent({
      eventId: item?.eventId,
    });
    let newEventId = result.response;
    if (result.status) {


 const result = await getInactiveDraftEventList({});
    if (result.status) {
        dispatch({
          type: SHOW_DRAFT,
          payload: true,
        });
      
        localStorage.setItem("selectedSegmentIndex", 2);
      
    
        const result1 = await getEventList({
          isPublished: false,
          type: "All",
        });
        if (result1.status) {

            dispatch({
              type: EVENT_DATA,
              payload: [...result1.response, ...result.response],
            });

            setSideBarReloadKey((prevKey) => prevKey + 1);


            navigate(
              `/EventDetails/${
                newEventId ? newEventId : eventId
              }`,
              {
                state: {
                  eventId: newEventId,
                  nonActiveEventDetails: {},
                },
              }
            );

            setSelectedTicketType("All sales");
      
            setNonActiveEventDetails(
              result?.response?.length > 0 &&
                result.response?.find(
                  (x) => x.draftEventId === newEventId
                )
            );

              dispatch({
                type: SELECTED_EVENT_DATA,
                payload:
                [...result1.response, ...result.response]?.length + 2,
              });
            
          

        } else {
          dispatch({ type: EVENT_DATA, payload: result.response });
        }
      
    } else {
      dispatch({ type: EVENT_DATA, payload: [] });
    }
 
    } else {
      showBanner(result.response, "Error");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  //GetEnteredList
  const getEventStatisticsRequest = async (eventId) => {
    const result = await getEventStatistics({
      eventId: eventId,
      eventStatisticTicketTypes: "All",
    });
    if (result.status) {
      setEventStatisticsObj({
        ...result.response.totals,
        ...result.response.ticketGuardStatus,
      });
    }
  };

  //GetEnteredList
  const updateEventTicketSellingFlagRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });

    const result = await updateEventTicketSellingFlag({
      eventId: eventId,
      ticketSellingEnabled: !ticketSellingEnabled,
    });
    if (result.status) {
      console.log("result updateEventTicketSellingFlagRequest", result);
      setTicketSellingEnabled(!ticketSellingEnabled);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  //GetEnteredList
  const getEventStatisticsRequestForAll = async (eventId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getEventStatistics({
      eventId: eventId,
      eventStatisticTicketTypes:
        selectedTicketType === "All sales"
          ? "All"
          : selectedTicketType === "All tickets"
          ? "ExcludeAddOns"
          : "AddOnsOnly",
    });
    if (result.status) {
      setEventStatisticsObj({
        ...result.response.totals,
        ...result.response.ticketGuardStatus,
      });
    } else {
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  useEffect(() => {
    console.log("eventStatisticsObj2121", eventStatisticsObj);
  }, [eventStatisticsObj]);

  const GetEventStatisticsByTicketTypeRequest = async (
    eventId,
    eventTicketTypeId
  ) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await GetEventStatisticsByTicketType({
      eventId: eventId,
      eventTicketTypeId: eventTicketTypeId,
    });
    if (result.status) {
      console.log("result.response", result.response);
      setEventStatisticsObj(result.response.totals[0]);
    } else {
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getEventTicketTypesRequest = async (eventId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await GetEventTicketTypes({ eventId: eventId });
    if (result.status) {
      const newItem = [
        { name: "All sales", id: undefined },
        { name: "All tickets", id: undefined },
        { name: "All add-ons", id: undefined },
      ];
      let tempTicketTypesList = result?.response;
      setTicketTypesList([...newItem, ...tempTicketTypesList]);
    } else {
      setTicketTypesList([{ name: "All sales", id: undefined }]);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getEventOrganiserEmailHistoryRequest = async (eventId) => {
    const result = await getEventOrganiserEmailHistory({ eventId: eventId });
    if (result.status) {
      setAttendeeEmailList(
        result.response?.length > 0 ? result.response.reverse() : []
      );
    } else {
      setAttendeeEmailList([]);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const sendEventOrganiserEmailRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    try {
      const result = await sendEventEmailToAttendees({
        eventId: eventId,
        emailSubject: emailComposeSubject,
        emailContent: emailComposeMessage,
      });

      if (result.status) {
        setEmailComposeSubject("");
        setEmailComposeMessage("");
        setIsComposeEmailActive(false);
        setIsEmailComposeAgreed(false);
        getEventOrganiserEmailHistoryRequest(eventId);
        setTimeout(() => {
          dispatch({
            type: BANNER_CONTENT,
            payload: {
              text: "Your email has been successfully delivered to all event attendees.",
              headingText: "Email sent",
              showBanner: true,
              type: "Success",
              withClose: true,
              withIcon: true,
              position: "bottom",
              width: 391,
              style: { width: "568px", margin: "0 auto" },
            },
          });
        }, 500);
      } else {
        const errorMessage = JSON.stringify(result.response);
        setTimeout(async () => {
          dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
          alert(`Error sending email: ${errorMessage},`);
        }, 2500);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setTimeout(async () => {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      }, 2500);
    }
  };

  // const onValidateGuestEmail =  debounce(async (text) => {
  //   dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
  //   const params = {
  //     emailAddress: text,
  //     userRole: "NotSet",
  //   };
  //   const result = await emailInUse(params);
  //   dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  //   return result?.response ? result?.response : false;
  // }, 2000);

  async function onValidateGuestEmail(text) {
    try {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const params = {
        emailAddress: text,
        userRole: "NotSet",
      };
      const result = await emailInUse(params);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      return result?.response ? result?.response : false;
    } catch (error) {
      console.error("Error in emailInUse API:", error);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      return false;
    }
  }

  const addGuestListRequest = async (index, email, ticketName) => {
    let tempArray = [...guestList];
    tempArray[index].status = "active";
    setGuestList(tempArray);
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    console.log("ticketList", ticketList);
    const params = [
      {
        eventTicketTypeId: parseInt(
          [...ticketList, ...ticketAddOnList].find((x) => x.name === ticketName)
            ?.id
        ),
        emailAddress: email,
        quantity: guestList[index]?.quantity,
      },
    ];
    console.log("params", params, ticketName, email);

    const result = await addGuestList(params);

    getGuestListRequest(eventId, item);
    if (result?.status) {
    } else {
      showBanner(JSON.stringify(result.response), "error");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getGuestListRequest = async (eventId, event) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = {
      eventId: parseInt(eventId),
    };
    const result = await getGuestListToSendRevokeTickets(params);

    if (result?.status && result?.response?.length > 0) {
      let tempArrayGuestList = [];
      result?.response?.map((guest) =>
        tempArrayGuestList.push({
          ticketCategory:
            event?.ticketTypes?.find(
              (ticket) => ticket.id === guest?.eventTicketTypeId
            )?.name || "",
          email: guest?.emailAddress,
          status: guest?.guestTicketIsPending === false ? "sent" : "active",
          emailError: false,
          quantityError: false,
          id: guest?.ticketId,
          eventTicketTypeId: guest?.eventTicketTypeId,
          quantity: guest?.quantity,
          ticketIds: guest?.ticketIds,
          guestTicketIsPending: guest?.guestTicketIsPending,
        })
      );
      setGuestList(tempArrayGuestList);
      const assetArray = getAssetArray(result?.response, event);
      setGuestAssetOptions(assetArray);
    } else {
      setGuestList([
        {
          ticketCategory: "Select an option",
          email: "",
          status: "notActive",
          emailError: false,
          quantityError: false,
          id: null,
          eventTicketTypeId: null,
          quantity: "-",
          ticketIds: [],
          guestTicketIsPending: false,
        },
      ]);
      setGuestAssetOptions([{ name: "All", id: 1 }]);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const filteredGuestList = guestList.filter((item) => {
    return (
      selectedGuestEventTicketTypeId === null ||
      item.eventTicketTypeId === selectedGuestEventTicketTypeId
    );
  });

  const removeGuestListRequest = async (index) => {
    if (guestList[index]?.ticketIds?.length > 0) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const result = await removeGuestList(guestList[index]?.ticketIds);
      // let tempArray = [...guestList];
      // tempArray[index].status = "created";
      // isGuestModalVisible === "deleteGuest"
      //   ? getGuestListRequest(eventId, item)
      //   : setGuestList(tempArray);

      getGuestListRequest(eventId, item);

      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    } else {
      const newArray = [
        ...guestList.slice(0, index),
        ...guestList.slice(index + 1),
      ];
      setGuestList(newArray);
    }
  };

  async function validateGuestTicketQuantityRequest(
    email,
    ticketName,
    quantity,
    index
  ) {
    const params = [
      {
        eventTicketTypeId: parseInt(
          ticketList.find((x) => x.name === ticketName)?.id
        ),
        emailAddress: email,
        quantity: parseInt(quantity),
        eventId: eventId,
        ticketTypeName: ticketName,
        isGuestList: true,
        bookingNumber: "0",
        eventTicketTypeCategoryId: null,
      },
    ];

    const result = await validateGuestTicketQuantity(params);

    if (result?.status) {
      let tempArray = [...guestList];
      tempArray[index].quantityError = false;
      setGuestList(tempArray);
    } else {
      let tempArray = [...guestList];
      tempArray[index].quantityError = true;
      setGuestList(tempArray);
    }

    return result?.status ? true : false;
  }

  // const verifyAccountRequest = async () => {
  //   dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
  //   const result = await getUrlKycVerify({
  //     emailAddress: userInfo?.emailAddress,
  //   });
  //   if (result.status) {
  //     localStorage.setItem("kycBeingVerified", true);
  //     window.open(result.response, "_self");
  //   }
  //   dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  // };

  const stripeConnect = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await StripeAccountConnect({
      AccountType: "express",
      EmailAddress: userInfo?.emailAddress,
      CountryCode: "US",
      returnURL: `${process.env.REACT_APP_WEB_URL}/EventDetails/${eventId}`,
    });
    if (result.status) {
      localStorage.setItem("isAccountVerificationRunning", true);
      window.open(result.response.url, "_self");
    } else {
      const errorMessage =
        result?.response?.response || "Error creating stripe connect request";
      showBanner(errorMessage, "error");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const verifyAccountRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getUrlKycVerify({
      returnURL: `${process.env.REACT_APP_WEB_URL}/EventDetails/${eventId}`,
    });
    if (result.status) {
      localStorage.setItem("isAccountVerificationRunning", true);
      window.open(result.response, "_self");
    } else {
      const errorMessage =
        result?.response?.response || "Error creating KYC request";
      showBanner(errorMessage, "error");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getMailChimpLoginURLRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getMailChimpLoginURL();
    if (result.status) {
      console.log("MailChimp URL", result.response.url);
      // localStorage.setItem("shouldCallRefreshUrl", true);
      window.open(result.response.url, "_self");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const connectMailChimpAccountRequest = async (mailChimpCode) => {
    const result = await connectMailChimpAccount({ code: mailChimpCode });
    if (result.status) {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Successfully connected to your Mailchimp account.",
          headingText: "Mailchimp connected",
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      getMailChimpAccountDetailsRequest();
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "There has been an error connceting your mailchimp account. Please check your details and try again.",
          headingText: "Mailchimp not connected",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getMailChimpAccountDetailsRequest = async () => {
    const result = await getMailChimpAccountDetails();
    if (result.status) {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setMailchimpUserInfo(result.response.mailChimpUserAccount);
    } else {
      setMailchimpUserInfo(false);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const disconnectMailChimpAccountRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await DisconnectMailChimpAccount();
    if (result.status) {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Successfully disconnected from your Mailchimp account.",
          headingText: "Mailchimp disconnected",
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      getMailChimpAccountDetailsRequest();
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "There has been an error disconnecting your mailchimp account. Please check your details and try again.",
          headingText: "Mailchimp not disconnected",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  function convertDynamicErrorMessage(response) {
    const errors = response.errors;
    const friendlyMessages = [];

    for (const key in errors) {
      const errorMessages = errors[key];

      // Match paths like $.ticketTypes[0].fieldName or other dynamic keys
      const match = key.match(/\$\.(\w+)(\[(\d+)\])?\.(\w+)/);

      if (match) {
        const parent = match[1]; // e.g., "ticketTypes"
        const index = match[3]; // e.g., "0" (if present)
        const field = match[4]; // e.g., "quantity"

        // Build a dynamic and readable message
        const location = index !== undefined ? `at index ${index}` : "";
        const fieldName = field.replace(/([A-Z])/g, " $1").toLowerCase(); // Convert camelCase to readable format
        friendlyMessages.push(
          `${parent} ${location} has an invalid ${fieldName}.(can be left blank / zero / infinte etc.). Please check and try again.`
        );
      } else {
        // Handle fields without an index or deeper path
        const fieldName = key
          .replace(/\$\./, "")
          .replace(/([A-Z])/g, " $1")
          .toLowerCase();
        friendlyMessages.push(
          `The ${fieldName} value is invalid. Please check all fields and submit all data again.`
        );
      }
    }

    return friendlyMessages;
  }

  const removeDraftEventId = (eventObj) => {
    // Destructure to exclude `draftEventId`
    const { draftEventId, ...updatedEvent } = eventObj;

    return updatedEvent;
  };

  const segmentBtnPressedGuestAsset = (index, items) => {
    if (index != selectedSegmentGuestIndexAsset) {
      setSelectedSegmentGuestIndexAsset(index);
      setSelectedGuestEventTicketTypeId(items.id);
    }
  };

  const onSaveClicked = (sectionName) => {
    updateEventRequest("unchanged", sectionName === "tickets" ? true : false);

    switch (sectionName) {
      case "event-details":
        setShouldShowSummaryEventDetails(true);
        break;
      case "timing":
        setShouldShowSummaryTiming(true);
        break;
      case "venue-about":
        setShouldShowSummaryVenueAbout(true);
        break;
      case "venue-address":
        setShouldShowSummaryVenueAddress(true);
        break;
      case "venue-map":
        setShouldShowSummaryVenueMap(true);
        break;
      case "venue-entrance":
        setShouldShowSummaryEntrance(true);
        break;
      case "kyc":
        setShouldShowSummaryKYC(true);
        break;
      case "refund-policy":
        setShouldShowSummaryRefundPolicy(true);
        break;
      case "fees":
        setShouldShowSummaryFees(true);
        break;
      case "total-tickets":
        setShouldShowSummaryTotalTickets(true);
        break;
      default:
        break;
    }
  };

  const onDiscardClicked = (sectionName) => {
    switch (sectionName) {
      case "event-details":
        setEventName(item?.name ? item?.name : "");
        setEventDescription(item?.description ? item?.description : "");
        setEventDescriptionApp(
          item?.descriptionApp ? item?.descriptionApp : ""
        );
        setBannerImage(
          item?.imageUri ? process.env.REACT_APP_API_URL + item?.imageUri : ""
        );
        setEventCategory(item?.type ? item?.type : ["Select an option"]);
        setEventCategoryId(item?.eventCategoryId ? item?.eventCategoryId : "");
        // setEventCategoryId(item?.capacity ? item?.capacity : "");
        setEventCurrencyId(item?.currencyId ? item?.currencyId : "");
        setEventCurrency(
          item?.currencyId
            ? (currencyList?.find((x) => x.id === item?.currencyId)?.symbol
                ? `${
                    currencyList?.find((x) => x.id === item?.currencyId)?.name
                  }(${
                    currencyList?.find((x) => x.id === item?.currencyId)?.symbol
                  }
            )`
                : `${
                    currencyList?.find((x) => x.id === item?.currencyId)?.name
                  }`) || ""
            : ""
        );
        setShouldShowSummaryEventDetails(true);

        break;
      case "timing":
        const dateObjStart = new Date(item?.startDate);
        const dayStart = dateObjStart.getDate().toString().padStart(2, "0");
        const monthStart = (dateObjStart.getMonth() + 1)
          .toString()
          .padStart(2, "0"); // Month is zero-based
        const yearStart = dateObjStart.getFullYear();
        setStartDate(`${dayStart}/${monthStart}/${yearStart}`);

        const dateObjEnd = new Date(item?.endDate);
        const dayEnd = dateObjEnd.getDate().toString().padStart(2, "0");
        const monthEnd = (dateObjEnd.getMonth() + 1)
          .toString()
          .padStart(2, "0"); // Month is zero-based
        const yearEnd = dateObjEnd.getFullYear();
        setEndDate(`${dayEnd}/${monthEnd}/${yearEnd}`);
        setStartTime(
          item?.startDate
            ? String(formattedTime(new Date(String(item?.startDate)))) || "-"
            : ""
        );
        setEndTime(
          item?.endDate
            ? String(formattedTime(new Date(String(item?.endDate)))) || "-"
            : ""
        );
        setShouldShowSummaryTiming(true);
        break;
      case "venue-about":
        setVenueName(item?.venue?.name ? item?.venue?.name : "");
        setVenueDescription(
          item?.venue?.description ? item?.venue?.description : ""
        );
        // setCapacity(item?.venue?.capacity ? item?.venue?.capacity : "");
        setShouldShowSummaryVenueAbout(true);
        break;
      case "venue-address":
        setAddressLine1(
          item?.venue?.address?.addressLine1
            ? item?.venue?.address?.addressLine1
            : ""
        );
        setAddressLine2(
          item?.venue?.address?.addressLine2
            ? item?.venue?.address?.addressLine2
            : ""
        );
        setPostalCode(
          item?.venue?.address?.postalCode
            ? item?.venue?.address?.postalCode
            : ""
        );
        setCountry(
          item?.venue?.address?.country ? item?.venue?.address?.country : ""
        );
        setCity(item?.venue?.address?.city ? item?.venue?.address?.city : "");
        setShouldShowSummaryVenueAddress(true);
        break;
      case "venue-map":
        setVenueAddress(
          item?.venue?.address?.latitude && item?.venue?.address?.longitude
            ? {
                lat: item?.venue?.address?.latitude,
                lng: item?.venue?.address?.longitude,
              }
            : ""
        );
        setShouldShowSummaryVenueMap(true);
        break;
      case "venue-entrance":
        let tempArrayEntrance = [];
        for (let i = 0; i < item?.venue?.venueEntrances?.length; i++) {
          tempArrayEntrance.push({
            id: item?.venue?.venueEntrances[i]?.id,
            venueId: item?.venue?.venueEntrances[i]?.venueId,
            name: item?.venue?.venueEntrances[i]?.name,
            username: item?.venue?.venueEntrances[i]?.venueUserName,
            password: item?.venue?.venueEntrances[i]?.venueUserPassword,
            isSaved: true,
          });
        }
        setEntranceList(tempArrayEntrance);
        setShouldShowSummaryEntrance(true);
        break;
      case "kyc":
        setKycType(item?.ticketRequirements?.kycRequired === true ? 0 : 1);
        setMinimumAge(
          item?.ticketRequirements?.kycMinimumAgeLimit
            ? item?.ticketRequirements?.kycMinimumAgeLimit
            : "-"
        );
        setShouldShowSummaryKYC(true);
        break;
      case "refund-policy":
        setIsRefundAcceptable(
          item?.ticketRequirements?.refundsAllowed === true ? 1 : 0
        );
        setShouldShowSummaryRefundPolicy(true);
        break;
      case "fees":
        item?.ticketTypes[0]?.addFeesToTicketPrice === true
          ? setAddFeesToTicketPrice(0)
          : item?.ticketTypes[0]?.addFeesToTicketPrice === false
          ? setAddFeesToTicketPrice(1)
          : setAddFeesToTicketPrice(null);

        setBookingPercentValue(item?.ticketTypes[0]?.bookingFeePct || 5);

        setShouldShowSummaryFees(true);
        break;
      case "total-tickets":
        setTotalTicketQuantity(item?.ticketTypes[0]?.maxUserTicketQty || "");
        setShouldShowSummaryTotalTickets(true);
        break;
      default:
        break;
    }
  };

  const onAddNewItemClicked = (type) => {
    switch (type) {
      case "ticket":
        let tempArray = [...ticketList];
        tempArray.push({
          releaseType: "automatic",
          releaseDate: "DD/MM/YYYY",
          releaseTime: "-",
          releaseEndDate: "DD/MM/YYYY",
          releaseEndTime: "-",
          name: "",
          quantity: "",
          description: "",
          category: "-",
          price: "",
          selectedAgeIndex: null,
          minimumAge: "",
          maximumAge: "",
          stageReleases: [
            {
              stageOfferName: "",
              stageQuantity: "",
              stageReleaseDate: "DD/MM/YYYY",
              stageReleaseTime: "-",
              stageReleaseEndDate: "DD/MM/YYYY",
              stageReleaseEndTime: "-",
              stagePrice: "",
              showActivateNextRelease: false,
              activateNextRelease: null,
              stageId: 0,
              stageCurrency: eventCurrency,
            },
          ],
          isSaved: false,
          isAddOn: null,
          addOnAvailabilities: [],
        });
        setTicketList(tempArray);
        break;

      case "addOn":
        let tempArrayAddOn = [...ticketAddOnList];
        tempArrayAddOn.push({
          name: "",
          addOnImage: "",
          addOnBase64Image: "",
          quantity: "",
          description: "",
          price: "",
          addedTo: ["Select an option"],
          addedToId: [
            {
              eventTicketTypeId: 0,
              eventTicketTypeIndex: "",
            },
          ],
          isSaved: false,
          toHaveAccessibleErrorMessageTicketAddOn: [],
          addOnCategoryList: addOnCategoryList || [],
        });
        setTicketAddOnList(tempArrayAddOn);
        break;

      case "guest":
        let tempArrayGuest = [...guestList];
        tempArrayGuest.push({
          ticketCategory: "Select an option",
          email: "",
          status: "notActive",
          emailError: false,
          quantityError: false,
          id: null,
          eventTicketTypeId: null,
          quantity: "-",
          ticketIds: [],
          guestTicketIsPending: false,
        });
        setGuestList(tempArrayGuest);
        break;

      default:
        break;
    }
  };

  const initializeDiscountValue = (value) => {
    setDiscountCodeName(value?.code ? value?.code : "");
    setDiscountAmountType(value?.isPercentage || value === undefined ? 0 : 1);
    setDiscountAmount(value?.discountAmount ? value?.discountAmount : "");
    // setDiscountEligibilityType(value ? value : null);
    setDiscountEligibility(
      value?.eventTicketTypeDiscountCodeUsageRestriction
        ? [{ name: value?.eventTicketTypeDiscountCodeUsageRestriction, id: -1 }]
        : value === undefined
        ? [{ name: "Select an option", id: 1 }]
        : [...ticketList, ...ticketAddOnList]
            .filter((item) => value?.eventTicketTypeIds.includes(item.id))
            .map((item) => ({ name: item.name, id: item.id }))
    );
    setDiscountMaxUsesType(
      value?.numberAvailable ? 0 : value === undefined ? null : 1
    );
    setDiscountMaxUses(
      value?.numberAvailable
        ? value?.numberAvailable
        : value === undefined
        ? ""
        : value?.numberAvailablePerCustomer
    );
    setDiscountStartDate(
      value?.startDate
        ? getRequiredFormattedDate(value?.startDate)
        : "DD/MM/YYYY"
    );
    setDiscountStartTime(
      value?.startDate
        ? formattedTime(new Date(String(value?.startDate)))
        : "00:00 "
    );
    setDiscountEndDate(
      value?.endDate ? getRequiredFormattedDate(value?.endDate) : "DD/MM/YYYY"
    );
    setDiscountEndTime(
      value?.endDate
        ? formattedTime(new Date(String(value?.endDate)))
        : "00:00 "
    );
    setIsDatePickerVisibleDiscountStartDate(false);
    setIsDatePickerVisibleDiscountEndDate(false);
  };

  function getOrdinalSuffix(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = num % 10;
    const suffix =
      num > 10 && num < 20
        ? suffixes[0]
        : lastDigit <= 3
        ? suffixes[lastDigit]
        : suffixes[0];
    return num + suffix;
  }

  const getCurrentTimeRoundedTo15Minutes = () => {
    const date = new Date();
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    date.setMinutes(roundedMinutes);
    const hours = date.getHours();
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`;
    return formattedTime;
  };

  const currentTimeRounded = getCurrentTimeRoundedTo15Minutes();

  const generateTimeArray = () => {
    const timeArray = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }
    return timeArray;
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const generateCustomTimeArray = (startTime) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const timeArray = [];

    // Generate times from start time to the end of the day
    for (let hours = startHour; hours < 24; hours++) {
      for (
        let minutes = hours === startHour ? startMinute : 0;
        minutes < 60;
        minutes += 15
      ) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }

    return timeArray;
  };

  const extractCurrencySymbol = (str) => {
    const match = str.match(/\(([^)]+)\)/);
    return match ? match[1] : str;
  };

  const onCountryChange = (selectedCountryName) => {
    setCountry(selectedCountryName);
    const foundCountry = countryList.find(
      (country) => country.name === selectedCountryName
    );
    if (foundCountry) {
      setCountryId(foundCountry.id);
    } else {
      setCountryId("");
    }
  };

  const onEventCategoryChange = (selectedCategoryName, index) => {
    let tempCategoryArray = [...eventCategory];
    tempCategoryArray[index] = selectedCategoryName;
    setEventCategory(tempCategoryArray);

    // const foundCategory = categoryList.find(
    //   (category) => category.name === selectedCategoryName
    // );
    // if (foundCategory) {
    //   setEventCategoryId(foundCategory?.id);
    // } else {
    //   setEventCategoryId("");
    // }
  };

  const segmentBtnPressedEvent = (index) => {
    if (index != selectedEventOptionIndex) {
      dispatch({
        type: SELECTED_EVENT_CATEGORY_DATA,
        payload: index,
      });
    }
  };

  const checkForActiveDraft = () => {
    const isTicketCompleted =
      ticketList.length > 0 &&
      ticketList.every(
        (item) =>
          item?.name?.length >= 1 &&
          item?.description?.length >= 1 &&
          item?.category !== "-" &&
          `${item?.quantity}`.length >= 1 &&
          (item?.releaseType === "staged" ||
            (item?.releaseType !== "staged" &&
              `${item?.price}`?.length >= 1)) &&
          (item?.selectedAgeIndex === 0 ||
            (item?.minimumAge !== "" && item?.selectedAgeIndex !== null)) &&
          (item?.releaseType === "automatic" ||
            item?.releaseType === "staged" ||
            (item?.releaseType === "manual" &&
              item?.releaseDate !== "DD/MM/YYYY" &&
              item?.releaseTime !== "-" &&
              item?.releaseEndDate !== "DD/MM/YYYY" &&
              item?.releaseEndTime !== "-")) &&
          (item?.releaseType !== "staged" ||
            (item?.releaseType === "staged" &&
              item?.stageReleases?.length > 0 &&
              item?.stageReleases?.every(
                (obj) =>
                  obj.stageOfferName !== "" &&
                  `${obj.stageQuantity}` !== "" &&
                  `${obj.stagePrice}` !== "" &&
                  ((obj.activateNextRelease === false &&
                    obj.stageReleaseDate !== "DD/MM/YYYY" &&
                    obj.stageReleaseTime !== "-" &&
                    obj.stageReleaseEndDate !== "DD/MM/YYYY" &&
                    obj.stageReleaseEndTime !== "-") ||
                    obj.activateNextRelease === true)
              )))
      );

    const isTicketAddOnCompleted = ticketAddOnList.every(
      (item) =>
        item?.name?.length >= 1 &&
        item?.description?.length >= 1 &&
        `${item?.price}`?.length >= 1 &&
        `${item?.quantity}`?.length >= 1 &&
        item?.addedTo?.length > 0 &&
        !item?.addedTo?.includes("Select an option")
    );

    if (
      eventName === "" ||
      eventDescription === "" ||
      eventDescriptionApp === "" ||
      bannerImage === "" ||
      (eventCategory?.length > 0 && eventCategory[0] === "Select an option") ||
      startDate === "DD/MM/YYYY" ||
      endDate === "DD/MM/YYYY" ||
      startTime === "00:00" ||
      endTime === "00:00" ||
      venueName === "" ||
      venueDescription === "" ||
      !venueAddress?.lat ||
      !venueAddress?.lng ||
      addressLine1 === "" ||
      postalCode === "" ||
      country === "" ||
      city === "" ||
      // entranceList.some((entrance) => entrance?.name?.trim() === "") ||
      // gateKeeperUserName?.trim().length === 0 ||
      // gateKeeperUserNameAssistiveText === "Username already exists" ||
      // gateKeeperUserNameAssistiveText === "*User name is required" ||
      isRefundAcceptable === null ||
      addFeesToTicketPrice === null ||
      !isTicketCompleted ||
      !isTicketAddOnCompleted
    ) {
      return false;
    } else {
      return true;
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader?.result?.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  async function fetchImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  function getFormattedDate(date) {
    const [day, month, year] = date
      .split("/")
      .map((part) => parseInt(part, 10));
    const dateObj = new Date(year, month - 1, day);
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  function getFormattedDateForEmailAttendee(dateTimeStr) {
    const date = new Date(dateTimeStr);

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "long" });
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }

  // Function to get the asset array
  const getAssetArray = (data, event) => {
    const assetMap = {};
    data.forEach((items) => {
      const { eventTicketTypeId } = items;
      const ticket = event?.ticketTypes?.find(
        (ticket) => ticket.id === eventTicketTypeId
      );
      const ticketName = ticket?.name;
      const ticketId = ticket?.id;

      if (!assetMap[ticketName]) {
        assetMap[ticketName] = { length: 0, id: ticketId };
      }
      assetMap[ticketName].length++;
    });

    const assetArray = Object.entries(assetMap).map(
      ([name, { length, id }]) => ({
        name,
        length,
        id,
      })
    );
    assetArray.unshift({ name: "All", length: data.length, id: null });
    return assetArray;
  };

  const renderOverview = () => {
    return (
      <div>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
            className="d-flex justify-content-center"
          >
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : item ? (
          <div className="event-details-banner-parent-content">
            <div
              className="EventDetailsBannerLeft"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1 / 2,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <img
                  content="center"
                  alt=""
                  style={{
                    aspectRatio: 16 / 9,
                    objectFit: "cover",
                    display: "block",
                    border: "none",
                  }}
                  src={process.env.REACT_APP_API_URL + item?.imageUri}
                />
              </div>
            </div>
            <div
              className="EventDetailsBannerRight"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1 / 2,
              }}
            >
              <div
                className="left-tags-label-container-event-details"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {(getTimeDiffStatus(item?.startDate, item?.endDate) ===
                  "Live" ||
                  getTimeDiffStatus(item?.startDate, item?.endDate) ===
                    "Expired") &&
                  eventType !== "draft1" && (
                    <Tags
                      backgroundColor={
                        eventType === "expired"
                          ? null
                          : "var(--fuchsiaSecondary)"
                      }
                      paddingVertical={8}
                      label={eventType === "expired" ? "Expired" : "Live"}
                      SourceIcon={
                        eventType === "expired" ? WarningFilled : RecordCircle
                      }
                      selected={true}
                      onChangeSelection={() => {}}
                      style={{
                        borderColor:
                          eventType === "expired" ? "var(--grey)" : null,
                        width: eventType === "expired" ? 110 : 90,
                        marginRight: 12,
                      }}
                      disabled={eventType === "expired" ? true : false}
                    />
                  )}

                <Tags
                  paddingVertical={8}
                  label={
                    item?.eventCategoryNames &&
                    item?.eventCategoryNames?.length > 0 &&
                    item?.eventCategoryNames[0] !== "Select an option"
                      ? item?.eventCategoryNames[0]
                      : "not set"
                  }
                  backgroundColor={"var(--hoverFadeBlue)"}
                  onChangeSelection={() => {}}
                  notBorder={true}
                />
              </div>

              <div className="eventDetails-item-inner-content">
                <div className="event-details-tittle">
                  <Text marginTop={24} variant={"m600"}>
                    {item?.name || ""}
                  </Text>
                </div>
                {/* <div className="event-details-prg">
                  <Text marginTop={16} variant={"r500"}>
                    {item?.description}
                  </Text>
                </div> */}
              </div>

              <div className="event-details-instance-component-parent-content">
                {instanceComponentOptions.map((items, ind) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flex: 1 / 3,
                        marginBottom: ind === 2 ? 0 : 24,
                      }}
                    >
                      <div className="event-details-banner-item-icon">
                        {ind === 2 ? (
                          !item?.startDate ||
                          !item?.endDate ? null : getTimeDiffStatus(
                              item?.startDate,
                              item?.endDate
                            ) === "Expired" ? (
                            <WarningFilled fill={"var(--midBlue)"} />
                          ) : getTimeDiffStatus(
                              item?.startDate,
                              item?.endDate
                            ) === "Live" ? (
                            <RecordCircle
                              width={24}
                              height={24}
                              fill={"var(--midBlue)"}
                            />
                          ) : (
                            <ClockFilled />
                          )
                        ) : ind === 1 ? (
                          item?.venue?.address?.addressLine1 ||
                          item?.venue?.address?.addressLine2 ||
                          item?.venue?.address?.postalCode ||
                          item?.venue?.address?.country ||
                          item?.venue?.address?.city ? (
                            items.icon
                          ) : null
                        ) : item?.startDate ? (
                          items.icon
                        ) : null}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: 8,
                        }}
                      >
                        <Text variant={"m400"}>
                          {ind === 0
                            ? item?.startDate
                              ? getFormattedDate(startDate)
                              : ""
                            : ind === 1
                            ? (item?.venue?.address?.addressLine1
                                ? item?.venue?.address?.addressLine1
                                : "") +
                              (item?.venue?.address?.addressLine2
                                ? ", " + item?.venue?.address?.addressLine2
                                : "")
                            : !item?.startDate || !item?.endDate
                            ? ""
                            : getTimeDiffStatus(
                                item?.startDate,
                                item?.endDate
                              ) === "Expired"
                            ? "Expired"
                            : getTimeDiffStatus(
                                item?.startDate,
                                item?.endDate
                              ) === "Live"
                            ? "Live"
                            : "Upcoming"}
                        </Text>
                        <Text
                          width={ind === 1 ? "90%" : "100%"}
                          marginTop={8}
                          variant={"r200"}
                        >
                          {items.subText +
                            (ind === 0
                              ? item?.startDate
                                ? `Doors open at ` +
                                  new Date(item?.startDate).toLocaleTimeString(
                                    "en-US",
                                    {
                                      hour12: false,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )
                                : ""
                              : ind === 1
                              ? item?.venue?.address?.postalCode +
                                (item?.venue?.address?.city
                                  ? ", " + item?.venue?.address?.city
                                  : "") +
                                (item?.venue?.address?.country
                                  ? ", " + item?.venue?.address?.country
                                  : "")
                              : !item?.startDate || !item?.endDate
                              ? ""
                              : getTimeDiffStatus(
                                  item?.startDate,
                                  item?.endDate
                                ) === "Expired"
                              ? "Expired on " +
                                getOrdinalSuffix(
                                  new Date(item?.endDate).getDate()
                                ) +
                                " " +
                                months[new Date(item?.endDate).getMonth()] +
                                " " +
                                new Date(item?.endDate).getFullYear()
                              : getTimeDiffStatus(
                                  item?.startDate,
                                  item?.endDate
                                ) === "Live"
                              ? "Doors open"
                              : checkDaysToGo(item?.startDate))}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}

        {eventType !== "draft1" &&
          (isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
              className="d-flex justify-content-center"
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : item ? (
            <EventStatistics
              title={"Sale statistics"}
              statType={"sales"}
              type={eventType}
              ticketTypesList={ticketTypesList}
              setSelectedTicketType={setSelectedTicketType}
              selectedTicketType={selectedTicketType}
              ticketSellingEnabled={ticketSellingEnabled}
              onPuaseResumeTicketSales={() =>
                setIsGuestModalVisible("pauseResumeTicketSales")
              }
              eventStatisticsObj={
                eventStatisticsObj
                  ? eventStatisticsObj
                  : {
                      id: 1,
                      name: "",
                      tickets: 0,
                      ticketsAllocated: 0,
                      ticketsSold: 0,
                      ticketsAvailable: 0,
                      netSalesValue: 0,
                      averageOrderValue: 0,
                      ticketsSoldLastDay: 0,
                      ticketsSoldLastDayValue: "0.00",
                      ticketsSoldLastMonth: 0,
                      ticketsSoldLastMonthValue: "0.00",
                      checkedIn: 0,
                      rejected: 0,
                      checkedOut: 0,
                      scanned: 0,
                      toScan: 0,
                    }
              }
              data1={
                eventStatisticsObj
                  ? [
                      ["sale", "total"],
                      [
                        "toSale",
                        eventStatisticsObj?.ticketsSold !== undefined &&
                        eventStatisticsObj?.tickets !== undefined
                          ? parseInt(eventStatisticsObj?.tickets || 1) -
                            parseInt(eventStatisticsObj?.ticketsSold || 0)
                          : 1,
                      ],
                      [
                        "sold",
                        eventStatisticsObj.ticketsSold !== undefined
                          ? eventStatisticsObj.ticketsSold || 0
                          : 0,
                      ],
                    ]
                  : [
                      ["sale", "total"],
                      ["toSale", 1],
                      ["sold", 0],
                    ]
              }
              data2={[
                ["sale", "total"],
                ["toSale", 1],
              ]}
              colors1={[
                getComputedStyle(document.documentElement).getPropertyValue(
                  "--midnightBlue"
                ),
                getComputedStyle(document.documentElement).getPropertyValue(
                  "--midBlue"
                ),
              ]}
              colors2={
                eventStatisticsObj?.checkedIn > 0
                  ? [
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--green"),
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--red"),
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue"),
                    ]
                  : [
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--lightGrey"),
                    ]
              }
              onAttendeeListClicked={() =>
                navigate(`/EventDetails/${item?.eventId}/OrderList`, {
                  state: {
                    eventId: item?.eventId,
                    event: item,
                  },
                })
              }
            />
          ) : null)}

        {eventType !== "draft1" &&
          (isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
              className="d-flex justify-content-center"
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : item ? (
            <EventStatistics
              title={"Live event statistics"}
              statType={"ticket"}
              type={eventType}
              eventStatisticsObj={
                eventStatisticsObj
                  ? eventStatisticsObj
                  : {
                      id: 1,
                      name: "",
                      tickets: 0,
                      ticketsAllocated: 0,
                      ticketsSold: 0,
                      ticketsAvailable: 0,
                      netSalesValue: 0,
                      averageOrderValue: 0,
                      ticketsSoldLastDay: 0,
                      ticketsSoldLastDayValue: "0.00",
                      ticketsSoldLastMonth: 0,
                      ticketsSoldLastMonthValue: "0.00",
                      checkedIn: 0,
                      rejected: 0,
                      checkedOut: 0,
                      scanned: 0,
                      toScan: 0,
                    }
              }
              data1={
                parseInt(eventStatisticsObj?.toScan) === 0 &&
                parseInt(eventStatisticsObj?.scanned) === 0
                  ? [
                      ["scan", "total"],
                      ["toScan", 1],
                    ]
                  : [
                      ["scan", "total"],
                      [
                        "toScan",
                        eventStatisticsObj?.toScan !== undefined
                          ? eventStatisticsObj?.toScan || 1
                          : 1,
                      ],
                      [
                        "scanned",
                        eventStatisticsObj?.scanned !== undefined
                          ? eventStatisticsObj?.scanned || 0
                          : 0,
                      ],
                    ]
              }
              data2={
                eventType === "expired"
                  ? [
                      ["scan", "total"],
                      [
                        "toScan",
                        eventStatisticsObj?.checkedIn !== undefined
                          ? eventStatisticsObj?.checkedIn || 0
                          : 0,
                      ],
                      [
                        "scanned",
                        eventStatisticsObj?.rejected !== undefined
                          ? eventStatisticsObj?.rejected || 0
                          : 0,
                      ],
                    ]
                  : parseInt(eventStatisticsObj?.checkedIn) === 0 &&
                    parseInt(eventStatisticsObj?.rejected) === 0 &&
                    parseInt(eventStatisticsObj?.checkedOut) === 0
                  ? [
                      ["scan", "total"],
                      ["toScan", 1],
                    ]
                  : [
                      ["scan", "total"],
                      [
                        "toScan",
                        eventStatisticsObj?.checkedIn !== undefined
                          ? eventStatisticsObj?.checkedIn || 0
                          : 1,
                      ],
                      [
                        "toScan",
                        eventStatisticsObj?.rejected !== undefined
                          ? eventStatisticsObj?.rejected || 0
                          : 0,
                      ],
                      [
                        "scanned",
                        eventStatisticsObj?.checkedOut !== undefined
                          ? eventStatisticsObj?.checkedOut || 0
                          : 0,
                      ],
                    ]
              }
              colors1={[
                getComputedStyle(document.documentElement).getPropertyValue(
                  "--midnightBlue"
                ),
                "#46AFF5",
              ]}
              colors2={
                parseInt(eventStatisticsObj?.checkedIn) > 0 ||
                parseInt(eventStatisticsObj?.rejected) > 0 ||
                parseInt(eventStatisticsObj?.checkedOut) > 0
                  ? [
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--green"),
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--red"),
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue"),
                    ]
                  : [
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--lightGrey"),
                    ]
              }
              onAttendeeListClicked={() =>
                navigate(`/EventDetails/${item?.eventId}/AttendeeList`, {
                  state: {
                    eventId: item?.eventId,
                    event: item,
                  },
                })
              }
            />
          ) : null)}

        {eventType !== "draft1" &&
          (isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
              className="d-flex justify-content-center"
            >
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : item ? (
            <EventStatistics
              title={"TicketGuard® statistics"}
              statType={"ticketGuard"}
              type={eventType}
              eventStatisticsObj={
                eventStatisticsObj
                  ? eventStatisticsObj
                  : {
                      id: 1,
                      name: "",
                      tickets: 0,
                      ticketsAllocated: 0,
                      ticketsSold: 0,
                      ticketsAvailable: 0,
                      netSalesValue: 0,
                      averageOrderValue: 0,
                      ticketsSoldLastDay: 0,
                      ticketsSoldLastDayValue: "0.00",
                      ticketsSoldLastMonth: 0,
                      ticketsSoldLastMonthValue: "0.00",
                      checkedIn: 0,
                      rejected: 0,
                      checkedOut: 0,
                      scanned: 0,
                      toScan: 0,
                    }
              }
              data1={
                eventStatisticsObj
                  ? [
                      ["sale", "total"],
                      [
                        "toSale",
                        eventStatisticsObj?.failed !== undefined &&
                        eventStatisticsObj?.tickets !== undefined
                          ? parseInt(eventStatisticsObj?.tickets || 1) -
                            parseInt(eventStatisticsObj?.failed || 0)
                          : 1,
                      ],
                      [
                        "sold",
                        eventStatisticsObj.verified !== undefined
                          ? eventStatisticsObj.verified || 0
                          : 0,
                      ],
                    ]
                  : [
                      ["sale", "total"],
                      ["toSale", 1],
                      ["sold", 0],
                    ]
              }
              data2={
                parseInt(eventStatisticsObj?.verified) === 0 &&
                parseInt(eventStatisticsObj?.failed) === 0 &&
                parseInt(eventStatisticsObj?.inProgress) === 0
                  ? [
                      ["scan", "total"],
                      ["toScan", 1],
                    ]
                  : [
                      ["scan", "total"],
                      [
                        "toScan",
                        eventStatisticsObj?.verified !== undefined
                          ? eventStatisticsObj?.verified || 0
                          : 1,
                      ],
                      [
                        "toScan",
                        eventStatisticsObj?.failed !== undefined
                          ? eventStatisticsObj?.failed || 0
                          : 0,
                      ],
                      [
                        "scanned",
                        eventStatisticsObj?.inProgress !== undefined
                          ? eventStatisticsObj?.inProgress || 0
                          : 0,
                      ],
                    ]
              }
              colors1={[
                getComputedStyle(document.documentElement).getPropertyValue(
                  "--midnightBlue"
                ),
                getComputedStyle(document.documentElement).getPropertyValue(
                  "--midBlue"
                ),
              ]}
              colors2={
                parseInt(eventStatisticsObj?.verified) > 0 ||
                parseInt(eventStatisticsObj?.failed) > 0 ||
                parseInt(eventStatisticsObj?.inProgress) > 0
                  ? [
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--green"),
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--red"),
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue"),
                    ]
                  : [
                      getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--lightGrey"),
                    ]
              }
              onAttendeeListClicked={() =>
                navigate(`/EventDetails/${item?.eventId}/AttendeeList`, {
                  state: {
                    eventId: item?.eventId,
                    event: item,
                  },
                })
              }
            />
          ) : null)}
      </div>
    );
  };

  const renderEventDetails = () => {
    return (
      <div>
        {!shouldShowSummaryEventDetails ? (
          <EventDetailsComponent
            // forNonActiveDraftEvent={nonActiveEventDetails?.draftEventId ? true : false}
            eventName={eventName}
            eventDescription={eventDescription}
            eventDescriptionApp={eventDescriptionApp}
            bannerImage={bannerImage}
            eventCategory={eventCategory}
            eventCurrency={eventCurrency}
            onEventNameChange={(value) => setEventName(value)}
            onEventDescriptionChange={(value) => setEventDescription(value)}
            onEventDescriptionAppChange={(value) =>
              setEventDescriptionApp(value)
            }
            onBannerImageChange={(value) => {
              if (value) {
                setToCropImage(value);
                setIsCropModalVisible("posterCrop");
              } else {
                setBannerImage(value);
              }
            }}
            onEventCategoryChange={(value, index) =>
              onEventCategoryChange(value, index)
            }
            shouldShowSubmitButton={
              eventName === "" ||
              eventDescription === "" ||
              eventDescriptionApp === "" ||
              bannerImage === "" ||
              (eventCategory?.length > 0 &&
                eventCategory[0] === "Select an option")
                ? false
                : true
            }
            onEventCurrencyChange={(value, id) => {
              setEventCurrency(value);
              setEventCurrencyId(id);
            }}
            onAddAnotherCategoryClicked={() => {
              let tempArray = [...eventCategory];
              tempArray.push("Select an option");
              setEventCategory(tempArray);
            }}
            onRemoveCategoryClicked={(ind) => {
              let tempArray = [...eventCategory];
              const newAddedToArray = [
                ...tempArray.slice(0, ind),
                ...tempArray.slice(ind + 1),
              ];
              tempArray = newAddedToArray;
              setEventCategory(tempArray);
            }}
            showAddAnotherCategory={
              (eventCategory?.length > 0 &&
                eventCategory[eventCategory?.length - 1] !==
                  "Select an option") ||
              eventCategory?.length === 0
                ? true
                : false
            }
            toHaveAccessibleErrorMessage={[]}
            onSaveClicked={() => onSaveClicked("event-details")}
            onDiscardClicked={() => onDiscardClicked("event-details")}
          />
        ) : (
          <SummaryLabeledView
            imageURL={bannerImage}
            tooltipInfo={true}
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"About"}
            data={[
              { label: "Event name", result: eventName },
              { label: "Event description", result: eventDescription },
              { label: "App description", result: eventDescriptionApp },
              {
                label: "Event category",
                result:
                  eventCategory[0] === "Select an option"
                    ? ""
                    : eventCategory?.length > 0 && eventCategory?.join(", "),
              },
              { label: "Event currency", result: eventCurrency },
              { label: "Event poster", result: bannerImage ? "Yes" : "No" },
            ]}
            onEditClicked={() => setShouldShowSummaryEventDetails(false)}
          />
        )}

        {!shouldShowSummaryTiming ? (
          <Timing
            shouldShowWarning={eventType === "valid" ? true : false}
            setSelectedStartDateString={(value) => setStartDate(value)}
            setSelectedEndDateString={(value) => setEndDate(value)}
            setSelectedStartTimeString={(value) => setStartTime(value)}
            setSelectedEndTimeString={(value) => setEndTime(value)}
            selectedStartDateString={startDate}
            selectedEndDateString={endDate}
            selectedStartTimeString={startTime}
            selectedEndTimeString={endTime}
            shouldShowSubmitButton={
              startDate === "DD/MM/YYYY" ||
              endDate === "DD/MM/YYYY" ||
              startTime === "00:00 " ||
              endTime === "00:00 "
                ? false
                : true
            }
            toHaveAccessibleErrorMessage={[]}
            onSaveTimingClicked={() => onSaveClicked("timing")}
            onDiscardTimingClicked={() => onDiscardClicked("timing")}
          />
        ) : (
          <SummaryLabeledView
            tooltipInfo={true}
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"Timings"}
            data={[
              {
                label: "Start date",
                result:
                  startDate !== "DD/MM/YYYY" ? getFormattedDate(startDate) : "",
              },
              {
                label: "Start time",
                result: startTime !== "00:00 " ? startTime : "",
              },
              {
                label: "End date",
                result:
                  endDate !== "DD/MM/YYYY" ? getFormattedDate(endDate) : "",
              },
              {
                label: "End time",
                result: endTime !== "00:00 " ? endTime : "",
              },
            ]}
            onEditClicked={() => setShouldShowSummaryTiming(false)}
          />
        )}
      </div>
    );
  };

  const renderVenue = () => {
    return (
      <div>
        {!shouldShowSummaryVenueAbout ? (
          <EventDetailsComponent
            forVenue={true}
            eventName={venueName}
            eventDescription={venueDescription}
            // capacity={capacity}
            onEventNameChange={(value) => setVenueName(value)}
            onEventDescriptionChange={(value) => setVenueDescription(value)}
            // onCapacityChange={(value) => setCapacity(value)}
            shouldShowSubmitButton={
              venueName === "" || venueDescription === "" ? false : true
            }
            toHaveAccessibleErrorMessage={[]}
            onSaveClicked={() => onSaveClicked("venue-about")}
            onDiscardClicked={() => onDiscardClicked("venue-about")}
            onEditClicked={() => {}}
          />
        ) : (
          <SummaryLabeledView
            tooltipInfo={true}
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"About"}
            data={[
              { label: "Venue name", result: venueName },
              { label: "Venue description", result: venueDescription },
              // { label: "Capacity", result: capacity },
            ]}
            onEditClicked={() => setShouldShowSummaryVenueAbout(false)}
          />
        )}

        {!shouldShowSummaryVenueMap ? (
          <VenueMap
            searchAddress={venueSearchAddress}
            setSearchAddress={(value) => setVenueSearchAddress(value)}
            currentLatLng={venueAddress}
            onCurrentLatLngChange={(value) => setVenueAddress(value)}
            shouldShowSubmitButton={
              venueAddress?.lat && venueAddress?.lng ? true : false
            }
            toHaveAccessibleErrorMessage={[]}
            onSaveClicked={() => onSaveClicked("venue-map")}
            onDiscardClicked={() => onDiscardClicked("venue-map")}
          />
        ) : (
          <SummaryLabeledView
            tooltipInfo={true}
            isExpiredEvent={eventType === "expired" ? true : false}
            flag={"map"}
            heading={"Map"}
            data={[
              { label: "Latitude", result: venueAddress?.lat },
              { label: "Longitude", result: venueAddress?.lng },
              { label: "Address", result: venueAddress },
            ]}
            onEditClicked={() => setShouldShowSummaryVenueMap(false)}
          />
        )}

        {!shouldShowSummaryVenueAddress ? (
          <VenueAddress
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            postalCode={postalCode}
            country={country}
            city={city}
            onAddressLine1Change={(value) => setAddressLine1(value)}
            onAddressLine2Change={(value) => setAddressLine2(value)}
            onPostalCodeChange={(value) => setPostalCode(value)}
            onCountryChange={(value) => onCountryChange(value)}
            onCityChange={(value) => setCity(value)}
            shouldShowSubmitButton={
              addressLine1 === "" ||
              postalCode === "" ||
              country === "" ||
              city === ""
                ? false
                : true
            }
            toHaveAccessibleErrorMessage={[]}
            onSaveClicked={() => onSaveClicked("venue-address")}
            onDiscardClicked={() => onDiscardClicked("venue-address")}
          />
        ) : (
          <SummaryLabeledView
            tooltipInfo={true}
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"Address"}
            data={[
              { label: "Address line 1", result: addressLine1 },
              { label: "Address line 2", result: addressLine2 },
              { label: "Town/City", result: city },
              { label: "Postal Code", result: postalCode },
              { label: "Country", result: country },
            ]}
            onEditClicked={() => setShouldShowSummaryVenueAddress(false)}
          />
        )}

        <div
          className="EventDetailsEntrancesCont"
          style={{ marginLeft: 24, marginRight: 24 }}
        >
          <SocialLink
            gateKeeperUserName={gateKeeperUserName}
            gateKeeperPassword={gateKeeperPassword}
            gateKeeperUserNameAssistiveText={gateKeeperUserNameAssistiveText}
            gateKeeperPasswordAssistiveText={""}
            onGateKeeperUserNameChange={(value) => setGateKeeperUserName(value)}
            onGateKeeperPasswordChange={(value) => setGateKeeperPassword(value)}
            shouldShowSubmitButton={
              true
              // (entranceList.some((entrance) => entrance?.name?.trim() !== "") &&
              //   gateKeeperUserName?.trim().length > 0 &&
              //   gateKeeperUserNameAssistiveText !== "Username already exists" &&
              //   gateKeeperUserNameAssistiveText !== "*User name is required") ||
              // entranceList.every((entrance) => entrance?.name?.trim() === "")
              //   ? true
              //   : false
            }
            shouldShowSummary={shouldShowSummaryEntrance}
            onSaveClicked={() => onSaveClicked("venue-entrance")}
            onDiscardClicked={() => onDiscardClicked("venue-entrance")}
            onEditClicked={() => setShouldShowSummaryEntrance(false)}
            type={"entrance"}
            data={entranceList}
            onSocialListChange={(value) => setEntranceList(value)}
            checkValidationUserName={(value) => checkValidationUserName(value)}
          />
        </div>
      </div>
    );
  };

  const renderTicket = () => {
    return (
      <div>
        <TotalTickets
          ticketQuantity={totalTicketQuantity}
          onTicketQuantityChange={(value) => setTotalTicketQuantity(value)}
          shouldShowSubmitButton={
            totalTicketQuantity > 0 && !shouldShowSummaryTotalTickets
              ? true
              : false
          }
          onSaveClicked={() => onSaveClicked("total-tickets")}
          shouldShowSummary={shouldShowSummaryTotalTickets}
          onEditClicked={() => setShouldShowSummaryTotalTickets(false)}
          onDiscardClicked={() => onDiscardClicked("total-tickets")}
        />

        {ticketList.map((items, index) => {
          return (
            <div>
              {!items.isSaved ? (
                <Ticket
                  eventStartDate={startDate}
                  eventStartTime={startTime === "00:00 " ? "00:00" : startTime}
                  fees={{
                    platformFee: currencyList?.find(
                      (x) => x.id === eventCurrencyId
                    )?.platformFee,
                    platformFeePct: currencyList?.find(
                      (x) => x.id === eventCurrencyId
                    )?.platformFeePct,
                    ticketGuardFee:
                      kycType === 0
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.ticketGuardFee
                        : 0,
                    bookingPercentValue: bookingPercentValue,
                    isAddBookingFees: isAddBookingFees,
                    isAddFeesToTicketPrice: addFeesToTicketPrice,
                    maxBookingFee: currencyList?.find(
                      (x) => x.id === eventCurrencyId
                    )?.maxBookingFee,
                  }}
                  index={index}
                  ticketReleaseType={items.releaseType}
                  ticketReleaseDate={items.releaseDate}
                  ticketReleaseTime={items.releaseTime}
                  ticketReleaseEndDate={items.releaseEndDate}
                  ticketReleaseEndTime={items.releaseEndTime}
                  ticketName={items.name}
                  ticketQuantity={items.quantity}
                  ticketDescription={items.description}
                  ticketCategory={items.category}
                  ticketCurrency={eventCurrency}
                  ticketPrice={items.price}
                  selectedAgeIndex={items?.selectedAgeIndex}
                  minAge={items?.minimumAge}
                  maxAge={items?.maximumAge}
                  stageReleases={items?.stageReleases}
                  ticketCategoryList={ticketCategoryList}
                  toHaveAccessibleErrorMessage={
                    items.toHaveAccessibleErrorMessageTicket
                  }
                  // toHaveAccessibleErrorMessage={[]}
                  onChangeReleaseType={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].releaseType = value;
                    setTicketList(tempArray);
                  }}
                  onTicketReleaseDateChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].releaseDate = value;
                    setTicketList(tempArray);
                  }}
                  onTicketReleaseTimeChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].releaseTime = value;
                    setTicketList(tempArray);
                  }}
                  onTicketReleaseEndDateChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].releaseEndDate = value;
                    setTicketList(tempArray);
                  }}
                  onTicketReleaseEndTimeChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].releaseEndTime = value;
                    setTicketList(tempArray);
                  }}
                  onTicketNameChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].name = value;
                    setTicketList(tempArray);
                  }}
                  onTicketQuantityChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].quantity = value;
                    setTicketList(tempArray);
                  }}
                  onTicketDescriptionChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].description = value;
                    setTicketList(tempArray);
                  }}
                  onTicketCategoryChange={(value) => {
                    let tempArray = [...ticketList];
                    if (value === "+ Create category") {
                      setIsCategoryModalVisible(true);
                      setSelectedTicketIndex(index);
                      tempArray[index].category = "Select an option";
                    } else {
                      let categoryId = ticketCategoryList.find(
                        (category) => category.name === value
                      )?.id;
                      tempArray[index].category = value;
                      tempArray[index].categoryId = categoryId;
                    }
                    setTicketList(tempArray);
                  }}
                  onTicketCurrencyChange={(value) => {
                    let tempArray = [...ticketList];
                    let currencyId = currencyList.find(
                      (currency) => currency.name === value
                    )?.id;
                    tempArray[index].currency = value;
                    tempArray[index].currencyId = currencyId;

                    setTicketList(tempArray);
                  }}
                  onTicketPriceChange={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].price = value;
                    setTicketList(tempArray);
                  }}
                  onChangeSelectedAgeIndex={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].selectedAgeIndex = value;
                    tempArray[index].minimumAge = "";
                    tempArray[index].maximumAge = "";
                    setTicketList(tempArray);
                  }}
                  onChangeMinAge={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].minimumAge = value;
                    setTicketList(tempArray);
                  }}
                  onChangeMaxAge={(value) => {
                    let tempArray = [...ticketList];
                    tempArray[index].maximumAge = value;
                    setTicketList(tempArray);
                  }}
                  onStageOfferNameChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stageOfferName = value;
                    setTicketList(tempArray);
                  }}
                  onStageQuantityChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stageQuantity = value;
                    setTicketList(tempArray);
                  }}
                  onStageReleaseDateChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stageReleaseDate =
                      value;
                    setTicketList(tempArray);
                  }}
                  onStageReleaseTimeChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stageReleaseTime =
                      value;
                    setTicketList(tempArray);
                  }}
                  onStageReleaseEndDateChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stageReleaseEndDate =
                      value;
                    setTicketList(tempArray);
                  }}
                  onStageReleaseEndTimeChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stageReleaseEndTime =
                      value;
                    setTicketList(tempArray);
                  }}
                  onStagePriceChange={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].stagePrice = value;
                    setTicketList(tempArray);
                  }}
                  onChangeActivateNextRelease={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].activateNextRelease =
                      value;
                    setTicketList(tempArray);
                  }}
                  onChangeAutoTriggerNextRelease={(value, ind) => {
                    let tempArray = [...ticketList];
                    tempArray[index].stageReleases[ind].autoTriggerNextRelease =
                      value;
                    setTicketList(tempArray);
                  }}
                  onClinkingNewCategory={() => {
                    setIsCategoryModalVisible(true);
                    setSelectedTicketIndex(index);
                  }}
                  shouldShowSubmitButton={
                    ticketList[index]?.name?.length >= 1 &&
                    ticketList[index]?.description?.length >= 1 &&
                    ticketList[index]?.category !== "-" &&
                    `${ticketList[index]?.quantity}`.length >= 1 &&
                    (ticketList[index]?.releaseType === "staged" ||
                      (ticketList[index]?.releaseType !== "staged" &&
                        `${ticketList[index]?.price}`?.length >= 1)) &&
                    (ticketList[index]?.selectedAgeIndex === 0 ||
                      (ticketList[index]?.minimumAge !== "" &&
                        // ticketList[index]?.maximumAge !== "" &&
                        ticketList[index]?.selectedAgeIndex !== null)) &&
                    (ticketList[index]?.releaseType === "automatic" ||
                      ticketList[index]?.releaseType === "staged" ||
                      (ticketList[index]?.releaseType === "manual" &&
                        ticketList[index]?.releaseDate !== "DD/MM/YYYY" &&
                        ticketList[index]?.releaseTime !== "-" &&
                        ticketList[index]?.releaseEndDate !== "DD/MM/YYYY" &&
                        ticketList[index]?.releaseEndTime !== "-")) &&
                    (ticketList[index]?.releaseType !== "staged" ||
                      (ticketList[index]?.releaseType === "staged" &&
                        ticketList[index]?.stageReleases?.length > 0 &&
                        ticketList[index]?.stageReleases?.every(
                          (obj) =>
                            obj.stageOfferName !== "" &&
                            `${obj.stageQuantity}` !== "" &&
                            `${obj.stagePrice}` !== "" &&
                            ((obj.activateNextRelease === false &&
                              obj.stageReleaseDate !== "DD/MM/YYYY" &&
                              obj.stageReleaseTime !== "-" &&
                              obj.stageReleaseEndDate !== "DD/MM/YYYY" &&
                              obj.stageReleaseEndTime !== "-") ||
                              obj.activateNextRelease === true)
                        )))
                      ? true
                      : false
                  }
                  addAnotherStageRelease={(indd) => {
                    let tempArray = [...ticketList];
                    // const tempArray = ticketList.map((ticketType) => {
                    //   ticketType.stageReleases.forEach((release) => {
                    //     release.showActivateNextRelease = Boolean(
                    //       release.stageReleaseDate !== "DD/MM/YYYY" &&
                    //         release.stageReleaseTime !== "-" &&
                    //         release.stageReleaseEndDate !== "DD/MM/YYYY" &&
                    //         release.stageReleaseEndTime !== "-" &&
                    //         release.stageOfferName !== "" &&
                    //         release.stageQuantity !== "" &&
                    //         release.stagePrice !== ""
                    //     );

                    //     release.activateNextRelease = Boolean(
                    //       release.stageReleaseDate !== "DD/MM/YYYY" &&
                    //         release.stageReleaseTime !== "-" &&
                    //         release.stageReleaseEndDate !== "DD/MM/YYYY" &&
                    //         release.stageReleaseEndTime !== "-" &&
                    //         release.stageOfferName !== "" &&
                    //         release.stageQuantity !== "" &&
                    //         release.stagePrice !== ""
                    //     );
                    //   });

                    //   return ticketType;
                    // });
                    tempArray[index].stageReleases.push({
                      stageOfferName: "",
                      stageQuantity: "",
                      stageReleaseDate:
                        tempArray[index].stageReleases[indd]
                          .stageReleaseEndDate,
                      // indd === 0
                      //   ? "DD/MM/YYYY"
                      //   : tempArray[index].stageReleases[indd]
                      //       ?.activateNextRelease === false
                      //   ? tempArray[index].stageReleases[indd]
                      //       .stageReleaseEndDate
                      //   : "DD/MM/YYYY",
                      stageReleaseTime:
                        tempArray[index].stageReleases[indd]
                          .stageReleaseEndTime,
                      // indd === 0
                      //   ? "-"
                      //   : tempArray[index].stageReleases[indd]
                      //       ?.activateNextRelease === false
                      //   ? tempArray[index].stageReleases[indd]
                      //       .stageReleaseEndTime
                      //   : "-",
                      stageReleaseEndDate: "DD/MM/YYYY",
                      stageReleaseEndTime: "-",
                      stagePrice: "",
                      showActivateNextRelease: false,
                      activateNextRelease:
                        indd === 0
                          ? null
                          : tempArray[index].stageReleases[indd]
                              ?.activateNextRelease,
                      autoTriggerNextRelease: true,
                      stageCurrency: eventCurrency,
                    });
                    setTicketList(tempArray);
                  }}
                  onDeleteStageReleaseClicked={(ind) => {
                    setSelectedTicketIndex(index);
                    setSelectedStageReleaseIndex(ind);
                    setIsDeleteModalVisible("stageRelease");
                  }}
                  onSaveClicked={() => {
                    const tempCategoryArray = [];
                    ticketList.map((ticket, index) => {
                      ticket.name !== "" &&
                        tempCategoryArray.push({
                          name: ticket?.name,
                          id: index,
                        });
                    });
                    setAddOnCategoryList(tempCategoryArray);
                    let tempArray = [...ticketList];
                    const totalStageReleasesQuantity =
                      items?.stageReleases.reduce(
                        (acc, release) => acc + parseInt(release.stageQuantity),
                        0
                      );
                    if (
                      parseInt(totalStageReleasesQuantity) >
                      parseInt(items.quantity)
                    ) {
                      tempArray[
                        index
                      ]?.toHaveAccessibleErrorMessageTicket?.push("quantity2");
                    } else {
                      tempArray[
                        index
                      ]?.toHaveAccessibleErrorMessageTicket?.filter(
                        (item) => item !== "quantity2"
                      );
                      tempArray[index].isSaved = true;
                      onSaveClicked("tickets");
                    }
                    setTicketList(tempArray);
                  }}
                  onDiscardClicked={() => {
                    let tempArray = [...ticketList];
                    tempArray[index].isSaved = true;
                    // tempArray[index].releaseType =
                    //   item?.ticketTypes[index]?.autoReleaseTickets === true
                    //     ? "automatic"
                    //     : item?.ticketTypes[index]?.eventTicketTypeStageReleases
                    //         .length > 0
                    //     ? "staged"
                    //     : "manual";
                    // tempArray[index].releaseDate =
                    //   item?.ticketTypes[index]?.autoReleaseTickets === false
                    //     ? getRequiredFormattedDate(
                    //         item?.ticketTypes[index]?.onSaleFromDate
                    //       ) || "DD/MM/YYYY"
                    //     : "DD/MM/YYYY";
                    // tempArray[index].releaseTime =
                    //   item?.ticketTypes[index]?.autoReleaseTickets === false
                    //     ? String(
                    //         formattedTime(
                    //           new Date(
                    //             String(item?.ticketTypes[index]?.onSaleFromDate)
                    //           )
                    //         )
                    //       ) || "-"
                    //     : "-";
                    // tempArray[index].releaseEndDate =
                    //   item?.ticketTypes[index]?.autoReleaseTickets === false
                    //     ? getRequiredFormattedDate(
                    //         item?.ticketTypes[index]?.endDate
                    //       ) || "DD/MM/YYYY"
                    //     : "DD/MM/YYYY";
                    // tempArray[index].releaseEndTime =
                    //   item?.ticketTypes[index]?.autoReleaseTickets === false
                    //     ? String(
                    //         formattedTime(
                    //           new Date(
                    //             String(item?.ticketTypes[index]?.endDate)
                    //           )
                    //         )
                    //       ) || "-"
                    //     : "-";
                    // tempArray[index].name =
                    //   item?.ticketTypes[index]?.name || "";
                    // tempArray[index].quantity =
                    //   item?.ticketTypes[index]?.quantity || "";
                    // tempArray[index].description =
                    //   item?.ticketTypes[index]?.description || "";
                    // tempArray[index].category =
                    //   item?.ticketTypes[index]?.category || "Select an option";
                    // tempArray[index].price =
                    //   item?.ticketTypes[index]?.price || "";
                    // tempArray[index].stageReleases =
                    //   item?.ticketTypes[index]?.eventTicketTypeStageReleases
                    //     .length > 0
                    //     ? item?.ticketTypes[
                    //         index
                    //       ]?.eventTicketTypeStageReleases.map((stage) => ({
                    //         stageId: stage?.id || 0,
                    //         stageOfferName: stage?.offerName || "",
                    //         stageReleaseDate:
                    //           getRequiredFormattedDate(stage?.startDate) ||
                    //           "DD/MM/YYYY",
                    //         stageReleaseTime:
                    //           String(
                    //             new Date(stage?.startDate).toLocaleTimeString(
                    //               "en-US",
                    //               {
                    //                 hour12: false,
                    //                 hour: "2-digit",
                    //                 minute: "2-digit",
                    //               }
                    //             )
                    //           ) || "-",
                    //         stageReleaseEndDate:
                    //           getRequiredFormattedDate(stage?.endDate) ||
                    //           "DD/MM/YYYY",
                    //         stageReleaseEndTime:
                    //           String(
                    //             new Date(stage?.endDate).toLocaleTimeString(
                    //               "en-US",
                    //               {
                    //                 hour12: false,
                    //                 hour: "2-digit",
                    //                 minute: "2-digit",
                    //               }
                    //             )
                    //           ) || "-",
                    //         stagePrice: stage?.price || "",
                    //         stageQuantity: stage?.quantity || "",
                    //         stageCurrency: eventCurrency,
                    //       }))
                    //     : [];

                    setTicketList(tempArray);
                  }}
                  onDeleteClicked={() => {
                    setSelectedTicketIndex(index);
                    setIsDeleteModalVisible("ticket");
                  }}
                />
              ) : (
                <SummaryLabeledView
                  ticketPrice={
                    items.releaseType === "staged"
                      ? items.stageReleases.reduce(
                          (acc, curr) =>
                            parseInt(acc) + parseInt(curr.stagePrice),
                          0
                        )
                      : items.price
                  }
                  currencySymbol={
                    currencyList?.find((x) => x.id === eventCurrencyId)
                      ?.symbol || ""
                  }
                  stageReleases={
                    items.releaseType === "staged" ? items.stageReleases : []
                  }
                  fees={{
                    platformFee: currencyList?.find(
                      (x) => x.id === eventCurrencyId
                    )?.platformFee,
                    platformFeePct: currencyList?.find(
                      (x) => x.id === eventCurrencyId
                    )?.platformFeePct,
                    ticketGuardFee:
                      kycType === 0
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.ticketGuardFee
                        : 0,
                    bookingPercentValue: bookingPercentValue,
                    isAddBookingFees: isAddBookingFees,
                    isAddFeesToTicketPrice: addFeesToTicketPrice,
                    maxBookingFee: currencyList?.find(
                      (x) => x.id === eventCurrencyId
                    )?.maxBookingFee,
                  }}
                  isExpiredEvent={eventType === "expired" ? true : false}
                  onAddBoltClicked={() => {}}
                  flag={"ticket"}
                  deleteOption={ticketList.length === 1 ? false : true}
                  onRemoveClicked={() => {
                    setIsDeleteModalVisible("ticket");
                    setSelectedTicketIndex(index);
                  }}
                  heading={items.name}
                  data={[
                    { label: "Quantity", result: items.quantity },
                    { label: "Ticket description", result: items.description },
                    {
                      label: "Ticket price",
                      result: `${
                        currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.symbol || ""
                      }${
                        items.releaseType === "staged"
                          ? items.stageReleases.reduce(
                              (acc, curr) =>
                                parseFloat(acc) + parseFloat(curr.stagePrice),
                              0
                            )
                          : items.price
                      }`,
                    },
                  ]}
                  onEditClicked={() => {
                    let tempArray = [...ticketList];
                    tempArray[index].isSaved = false;
                    setTicketList(tempArray);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderRequirement = () => {
    return (
      <div>
        {!shouldShowSummaryKYC ? (
          <KYC
            type={kycType}
            minimumAge={minimumAge}
            onChangeKYCType={(value) => setKycType(value)}
            onChangeMinimumAge={(value) => setMinimumAge(value)}
            shouldShowSubmitButton={
              kycType === 1
                ? true
                : kycType === 0 && minimumAge !== "-"
                ? true
                : false
            }
            onSaveClicked={() => onSaveClicked("kyc")}
            onDiscardClicked={() => onDiscardClicked("kyc")}
          />
        ) : (
          <SummaryLabeledView
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"TicketGuard™"}
            data={[
              {
                label: "Status",
                result: kycType === 0 ? "Required" : "Not required",
              },
              kycType === 0 && {
                label: "Minimum age for Verification",
                result: minimumAge,
              },
            ]}
            onEditClicked={() => setShouldShowSummaryKYC(false)}
          />
        )}

        {!shouldShowSummaryRefundPolicy ? (
          <RefundPolicy
            shouldShowSubmitButton={isRefundAcceptable === null ? false : true}
            isRefundAccepted={isRefundAcceptable}
            onRefundAcceptedChange={(value) => {
              setIsRefundAcceptable(value);
            }}
            onSaveClicked={() => onSaveClicked("refund-policy")}
            onDiscardClicked={() => onDiscardClicked("refund-policy")}
          />
        ) : (
          <SummaryLabeledView
            tooltipInfo={true}
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"Refund Policy"}
            data={[
              {
                label: "Refund type",
                result:
                  isRefundAcceptable === 0 || isRefundAcceptable === null
                    ? "No refunds accepted"
                    : "Refund request accepted",
              },
            ]}
            onEditClicked={() => setShouldShowSummaryRefundPolicy(false)}
          />
        )}
      </div>
    );
  };

  const renderFees = () => {
    return (
      <div>
        {!shouldShowSummaryFees ? (
          <Fees
            feesObject={currencyList?.find((x) => x.id === eventCurrencyId)}
            shouldShowSubmitButton={
              addFeesToTicketPrice === null ? false : true
            }
            addTenPercentageBooking={isAddBookingFees}
            onAddTenPercentageBookingChange={(value) =>
              setIsAddBookingFees(value)
            }
            onAddFeesToTicketPriceChange={(value) =>
              setAddFeesToTicketPrice(value)
            }
            addFeesToTicketPrice={addFeesToTicketPrice}
            onChangeBookingPrice={(value) => setBookingPercentValue(value)}
            bookingPercentVal={bookingPercentValue}
            onSaveClicked={() => onSaveClicked("fees")}
            onDiscardClicked={() => onDiscardClicked("fees")}
            currencySymbol={
              currencyList?.find((x) => x.id === eventCurrencyId)?.symbol || ""
            }
            feeLineItemArray={
              kycType === 0
                ? isAddBookingFees
                  ? [
                      {
                        label1: "Platform fee",
                        label2: currencyList?.find(
                          (x) => x.id === eventCurrencyId
                        )?.platformFee
                          ? currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                            ? `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFeePct
                              }% +  ${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.symbol
                              }${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFee
                              }
              `
                            : `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFeePct
                              }% + ${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFee
                              }
  `
                          : "",
                        label3: `${
                          currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.platformFeePct
                        }% of ticket price`,
                      },
                      {
                        label1: "TicketGuard™ fee",
                        label2: currencyList?.find(
                          (x) => x.id === eventCurrencyId
                        )?.ticketGuardFee
                          ? currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                            ? `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.symbol
                              }${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.ticketGuardFee
                              }
          `
                            : `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.ticketGuardFee
                              }
`
                          : "",
                      },
                      {
                        label1: "Booking fee",
                        label2: currencyList?.find(
                          (x) => x.id === eventCurrencyId
                        )?.symbol
                          ? `${bookingPercentValue}% per ticket (${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.symbol
                            })`
                          : `${bookingPercentValue}% per ticket`,
                        label3:
                          "Fee will vary depending on the price of your ticket(s)",
                      },
                    ]
                  : [
                      {
                        label1: "Platform fee",
                        label2: currencyList?.find(
                          (x) => x.id === eventCurrencyId
                        )?.platformFee
                          ? currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                            ? `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFeePct
                              }% +  ${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.symbol
                              }${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFee
                              }
              `
                            : `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFeePct
                              }% + ${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.platformFee
                              }
  `
                          : "",
                        label3: `${
                          currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.platformFeePct
                        }% of ticket price`,
                      },
                      {
                        label1: "TicketGuard™ fee",
                        label2: currencyList?.find(
                          (x) => x.id === eventCurrencyId
                        )?.ticketGuardFee
                          ? currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                            ? `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.symbol
                              }${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.ticketGuardFee
                              }
          `
                            : `${
                                currencyList?.find(
                                  (x) => x.id === eventCurrencyId
                                )?.ticketGuardFee
                              }
`
                          : "",
                      },
                    ]
                : isAddBookingFees
                ? [
                    {
                      label1: "Platform fee",
                      label2: currencyList?.find(
                        (x) => x.id === eventCurrencyId
                      )?.platformFee
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                          ? `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% +  ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.symbol
                            }${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            }
              `
                          : `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% + ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            }
  `
                        : "",
                      label3: `${
                        currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.platformFeePct
                      }% of ticket price`,
                    },
                    {
                      label1: "Booking fee",
                      label2: currencyList?.find(
                        (x) => x.id === eventCurrencyId
                      )?.symbol
                        ? `${bookingPercentValue}% per ticket (${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                          })`
                        : `${bookingPercentValue}% per ticket`,
                      label3:
                        "Fee will vary depending on the price of your ticket(s)",
                    },
                  ]
                : [
                    {
                      label1: "Platform fee",
                      label2: currencyList?.find(
                        (x) => x.id === eventCurrencyId
                      )?.platformFee
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                          ? `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% +  ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.symbol
                            }${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            }
              `
                          : `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% + ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            }
  `
                        : "",
                      label3: `${
                        currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.platformFeePct
                      }% of ticket price`,
                    },
                  ]
            }
          />
        ) : (
          <SummaryLabeledView
            isExpiredEvent={eventType === "expired" ? true : false}
            heading={"Fees per ticket sold"}
            data={
              kycType === 0
                ? [
                    {
                      label: "Platform fee",
                      result: currencyList?.find(
                        (x) => x.id === eventCurrencyId
                      )?.platformFee
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                          ? `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.symbol
                            }${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            } + ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% 
            `
                          : `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% + ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            }
`
                        : "",
                      result1: `(${
                        currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.platformFeePct
                      }% of ticket price)`,
                    },
                    {
                      label: "TicketGuard™ fee",
                      result: currencyList?.find(
                        (x) => x.id === eventCurrencyId
                      )?.ticketGuardFee
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                          ? `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.symbol
                            }${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.ticketGuardFee
                            }
        `
                          : `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.ticketGuardFee
                            }
`
                        : "",
                    },
                    {
                      label: "Booking fees",
                      result: !isAddBookingFees
                        ? "No booking fee is added"
                        : currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                        ? `${bookingPercentValue}% (${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                          }) per ticket `
                        : `${bookingPercentValue}% per ticket`,
                      result1:
                        "(Fee will vary depending on the price of your tickets)",
                    },
                    {
                      label: "Fee charging method",
                      result:
                        addFeesToTicketPrice === 0
                          ? "Add fees to ticket price"
                          : "Absorb the fees",
                    },
                  ]
                : [
                    {
                      label: "Platform fee",
                      result: currencyList?.find(
                        (x) => x.id === eventCurrencyId
                      )?.platformFee
                        ? currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                          ? `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.symbol
                            }${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            } + ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }%
          `
                          : `${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFeePct
                            }% + ${
                              currencyList?.find(
                                (x) => x.id === eventCurrencyId
                              )?.platformFee
                            }
`
                        : "",
                      result1: `(${
                        currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.platformFeePct
                      }% of ticket price)`,
                    },

                    {
                      label: "Booking fees",
                      result: !isAddBookingFees
                        ? "No booking fee is added"
                        : currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                        ? `${bookingPercentValue}% (${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                          }) per ticket `
                        : `${bookingPercentValue}% per ticket`,
                      result1:
                        "(Fee will vary depending on the price of your tickets)",
                    },
                    {
                      label: "Fee charging method",
                      result:
                        addFeesToTicketPrice === 0
                          ? "Added fees to ticket price"
                          : "Absorb the fees",
                    },
                  ]
            }
            onEditClicked={() => setShouldShowSummaryFees(false)}
          />
        )}
      </div>
    );
  };

  const renderAddOn = () => {
    return (
      <div>
        {ticketAddOnList.length === 0 ? (
          <div className="add-on-empty-container-event-details">
            <Text marginBottom={16} variant={"m500"}>
              {"Add Ons (optional)"}
            </Text>

            <Text marginBottom={40} variant={"r300"}>
              {"Create customised Add Ons and attach them to your tickets"}
            </Text>

            <ButtonPrimary
              isSecondary={true}
              label={"Create Add-ons"}
              width={328}
              onPress={() => {
                let tempArrayAddOn = [...ticketAddOnList];
                tempArrayAddOn.push({
                  name: "",
                  addOnImage: "",
                  addOnBase64Image: "",
                  quantity: "",
                  description: "",
                  currency:
                    eventCurrency === "Select an option" ? "-" : eventCurrency,
                  price: "",
                  addedTo: ["Select an option"],
                  addedToId: [
                    {
                      eventTicketTypeId: 0,
                      eventTicketTypeIndex: "",
                    },
                  ],
                  isSaved: false,
                  toHaveAccessibleErrorMessageTicketAddOn: [],
                  addOnCategoryList: addOnCategoryList || [],
                });
                setTicketAddOnList(tempArrayAddOn);
              }}
            />
          </div>
        ) : (
          ticketAddOnList.map((items, index) => {
            return (
              <div>
                {!items.isSaved ? (
                  // <TicketAddOn
                  //   index={index}
                  //   addOnName={items.name}
                  //   addOnQuantity={items.quantity}
                  //   addOnDescription={items.description}
                  //   currencyList={[]}
                  //   addOnCategoryList={items.addOnCategoryList || []}
                  //   addOnCurrency={eventCurrency}
                  //   addOnPrice={items.price}
                  //   addOnAddedTo={items.addedTo}
                  //   toHaveAccessibleErrorMessageTicketAddOn={[]}
                  //   onNameChange={(value) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].name = value;
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onQuantityChange={(value) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].quantity = value;
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onDescriptionChange={(value) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].description = value;
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onCurrencyChange={(value) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].currency = value;
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onPriceChange={(value) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].price = value;
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onAddedToChange={(value, ind, rowIndex) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].addedTo[rowIndex] = value;
                  //     tempArray[index].addedToId[
                  //       rowIndex
                  //     ].eventTicketTypeIndex = addOnCategoryList.findIndex(
                  //       (item) => item.name === value
                  //     );

                  //     tempArray[index].addedToId[rowIndex].id = 0;

                  //     tempArray[index].addedToId[rowIndex].eventTicketTypeId =
                  //       ticketList.find((item) => item.name === value)?.id;

                  //     const filteredArr = addOnCategoryList.filter(
                  //       (item) => !items.addedTo?.includes(item.name)
                  //     );
                  //     tempArray[index].addOnCategoryList = filteredArr;

                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   shouldShowSubmitButton={
                  //     ticketAddOnList[index]?.name?.length >= 1 &&
                  //     ticketAddOnList[index]?.description?.length >= 1 &&
                  //     `${ticketAddOnList[index]?.price}`?.length >= 1 &&
                  //     `${ticketAddOnList[index]?.quantity}`?.length >= 1 &&
                  //     ticketAddOnList[index]?.addedTo?.length > 0 &&
                  //     ticketAddOnList[index]?.addedTo?.includes(
                  //       "Select an option"
                  //     ) === false
                  //       ? true
                  //       : false
                  //   }
                  //   showAddAnotherAddOn={
                  //     ticketAddOnList[index].addedTo[
                  //       ticketAddOnList[index].addedTo.length - 1
                  //     ] !== "Select an option" ||
                  //     ticketAddOnList[index].addedTo.length === 0
                  //       ? true
                  //       : false
                  //   }
                  //   onRemoveAddOnClicked={(ind) => {
                  //     let tempArray = [...ticketAddOnList];
                  //     const newAddedToArray = [
                  //       ...tempArray[index].addedTo.slice(0, ind),
                  //       ...tempArray[index].addedTo.slice(ind + 1),
                  //     ];
                  //     const newAddedToIdArray = [
                  //       ...tempArray[index].addedToId.slice(0, ind),
                  //       ...tempArray[index].addedToId.slice(ind + 1),
                  //     ];
                  //     tempArray[index].addedTo = newAddedToArray;
                  //     tempArray[index].addedToId = newAddedToIdArray;

                  //     const filteredArr = addOnCategoryList.filter(
                  //       (item) => !items.addedTo?.includes(item.name)
                  //     );

                  //     tempArray[index].addOnCategoryList = filteredArr;

                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onAddAnotherAddOnClicked={() => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].addedTo?.push("Select an option");
                  //     tempArray[index].addedToId?.push({
                  //       eventTicketTypeId: 0,
                  //       eventTicketTypeIndex: "",
                  //     });
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onSaveClicked={() => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].isSaved = true;
                  //     setTicketAddOnList(tempArray);
                  //     onSaveClicked("addOn");
                  //   }}
                  //   onDiscardClicked={() => {
                  //     let tempArray = [...ticketAddOnList];
                  //     tempArray[index].isSaved = true;
                  //     setTicketAddOnList(tempArray);
                  //   }}
                  //   onDeleteClicked={() => {
                  //     setSelectedAddOnIndex(index);
                  //     setIsDeleteModalVisible("ticketAddOn");
                  //   }}
                  // />
                  <TicketAddOn
                    index={index}
                    addOnName={items.name}
                    addOnImage={items.addOnImage}
                    addOnQuantity={items.quantity}
                    addOnDescription={items.description}
                    currencyList={[]}
                    addOnCategoryList={items.addOnCategoryList || []}
                    addOnCurrency={eventCurrency}
                    addOnPrice={items.price}
                    addOnAddedTo={items.addedTo}
                    toHaveAccessibleErrorMessageTicketAddOn={[]}
                    onNameChange={(value) => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].name = value;
                      setTicketAddOnList(tempArray);
                    }}
                    onAddOnImageChange={(value) => {
                      if (value) {
                        setToCropImage(value);
                        setSelectedAddOnIndex(index);
                        setIsCropModalVisible("addOnImageCrop");
                      } else {
                        let tempArray = [...ticketAddOnList];
                        tempArray[index].addOnImage = value;
                        setTicketAddOnList(tempArray);
                      }
                    }}
                    onQuantityChange={(value) => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].quantity = value;
                      setTicketAddOnList(tempArray);
                    }}
                    onDescriptionChange={(value) => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].description = value;
                      setTicketAddOnList(tempArray);
                    }}
                    onCurrencyChange={(value) => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].currency = value;
                      setTicketAddOnList(tempArray);
                    }}
                    onPriceChange={(value) => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].price = value;
                      setTicketAddOnList(tempArray);
                    }}
                    onAddedToChange={(value, ind, rowIndex) => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].addedTo[rowIndex] = value;
                      tempArray[index].addedToId[
                        rowIndex
                      ].eventTicketTypeIndex = addOnCategoryList.findIndex(
                        (item) => item.name === value
                      );

                      tempArray[index].addedToId[rowIndex].id = 0;

                      tempArray[index].addedToId[rowIndex].eventTicketTypeId =
                        ticketList.find((item) => item.name === value)?.id;

                      const filteredArr = addOnCategoryList.filter(
                        (item) => !items.addedTo?.includes(item.name)
                      );
                      tempArray[index].addOnCategoryList = filteredArr;

                      setTicketAddOnList(tempArray);
                    }}
                    shouldShowSubmitButton={
                      ticketAddOnList[index]?.name?.length >= 1 &&
                      ticketAddOnList[index]?.description?.length >= 1 &&
                      `${ticketAddOnList[index]?.price}`?.length >= 1 &&
                      `${ticketAddOnList[index]?.quantity}`?.length >= 1 &&
                      ticketAddOnList[index]?.addedTo?.length > 0 &&
                      ticketAddOnList[index]?.addedTo?.includes(
                        "Select an option"
                      ) === false
                        ? true
                        : false
                    }
                    showAddAnotherAddOn={
                      ticketAddOnList[index].addedTo[
                        ticketAddOnList[index].addedTo.length - 1
                      ] !== "Select an option" ||
                      ticketAddOnList[index].addedTo.length === 0
                        ? true
                        : false
                    }
                    onRemoveAddOnClicked={(ind) => {
                      let tempArray = [...ticketAddOnList];
                      const newAddedToArray = [
                        ...tempArray[index].addedTo.slice(0, ind),
                        ...tempArray[index].addedTo.slice(ind + 1),
                      ];
                      const newAddedToIdArray = [
                        ...tempArray[index].addedToId.slice(0, ind),
                        ...tempArray[index].addedToId.slice(ind + 1),
                      ];
                      tempArray[index].addedTo = newAddedToArray;
                      tempArray[index].addedToId = newAddedToIdArray;

                      const filteredArr = addOnCategoryList.filter(
                        (item) => !items.addedTo?.includes(item.name)
                      );

                      tempArray[index].addOnCategoryList = filteredArr;

                      setTicketAddOnList(tempArray);
                    }}
                    onAddAnotherAddOnClicked={() => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].addedTo?.push("Select an option");
                      tempArray[index].addedToId?.push({
                        eventTicketTypeId: 0,
                        eventTicketTypeIndex: "",
                      });
                      setTicketAddOnList(tempArray);
                    }}
                    onSaveClicked={() => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].isSaved = true;
                      setTicketAddOnList(tempArray);
                      onSaveClicked("addOn");
                    }}
                    onDiscardClicked={() => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].isSaved = true;
                      setTicketAddOnList(tempArray);
                    }}
                    onDeleteClicked={() => {
                      setSelectedAddOnIndex(index);
                      setIsDeleteModalVisible("ticketAddOn");
                    }}
                  />
                ) : (
                  <SummaryLabeledView
                    isExpiredEvent={eventType === "expired" ? true : false}
                    deleteOption={false}
                    onRemoveClicked={() => {
                      setIsDeleteModalVisible("ticketAddOn");
                      setSelectedAddOnIndex(index);
                    }}
                    heading={items.name}
                    data={[
                      { label: "Add On Name", result: items.name },
                      {
                        label: "Quantity",
                        result: items.quantity,
                      },
                      {
                        label: "Add On Description",
                        result: items.description,
                      },
                      { label: "Currency", result: eventCurrency },
                      {
                        label: "Add On Price",
                        result: `${
                          currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol || ""
                        }${items.price}`,
                      },
                      {
                        label: "Can be added to",
                        result: items.addedTo?.join(", "),
                      },
                      {
                        label: "Add On Image",
                        result: items.addOnImage,
                      },
                    ]}
                    onEditClicked={() => {
                      let tempArray = [...ticketAddOnList];
                      tempArray[index].isSaved = false;
                      setTicketAddOnList(tempArray);
                    }}
                  />
                )}
              </div>
            );
          })
        )}
      </div>
    );
  };

  const renderGuests = () => {
    return (
      <div>
        <div
          style={{ margin: 32 }}
          className="generic-common-row-div-space-between guestList-contentBtn-block"
        >
          <div
            style={{ width: "50%", marginRight: 80 }}
            className="generic-common-column-div guestList-content-lft"
          >
            <Text variant={"m600"}>
              <span className="guestList-tittle">Guest list</span>
            </Text>
            <Text marginTop={16} variant={"r300"}>
              Tickets can be added to your guest list and assigned to attendees'
              email addresses.
            </Text>
          </div>
          <div
            style={{ width: "50%", alignItems: "flex-end" }}
            className="generic-common-column-div guestList-btn-rht"
          >
            {/* <ButtonPrimary
              width={340}
              showLeftIcon={true}
              icon={<AddFilled fill={"var(--white)"} height={24} width={24} />}
              isSecondary={true}
              label={"Add another ticket"}
              onPress={() => {
                setGuestList([
                  {
                    ticketCategory: "Select an option",
                    email: "",
                    status: "notActive",
                    emailError: false,
                    quantityError: false,
                    id: null,
                    eventTicketTypeId: null,
                    quantity: "-",
                    ticketIds: [],
                  },
                  ...guestList,
                ]);
              }}
            /> */}
          </div>
        </div>

        <div
          style={{ margin: "0px 24px" }}
          className="home-scroll-container homeTob-btns-tool-lft"
        >
          {guestAssetOptions.map((items, ind) => {
            return (
              <div key={ind} style={{ marginRight: 8 }}>
                <div class="tag-div">
                  <Tags
                    paddingVertical={8}
                    label={
                      items.name +
                      (items?.length ? " (" + items.length + ")" : "")
                    }
                    selected={
                      ind == selectedSegmentGuestIndexAsset ? true : false
                    }
                    onChangeSelection={() =>
                      segmentBtnPressedGuestAsset(ind, items)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>

        {filteredGuestList.map((items, index) => {
          return (
            <div>
              <Guests
                shouldShowDeleteButton={true}
                categoryList={[...addOnCategoryList, ...ticketAddOnList] || []}
                index={index}
                ticketCategory={items.ticketCategory}
                quantity={items.quantity || "-"}
                email={items.email}
                status={items.guestTicketIsPending ? 'notActive' : items.status}
                emailError={items.guestTicketIsPending ? true : items.emailError}
                quantityError={items.quantityError}
                onTicketQuantityChange={async (value) => {
                  let tempArray = [...guestList];
                  tempArray[index].quantity = value;
                  tempArray[index].quantityError = false;
                  tempArray[index].ticketCategory !== "Select an option" &&
                  emailReg?.test(tempArray[index].email) === true &&
                  // (await onValidateGuestEmail(tempArray[index].email)) ===
                  //   false &&
                  value !== "-"
                    ? // &&
                      // (await validateGuestTicketQuantityRequest(
                      //   tempArray[index].email,
                      //   tempArray[index].ticketCategory,
                      //   value,
                      //   index
                      // )) === true
                      (tempArray[index].status = "created")
                    : (tempArray[index].status = "notActive");
                  console.log("tempArray[index].status1", tempArray);

                  setGuestList(tempArray);
                }}
                onTicketCategoryChange={async (value) => {
                  let tempArray = [...guestList];
                  tempArray[index].ticketCategory = value;
                  tempArray[index].quantityError = false;
                  tempArray[index].emailError = false;
                  tempArray[index].guestTicketIsPending = false;
                  tempArray[index].email?.trim() !== "" &&
                  tempArray[index].quantity !== "-" &&
                  value !== "Select an option" &&
                  emailReg?.test(tempArray[index].email) === true
                    ? // &&
                      // (await onValidateGuestEmail(tempArray[index].email)) ===
                      //   false
                      //   &&
                      // (await validateGuestTicketQuantityRequest(
                      //   tempArray[index].email,
                      //   value,
                      //   tempArray[index].quantity,
                      //   index
                      // )) === true
                      (tempArray[index].status = "created")
                    : (tempArray[index].status = "notActive");
                  console.log("tempArray[index].status2", tempArray);
                  setGuestList(tempArray);
                }}
                onEmailChange={async (value) => {
                  let tempArray = [...guestList];
                  tempArray[index].email = value;
                  tempArray[index].emailError = false;
                  tempArray[index].guestTicketIsPending = false;
                  if (emailReg?.test(value) === true) {
                    tempArray[index].ticketCategory !== "Select an option" &&
                    tempArray[index].quantity !== "-"
                      ? // &&
                        // (await onValidateGuestEmail(value)) === false
                        // &&
                        // (await validateGuestTicketQuantityRequest(
                        //   value,
                        //   tempArray[index].ticketCategory,
                        //   tempArray[index].quantity,
                        //   index
                        // )) === true
                        (tempArray[index].status = "created")
                      : (tempArray[index].status = "notActive");

                    tempArray[index].emailError = false;
                    // (await onValidateGuestEmail(value)) === true
                    //   ? (tempArray[index].emailError = true)
                    //   : (tempArray[index].emailError = false);
                  } else {
                    tempArray[index].status = "notActive";
                    tempArray[index].emailError = true;
                  }
                  console.log("tempArray[index].status3", tempArray);
                  setGuestList(tempArray);
                }}
                onSendTicketClicked={async () => {
                  let tempArray = [...guestList];

                  if (
                    (await validateGuestTicketQuantityRequest(
                      tempArray[index].email,
                      tempArray[index].ticketCategory,
                      tempArray[index].quantity,
                      index
                    )) === true
                  ) {
                    tempArray[index].quantityError = false;
                    setSelectedGuestIndex(index);
                    setIsGuestModalVisible(
                      guestList[index].status === "sent"
                        ? "revokeTicket"
                        : "sendTicket"
                    );
                    setSelectedGuestIndexToSendOrRevokeTicket(index);
                  } else {
                    tempArray[index].status = "notActive";
                    tempArray[index].quantityError = true;
                  }

                  // tempArray[index].status = "notActive";
                  setGuestList(tempArray);
                }}
                onDeleteClicked={() => {
                  // removeGuestListRequest(index);
                  setIsGuestModalVisible("deleteGuest");
                  setSelectedGuestIndexToSendOrRevokeTicket(index);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderDiscount = () => {
    return (
      <div>
        {discountList.length === 0 ? (
          <div className="discount-empty-container-event-details create-discount-code-top-div">
            <div style={{ flex: 1 }} className="generic-common-column-div">
              <Text marginBottom={16} variant={"m500"}>
                {"Discount codes"}
              </Text>

              <Text variant={"r300"}>
                {
                  "Enable attendees to apply custom discount codes to their purchases."
                }
              </Text>
            </div>

            <ButtonPrimary
              isSecondary={true}
              label={"Create discount code"}
              width={360}
              onPress={() => {
                setSelectedDiscountIndex(null);
                initializeDiscountValue();
                setIsGuestModalVisible("createDiscountCode");
                // let tempDiscountList = [...discountList];
                // tempDiscountList.push({
                //   name: "",
                //   quantity: "",
                //   description: "",
                //   currency:
                //     eventCurrency === "Select an option" ? "-" : eventCurrency,
                //   price: "",
                //   addedTo: ["Select an option"],
                //   addedToId: [
                //     {
                //       eventTicketTypeId: 0,
                //       eventTicketTypeIndex: "",
                //     },
                //   ],
                //   isSaved: false,
                //   toHaveAccessibleErrorMessageTicketAddOn: [],
                //   addOnCategoryList: addOnCategoryList || [],
                // });
                // setDiscountList(tempDiscountList);
              }}
            />
          </div>
        ) : (
          <div className="add-on-empty-container-event-details">
            <div
              style={{ width: "100%" }}
              className="create-discount-code-top-div generic-common-row-div-space-between"
            >
              <div style={{ flex: 1 }} className="generic-common-column-div">
                <Text marginBottom={16} variant={"m500"}>
                  {"Discount codes"}
                </Text>

                <Text variant={"r300"}>
                  {
                    "Enable attendees to apply custom discount codes to their purchases."
                  }
                </Text>
              </div>

              <ButtonPrimary
                isSecondary={true}
                label={"Create discount code"}
                width={360}
                onPress={() => {
                  initializeDiscountValue();
                  setIsGuestModalVisible("createDiscountCode");
                  setSelectedDiscountIndex(null);
                  // let tempDiscountList = [...discountList];
                  // tempDiscountList.push({
                  //   heading: "",
                  //   discountAmount: "",
                  //   eligibleCustomer: "",
                  //   maxDiscountLimit: '',
                  //   startDate: "",
                  //   endDate: "",
                  //   purchaserAmount: '',
                  // });
                  // setDiscountList(tempDiscountList);
                }}
              />
            </div>

            <div
              style={{ marginTop: "32px" }}
              className="generic-common-divider"
            />

            {discountList.map((items, index) => {
              return (
                <div style={{ width: "100%" }}>
                  <Discount
                    status={items?.status || ""}
                    deleteOption={true}
                    heading={items?.code || ""}
                    discountAmount={
                      items?.isPercentage
                        ? `${parseInt(items?.discountAmount)}%`
                        : `${
                            currencyList?.find((x) => x.id === item?.currencyId)
                              ?.symbol
                          }${items?.discountAmount}`
                    }
                    eligibleCustomer={`Eligible for ${
                      items?.eventTicketTypeDiscountCodeUsageRestriction
                        ? items?.eventTicketTypeDiscountCodeUsageRestriction?.replace(
                            /([a-z])([A-Z])/g,
                            "$1 $2"
                          ) ||
                          items?.eventTicketTypeDiscountCodeUsageRestriction
                        : [...ticketList, ...ticketAddOnList]
                            .filter((item) =>
                              items?.eventTicketTypeIds?.includes(item.id)
                            )
                            .map((item) => item.name)
                            .join(", ")
                    }`}
                    // maxDiscountLimit={`Code can only be used ${
                    //   items?.numberAvailable
                    //     ? items?.numberAvailable
                    //     : items?.numberAvailablePerCustomer
                    // } times / ${
                    //   items?.numberAvailable
                    //     ? `Maximum use limit for code`
                    //     : `Limit of one use per customer`
                    // }`}
                    maxDiscountLimit={`Code can be used for up to ${
                      items?.numberAvailable
                        ? items?.numberAvailable
                        : items?.numberAvailablePerCustomer
                    }${
                      items?.numberAvailable
                        ? ` ticket${items?.numberAvailable > 1 ? "s" : ""}`
                        : ` times / Limit of one use per customer`
                    }`}
                    startDate={
                      items?.startDate
                        ? getRequiredFormattedDate(items?.startDate)
                        : "DD/MM/YYYY"
                    }
                    endDate={
                      items?.endDate
                        ? getRequiredFormattedDate(items?.endDate)
                        : "DD/MM/YYYY"
                    }
                    numberOfUses={items?.numberOfUses || ""}
                    onEditClicked={() => {
                      setSelectedDiscountIndex(index);
                      initializeDiscountValue(items);
                      setIsGuestModalVisible("createDiscountCode");
                    }}
                    onRemoveClicked={() => {
                      setSelectedDiscountIndex(index);
                      setIsDeleteModalVisible("discountCode");
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}

        {mailchimpUserInfo?.isConnected ? (
          <div
            style={{ marginLeft: 24, marginRight: 24 }}
            class="allla SettingCommonBoxCont"
          >
            <div
              className="generic-common-row-div-space-between-not-centered SettingPaymentTopCont"
              style={{
                paddingBottom: 30,
                borderBottomWidth: 1,
              }}
            >
              <div className="CommonSpaceBetweenRow">
                <div className="CommonSpaceBetweenCol">
                  <div>
                    <Text textclass="Heading-div" variant={"m500"}>
                      Mailchimp
                    </Text>
                  </div>
                  <div
                    className="generic-common-row-div"
                    style={{ marginTop: 16 }}
                  >
                    <SuccessFilledWhite width={24} height={24} />
                    <Text
                      color={"var(--green)"}
                      marginLeft={4}
                      variant={"m300"}
                    >
                      Connected
                    </Text>
                  </div>
                </div>

                <div className="CommonSpaceBetweenCol">
                  <div className="BtnCont296">
                    <ButtonPrimary
                      type={"error"}
                      isSecondary={true}
                      label={"Disconnect Mailchimp account"}
                      onPress={() =>
                        setIsDeleteModalVisible("disconnectMailChimp")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="SettingPaymentBodyCont CommonSpaceBetweenRow Gap34"
              style={{
                marginTop: 30,
              }}
            >
              <div className="generic-common-column-div-half-width">
                <Text variant={"m400"}>Username</Text>

                <div className="email-textWrap">
                  <Text marginTop={8} color={"var(--midBlue)"} variant={"m400"}>
                    {mailchimpUserInfo?.mailChimpUserName}
                  </Text>
                </div>
              </div>
              <div className="generic-common-column-div-half-width">
                <Text variant={"m400"}>Email</Text>

                <div className="email-textWrap">
                  <Text marginTop={8} color={"var(--midBlue)"} variant={"m400"}>
                    {mailchimpUserInfo?.mailChimpUserEmail}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="discount-empty-container-event-details create-discount-code-top-div">
            <div className="generic-common-row-div-top">
              <MailChimpIcon />
              <div
                style={{ flex: 1, marginLeft: "16px" }}
                className="generic-common-column-div"
              >
                <Text marginBottom={8} variant={"b400"}>
                  {"Marketing emails"}
                </Text>

                <Text variant={"r200"}>
                  {
                    "We recommend using Mailchimp for marketing emails. Connect it easily in your account settings."
                  }
                </Text>
              </div>
            </div>

            <ButtonPrimary
              isSecondary={true}
              label={"Connect to Mailchimp"}
              width={360}
              onPress={() => {
                getMailChimpLoginURLRequest();
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const remainingEmailComposeSubjectCharCount = emailComposeSubject.length;
  const remainingEmailComposeMessageCharCount = emailComposeMessage.length;

  const renderCommunications = () => {
    return (
      <div className="email-attendee-top-div">
        {isComposeEmailActive ? (
          <div className="add-on-empty-container-event-details">
            <div className="generic-common-row-div-top">
              <EmailAttendee />
              <div
                style={{ flex: 1, marginLeft: "16px" }}
                className="generic-common-column-div"
              >
                <Text marginBottom={8} variant={"b400"}>
                  {"Email attendees"}
                </Text>

                <Text variant={"r200"}>
                  {
                    "Compose and send an email directly to all your event attendees."
                  }
                </Text>
              </div>
            </div>
            <div className="compose-email-attendee-inner-box-container">
              <div className="compose-email-parent-text-box-container">
                <div className="compose-email-child-text-box-container">
                  <Text variant={"b200"}>Subject</Text>

                  <InputField
                    inputValue={emailComposeSubject}
                    verifyNotNeed={true}
                    shouldShowErrorOnAssistive={true}
                    placeholder={"Enter subject"}
                    secureTextEntry={false}
                    editable={true}
                    type={"eventName"}
                    withOutIcon={true}
                    isError={false}
                    maxLength={100}
                    onBlur={() => {}}
                    onChangeValue={(text) => setEmailComposeSubject(text)}
                  />

                  <Text
                    color={"var(--midBlue)"}
                    alignSelf={"flex-end"}
                    variant={"label"}
                  >
                    {remainingEmailComposeSubjectCharCount}/100 characters
                  </Text>
                </div>

                <div className="compose-email-child-text-box-container">
                  <Text variant={"b200"}>Message</Text>

                  <div
                    style={{ position: "relative" }}
                    className="event-description-input-container"
                  >
                    <textarea
                      className="event-description-top-aligned-input"
                      maxLength={2000}
                      disabled={false}
                      type={"text"}
                      placeholder={"Enter your message"}
                      aria-multiline="true"
                      autoCapitalize={"words"}
                      value={emailComposeMessage}
                      onChange={(event) =>
                        setEmailComposeMessage(event.target.value)
                      }
                      rows={5}
                    />
                  </div>

                  <Text
                    color={"var(--midBlue)"}
                    alignSelf={"flex-end"}
                    variant={"label"}
                  >
                    {remainingEmailComposeMessageCharCount}/2000 characters
                  </Text>
                </div>
              </div>

              <div className="compose-email-attendee-warning-container">
                <Text
                  color={"var(--text-body-warning)"}
                  textAlign={"center"}
                  variant="r200"
                >
                  {"This email will be sent by TwoTixx on behalf of "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                  >
                    {userInfo?.fullName}
                  </span>
                  {
                    " to all ticket holders. Please make sure you have followed our T&Cs regarding communications before sending this message."
                  }
                </Text>
              </div>

              <div
                style={{
                  backgroundColor: isEmailComposeAgreed
                    ? "var(--surfaceSuccessLight)"
                    : "var(--surface-secondary)",
                }}
                className="compose-email-attendee-agree-terms-container"
              >
                <div
                  onClick={() => setIsEmailComposeAgreed(!isEmailComposeAgreed)}
                  style={{ padding: "5px 2px", cursor: "pointer" }}
                >
                  {isEmailComposeAgreed ? (
                    <SuccessFilledWhite width={24} height={24} />
                  ) : (
                    <CheckBoxUncheckedCircleBlue width={24} height={24} />
                  )}
                </div>

                <Text variant="m200" color={"var(--newTextBody)"}>
                  I confirm that I have read and understood the TwoTixx{" "}
                  <Text
                    cursor={"pointer"}
                    color={"var(--midBlue)"}
                    underline={true}
                    variant="m200"
                    style={{ display: "inline" }}
                  >
                    Terms and Conditions.
                  </Text>{" "}
                </Text>
              </div>

              <div className="compose-email-attendee-confirm-button-container">
                <ButtonPrimary
                  type={"error"}
                  isSecondary={true}
                  label={"Cancel"}
                  width={360}
                  onPress={() => setIsComposeEmailActive(false)}
                />
                <ButtonPrimary
                  disabled={
                    emailComposeSubject?.trim()?.length === 0 ||
                    emailComposeMessage?.trim()?.length === 0 ||
                    !isEmailComposeAgreed
                  }
                  label={"Send email"}
                  width={360}
                  onPress={() => sendEventOrganiserEmailRequest()}
                />
              </div>
            </div>
          </div>
        ) : attendeeEmailList.length === 0 ? (
          <div className="discount-empty-container-event-details create-discount-code-top-div">
            <div className="generic-common-row-div-top">
              <EmailAttendee />
              <div
                style={{ flex: 1, marginLeft: "16px" }}
                className="generic-common-column-div"
              >
                <Text marginBottom={8} variant={"b400"}>
                  {"Email attendees"}
                </Text>

                <Text variant={"r200"}>
                  {"Send an email directly to all your event attendees."}
                </Text>
              </div>
            </div>

            <ButtonPrimary
              isSecondary={true}
              label={"Compose new email"}
              width={360}
              onPress={() => {
                setIsComposeEmailActive(true);
              }}
            />
          </div>
        ) : (
          <div
            style={{ padding: "24px" }}
            className="add-on-empty-container-event-details"
          >
            <div
              style={{ width: "100%" }}
              className="create-discount-code-top-div generic-common-row-div-space-between"
            >
              <div className="generic-common-row-div-top">
                <EmailAttendee />
                <div
                  style={{ flex: 1, marginLeft: "16px" }}
                  className="generic-common-column-div"
                >
                  <Text marginBottom={8} variant={"b400"}>
                    {"Email attendees"}
                  </Text>

                  <Text variant={"r200"}>
                    {
                      "Compose and send an email directly to all your event attendees."
                    }
                  </Text>
                </div>
              </div>

              <ButtonPrimary
                isSecondary={true}
                label={"Compose new email"}
                width={360}
                onPress={() => {
                  setIsComposeEmailActive(true);
                }}
              />
            </div>

            <div
              style={{ marginTop: "32px" }}
              className="generic-common-divider"
            />

            <Text marginTop={24} variant={"b300"}>
              {`Email history (${attendeeEmailList.length})`}
            </Text>

            {attendeeEmailList.map((items, index) => {
              return (
                <div className="attendee-email-history-box-container">
                  <div className="attendee-email-box-container">
                    <div
                      style={{ width: "100%" }}
                      className="generic-common-row-div-space-between"
                    >
                      <Text marginLeft={3} variant={"b300"}>
                        {items?.subject}
                      </Text>
                    </div>

                    <div
                      style={{ marginTop: 16 }}
                      className="generic-common-row-div-top"
                    >
                      <Text
                        newLineData={
                          items.content?.split("\n")
                            ? items.content?.split("\n")
                            : false
                        }
                        variant="r200"
                        style={{ display: "inline" }}
                      >
                        {`${items?.content}`}
                      </Text>
                    </div>
                  </div>
                  <div className="attendee-email-status-box-container">
                    <div className="generic-common-row-div">
                      <CalendarSmall />
                      <Text marginLeft={8} variant={"label"}>
                        {`Sent on ${getFormattedDateForEmailAttendee(
                          items?.dateCreated
                        )}`}
                      </Text>
                    </div>

                    <div
                      style={{ marginTop: "20px" }}
                      className="generic-common-row-div"
                    >
                      <SocialSmall />
                      <Text marginLeft={8} variant={"label"}>
                        {`Sent to ${items.numberRecipients || 0} people`}
                      </Text>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderCropEventBanner = () => {
    return (
      <div
        className="crop-event-banner-container"
        style={{
          margin: "40px",
          width: "700px",
          height: "393.75",
          alignSelf: "center",
        }}
      >
        <Cropper
          // image="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
          image={toCropImage}
          crop={crop}
          zoom={zoom}
          // aspect={16 / 9}
          aspect={isCropModalVisible === "posterCrop" ? 16 / 9 : 1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          ref={imageRef}
        />

        <input
          style={{ width: "100%", marginTop: 24 }}
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value);
          }}
          className="zoom-range"
        />

        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
            marginBottom: 0,
            justifyContent: "flex-end",
          }}
        >
          <div className="generic-common-row-div">
            <ButtonPrimary
              label={"Use original"}
              onPress={() => {
                setIsCropModalVisible(false);
                if (isCropModalVisible === "posterCrop") {
                  setBannerImage(toCropImage);
                } else {
                  let tempArray = [...ticketAddOnList];
                  tempArray[selectedAddOnIndex].addOnImage = toCropImage;
                  setTicketAddOnList(tempArray);
                }
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              label={"Save"}
              onPress={() => {
                showCroppedImage();
                setIsCropModalVisible(false);
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              type={"error"}
              isSecondary={true}
              label={"Cancel"}
              onPress={() => setIsCropModalVisible(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCustomeCategory = () => {
    return <div></div>;
  };

  const renderCancelEventConfirmation = () => {
    return (
      <div
        style={{
          paddingTop:
            isGuestModalVisible === "deleteGuest" ||
            isGuestModalVisible === "duplicateEvent"
              ? 0
              : 40,
          paddingBottom:
            isGuestModalVisible === "pauseResumeTicketSales" ||
            isGuestModalVisible === "publishEventKycNotVerified" ||
            isGuestModalVisible === "deleteGuest" ||
            isGuestModalVisible === "duplicateEvent"
              ? 0
              : 40,
        }}
      >
        <Text style={{ whiteSpace: "pre-line" }} variant={"r300"}>
          {isGuestModalVisible === "pauseResumeTicketSales"
            ? ticketSellingEnabled === true
              ? "Are you sure you want to pause ticket sales for this event? This means you will not be able to sell any tickets until you resume ticket selling again."
              : "Are you sure you want to resume ticket sales for this event? This means you will enable ticket selling to the public."
            : isGuestModalVisible === "publishEventKycNotVerified"
            ? "To publish this event, please complete the identity verification process first."
            : eventType === "draft1"
            ? "Are you sure you would like to cancel this event? Any data or information related to this event will be lost and cannot be undone."
            : isGuestModalVisible === "deleteGuest"
            ? "Are you sure you want to remove the selected ticket(s) from your guest list? The current holder(s) will lose access, and this action cannot be undone."
            : isGuestModalVisible === "duplicateEvent"
            ? "Are you sure you want to duplicate this event? All live event data will be reset, but the event setup details will be preserved."
            : "Due to the fact that tickets have already started selling for this event, we need you to contact us so we can proceed with next steps. \n\nIf you would like to cancel your event, please email us using the link below."}
        </Text>

        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            marginBottom: 0,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={
              isGuestModalVisible === "pauseResumeTicketSales" ||
              isGuestModalVisible === "deleteGuest" ||
              isGuestModalVisible === "duplicateEvent"
                ? "No, cancel"
                : eventType === "draft1"
                ? "No, cancel"
                : "Cancel"
            }
            onPress={() => {
              setIsGuestModalVisible(false);
            }}
          />
          <ButtonPrimary
            label={
              isGuestModalVisible === "pauseResumeTicketSales" ||
              isGuestModalVisible === "deleteGuest"
                ? "Yes, confirm"
                : isGuestModalVisible === "publishEventKycNotVerified"
                ? "Verify now"
                : eventType === "draft1" ||
                  isGuestModalVisible === "duplicateEvent"
                ? "Yes, confirm"
                : "Contact us"
            }
            onPress={() => {
              isGuestModalVisible === "pauseResumeTicketSales"
                ? updateEventTicketSellingFlagRequest()
                : isGuestModalVisible === "publishEventKycNotVerified"
                ? verifyAccountRequest()
                : isGuestModalVisible === "duplicateEvent"
                ? duplicateEventRequest()
                : eventType === "draft1"
                ? deleteEventRequest(true)
                : isGuestModalVisible === "deleteGuest"
                ? removeGuestListRequest(selectedGuestIndexToSendOrRevokeTicket)
                : sendContactUsEmail();

              setIsGuestModalVisible(false);
            }}
            style={{ marginLeft: 32 }}
          />
        </div>
      </div>
    );
  };

  const containsAllObjects = (arr1, arr2) =>
    arr1.every((item1) => arr2.some((item2) => item2.name === item1.name));

  // const containsAllTicketsAndAddOns = array2.some(item => item.name === "All tickets and add ons");
  // const containsBothAllTicketsAndAllAddOns = array2.some(item => item.name === "All tickets") && array2.some(item => item.name === "All add ons");
  // const containsAllAddOnCategoryList = containsAllObjects(addOnCategoryList, array2);
  // const containsAllTicketAddOnList = containsAllObjects(ticketAddOnList, array2);

  const renderDiscountCode = () => {
    return (
      <div className="generic-common-column-div">
        <div className="discount-code-empty-container">
          <Text marginBottom={24} variant={"m400"}>
            Details*
          </Text>

          <InputField
            withOutIcon={true}
            inputValue={discountCodeName}
            placeholder={"Enter discount code name"}
            secureTextEntry={false}
            editable={true}
            type={"general"}
            onChangeValue={(text) => setDiscountCodeName(text)}
            onBlur={() => {}}
            descriptive={"Customers must enter this code at checkout."}
          />
        </div>

        <div className="discount-code-empty-container">
          <Text marginBottom={24} variant={"m400"}>
            Discount value*
          </Text>

          <div
            style={{ marginTop: 0, marginBottom: "4px" }}
            className="event-details-event-scroll-container"
          >
            <div className="event-detailsTop-btns-inner">
              {discountOptions.map((items, ind) => {
                return (
                  <div key={ind} style={{ marginRight: 12 }}>
                    <Tags
                      disabled={false}
                      paddingVertical={8}
                      label={items.name}
                      selected={ind == discountAmountType ? true : false}
                      onChangeSelection={() => {
                        setDiscountAmountType(ind);
                        setDiscountAmount("");
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <InputField
            isNumeric={true}
            maxLength={discountAmountType === 0 ? 3 : 10}
            verifyNotNeed={true}
            withOutIcon={true}
            inputValue={discountAmount}
            placeholder={
              discountAmountType === 0 ? "Enter percentage" : "Enter amount"
            }
            secureTextEntry={false}
            editable={true}
            type={"general"}
            onChangeValue={(text) => {
              // Convert the text input to a number
              const value = parseFloat(text);

              // Check if the value is greater than 100
              if (discountAmountType === 0 && value > 100) {
                setDiscountAmount("100");
              } else {
                setDiscountAmount(text);
              }
            }}
            endSymbol={discountAmountType === 0 ? "%" : ""}
            onBlur={() => {}}
          />
        </div>

        <div className="discount-code-empty-container">
          <Text marginBottom={24} variant={"m400"}>
            Usage restriction*
          </Text>

          <div style={{ width: "100%" }}>
            <Text variant="m300">
              Which tickets are eligible to use this discount code?
            </Text>

            {discountEligibility?.map((item, index) => {
              return (
                <div style={{ width: "100%" }} key={index}>
                  <DropdownInput
                    style={{ marginTop: "10px" }}
                    eventCategory={
                      // item?.name === "AllTicketsAddons"
                      //   ? "All tickets and add ons"
                      //   : item?.name === "AllTickets"
                      //   ? "All tickets"
                      //   : item?.name === "AllAddons"
                      //   ? "All add ons"
                      //   :
                      item?.name
                    }
                    data={discountEligibilityOptions}
                    onSelectedItem={(value) => {
                      let tempArray = [...discountEligibility];
                      tempArray[index].name = value;
                      tempArray[index].id = ticketList.find(
                        (item) => item.name === value
                      )
                        ? ticketList.find((item) => item.name === value)?.id
                        : ticketAddOnList.find((item) => item.name === value)
                        ? ticketAddOnList.find((item) => item.name === value)
                            ?.id
                        : -1;
                      setDiscountEligibility(tempArray);
                    }}
                    editable={true}
                  />
                </div>
              );
            })}
          </div>

          <ButtonPrimary
            style={{ alignSelf: "center", marginTop: "24px" }}
            isSecondary={true}
            label={"Add another ticket"}
            width={328}
            disabled={
              discountEligibility.some(
                (item) => item.name === "All tickets and add ons"
              ) ||
              discountEligibility.some((item) => item.name === "All tickets") ||
              discountEligibility.some((item) => item.name === "All add ons") ||
              discountEligibility.some(
                (item) => item.name === "Select an option"
              ) ||
              (containsAllObjects(addOnCategoryList, discountEligibility) &&
                containsAllObjects(ticketAddOnList, discountEligibility))
                ? true
                : false
            }
            onPress={() => {
              let tempArray = [...discountEligibility];
              tempArray.push({
                name: "Select an option",
                id: null,
              });
              setDiscountEligibility(tempArray);
            }}
          />
        </div>

        <div className="discount-code-empty-container">
          <Text marginBottom={24} variant={"m400"}>
            Maximum discount uses*
          </Text>

          <RadioButton
            twoColumn={true}
            marginRight={0}
            styles={{ marginBottom: 8 }}
            vertically={false}
            data={discountMaxUsesOptions}
            selectIndex={discountMaxUsesType}
            onSelect={(item, index) => {
              setDiscountMaxUsesType(index);
            }}
          />
          <div style={{ width: "100%", marginTop: "16px" }}>
            <Text variant="m300">
              How many times can this code be used in total?
            </Text>

            <InputField
              isNumeric={true}
              style={{ marginTop: "8px" }}
              withOutIcon={true}
              inputValue={discountMaxUses}
              placeholder={"Enter numbers"}
              secureTextEntry={false}
              editable={true}
              type={"general"}
              onChangeValue={(text) => setDiscountMaxUses(text)}
              onBlur={() => {}}
              descriptive={
                "Discount code will automatically deactivate once the usage limit is reached."
              }
            />
          </div>
        </div>

        <div className="discount-code-empty-container">
          <Text marginBottom={24} variant={"m400"}>
            Active dates
          </Text>
          <div
            style={{ width: "100%" }}
            className="generic-common-row-div-space-between"
          >
            <div
              className="generic-common-column-div-half-width"
              style={{ marginTop: "4px", paddingRight: "8px" }}
            >
              <Text marginBottom={8} variant="m300">
                Start date
              </Text>

              <DropdownInput
                resetRequired={true}
                showDropdown={isDatePickerVisibleDiscountStartDate}
                onToggleDropdown={() =>
                  setIsDatePickerVisibleDiscountStartDate(
                    !isDatePickerVisibleDiscountStartDate
                  )
                }
                eventCategory={discountStartDate}
                data={[]}
                editable={true}
                resetBtnClicked={() => {
                  setDiscountStartDate("DD/MM/YYYY");
                }}
                customRender={() => {
                  return (
                    <div style={{ marginBottom: -10 }}>
                      <DatePicker
                        shouldCloseOnSelect={true}
                        onChange={(update) => {
                          setDiscountStartDate(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          setIsDatePickerVisibleDiscountStartDate(
                            !isDatePickerVisibleDiscountStartDate
                          );
                        }}
                        startDate={new Date()}
                        inline
                        minDate={
                          // new Date(
                          //   startDate.split("/")[2],
                          //   startDate.split("/")[1] - 1,
                          //   startDate.split("/")[0]
                          // ) || null
                          new Date()
                        }
                        maxDate={null}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div
              className="generic-common-column-div-half-width"
              style={{ marginTop: "4px", paddingLeft: "8px" }}
            >
              <Text variant="m300" marginBottom={8}>
                Start time
              </Text>
              <DropdownInput
                eventCategory={discountStartTime}
                data={
                  discountStartDate === formatDate(new Date())
                    ? generateCustomTimeArray(startTime)
                    : generateTimeArray()
                }
                onSelectedItem={(value) => {
                  setDiscountStartTime(value);
                }}
                editable={discountStartDate === "DD/MM/YYYY" ? false : true}
              />
            </div>
          </div>

          <div
            style={{ width: "100%", marginTop: "28px" }}
            className="generic-common-row-div-space-between"
          >
            <div
              className="generic-common-column-div-half-width"
              style={{ marginTop: "4px", paddingRight: "8px" }}
            >
              <Text marginBottom={8} variant="m300">
                End date
              </Text>

              <DropdownInput
                resetRequired={true}
                showDropdown={isDatePickerVisibleDiscountEndDate}
                onToggleDropdown={() =>
                  setIsDatePickerVisibleDiscountEndDate(
                    !isDatePickerVisibleDiscountEndDate
                  )
                }
                eventCategory={discountEndDate}
                data={[]}
                editable={
                  discountStartDate === "DD/MM/YYYY" ||
                  discountStartTime === "00:00 "
                    ? false
                    : true
                }
                resetBtnClicked={() => {
                  setDiscountEndDate("DD/MM/YYYY");
                }}
                customRender={() => {
                  return (
                    <div style={{ marginBottom: -10 }}>
                      <DatePicker
                        shouldCloseOnSelect={true}
                        onChange={(update) => {
                          setDiscountEndDate(
                            new Date(update).toLocaleDateString("en-GB")
                          );
                          setIsDatePickerVisibleDiscountEndDate(
                            !isDatePickerVisibleDiscountEndDate
                          );
                        }}
                        minDate={
                          new Date(
                            discountStartDate.split("/")[2],
                            discountStartDate.split("/")[1] - 1,
                            discountStartDate.split("/")[0]
                          ) && new Date()
                        }
                        inline
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div
              className="generic-common-column-div-half-width"
              style={{ marginTop: "4px", paddingLeft: "8px" }}
            >
              <Text variant="m300" marginBottom={8}>
                End time
              </Text>
              <DropdownInput
                eventCategory={discountEndTime}
                data={
                  discountStartDate === discountEndDate
                    ? generateCustomTimeArray(discountStartTime)
                    : generateTimeArray()
                }
                onSelectedItem={(value) => {
                  setDiscountEndTime(value);
                }}
                editable={discountEndDate === "DD/MM/YYYY" ? false : true}
              />
            </div>
          </div>
        </div>

        <div
          className="discount-bottom-discard-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "8px",
            width: "100%",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            disabled={false}
            label={"Discard"}
            onPress={() => {
              if (
                discountAmount !== "" ||
                discountCodeName?.trim() !== "" ||
                !discountEligibility.some(
                  (item) => item.name === "Select an option"
                ) ||
                discountMaxUsesType !== null ||
                discountMaxUses !== ""
              ) {
                setIsDeleteModalVisible("discardDiscountCode");
                setIsGuestModalVisible(false);
              } else {
                initializeDiscountValue();
                setIsGuestModalVisible(false);
              }
            }}
          />

          <ButtonPrimary
            disabled={
              discountAmount === "" ||
              discountCodeName?.trim() === "" ||
              discountEligibility.some(
                (item) => item.name === "Select an option"
              ) ||
              discountMaxUsesType === null ||
              discountMaxUses === ""
                ? true
                : false
            }
            label={"Create discount code"}
            onPress={() => {
              setIsGuestModalVisible(false);
              createEventDiscountCodeRequest(
                selectedDiscountIndex !== null ? "edit" : "create"
              );
            }}
            style={{ marginLeft: 32 }}
          />
        </div>
      </div>
    );
  };

  const sendContactUsEmail = () => {
    var recipient = "help@twotixx.com";
    var subject = "Event cancellation request";

    var mailtoLink =
      "mailto:" + recipient + "?subject=" + encodeURIComponent(subject);

    window.location.href = mailtoLink;
  };

  const renderGuestsConfirmation = () => {
    return (
      <div>
        <div
          style={{ marginTop: 32, marginBottom: 40 }}
          className="generic-common-divider"
        />
        <Text variant={"r400"}>
          Below is a summary of the guest details for the selected ticket:
        </Text>
        <div
          style={{ marginTop: 40 }}
          className="generic-common-row-div-space-between"
        >
          <div style={{ width: "33%" }} className="generic-common-column-div">
            <Text color={"var(--midBlue)"} variant={"m300"}>
              TICKET TYPE
            </Text>
            <Text marginTop={12} variant={"m400"}>
              {guestList[selectedGuestIndex]?.ticketCategory}
            </Text>
          </div>

          <div style={{ width: "33%" }} className="generic-common-column-div">
            <Text color={"var(--midBlue)"} variant={"m300"}>
              QUANTITY
            </Text>
            <Text marginTop={12} variant={"m400"}>
              {guestList[selectedGuestIndex]?.quantity}
            </Text>
          </div>

          <div style={{ width: "34%" }} className="generic-common-column-div">
            <Text color={"var(--midBlue)"} variant={"m300"}>
              EMAIL ADDRESS
            </Text>
            <Text marginTop={12} variant={"m400"}>
              {guestList[selectedGuestIndex]?.email}
            </Text>
          </div>
        </div>
        <div
          onClick={() => {
            setConfirmTicketToGuest(!confirmTicketToGuest);
          }}
          style={{
            height: 36,
            marginTop: 40,
            marginLeft: -8,
            padding: "0 8px",
          }}
          className="generic-common-row-div central-modal-contentPopup-check"
        >
          <div
            style={{
              width: 38,
              height: 38,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {confirmTicketToGuest ? (
              <CheckBoxCheckedCircle width={24} height={24} />
            ) : (
              <CheckBoxUncheckCircle width={24} height={24} />
            )}
          </div>
          <Text marginTop={-2} marginLeft={4} variant={"r200"}>
            {isGuestModalVisible === "sendTicket"
              ? `Ticking this box indicates that you agree to the above details and wish to proceed with allocating the ticket. This quantity will be deducted from the total number of tickets available.`
              : `Ticking this box indicates that you agree to the above details and wish to proceed with revoking the ticket.`}
          </Text>
        </div>
        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
            marginBottom: 0,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => {
              setConfirmTicketToGuest(false);
              setIsGuestModalVisible(false);
            }}
          />
          <ButtonPrimary
            disabled={!confirmTicketToGuest}
            label={
              isGuestModalVisible === "sendTicket"
                ? "Send ticket"
                : "Revoke ticket"
            }
            onPress={() => {
              setConfirmTicketToGuest(false);
              setIsGuestModalVisible(false);
              isGuestModalVisible === "sendTicket"
                ? addGuestListRequest(
                    selectedGuestIndexToSendOrRevokeTicket,
                    guestList[selectedGuestIndexToSendOrRevokeTicket].email,
                    guestList[selectedGuestIndexToSendOrRevokeTicket]
                      .ticketCategory
                  )
                : removeGuestListRequest(
                    selectedGuestIndexToSendOrRevokeTicket
                  );
            }}
            style={{ marginLeft: 32 }}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    isDeleteModalVisible === "ticketAddOn" && updateEventRequest("unchanged");
    setIsDeleteModalVisible(false);
  }, [ticketAddOnList]);

  const replaceWhiteSpaceWithHyphen = (str) => {
    return str.replace(/\s+/g, "-");
  };

  return (
    <div className="page-wrapper-event-details">
      {isCopiedURLVisible ? (
        <InfoTags
          text={"Share link copied"}
          icon={<Copied />}
          onChangeVisibility={(value) => setIsCopiedURLVisible(value)}
        />
      ) : null}
      <CentralCustomModal
        title={
          isCropModalVisible === "posterCrop"
            ? "Resize Poster"
            : "Resize Add On Image"
        }
        show={
          isCropModalVisible === "posterCrop" ||
          isCropModalVisible === "addOnImageCrop"
        }
        onClose={() => setIsCropModalVisible(false)}
        showDividerTop={false}
        renderContent={() => renderCropEventBanner()}
        isDynamicSize={false}
      />

      <CentralCustomModal
        title={
          isGuestModalVisible === "sendTicket"
            ? "Send confirmation"
            : isGuestModalVisible === "duplicateEvent"
            ? "Duplicate event?"
            : isGuestModalVisible === "cancelEvent"
            ? "Cancel event"
            : isGuestModalVisible === "pauseResumeTicketSales"
            ? ticketSellingEnabled
              ? "Pause ticket sales"
              : "Resume ticket sales"
            : isGuestModalVisible === "publishEventKycNotVerified"
            ? "Publish event"
            : isGuestModalVisible === "createDiscountCode"
            ? "Create discount code"
            : isGuestModalVisible === "deleteGuest"
            ? "Remove ticket(s)"
            : "Revoke confirmation"
        }
        show={
          isGuestModalVisible === "sendTicket" ||
          isGuestModalVisible === "revokeTicket" ||
          isGuestModalVisible === "cancelEvent" ||
          isGuestModalVisible === "pauseResumeTicketSales" ||
          isGuestModalVisible === "publishEventKycNotVerified" ||
          isGuestModalVisible === "createDiscountCode" ||
          isGuestModalVisible === "deleteGuest" ||
          isGuestModalVisible === "duplicateEvent"
        }
        onClose={() => setIsGuestModalVisible(false)}
        showDividerTop={
          isGuestModalVisible === "createDiscountCode" ||
          isGuestModalVisible === "deleteGuest" ||
          isGuestModalVisible === "duplicateEvent"
            ? true
            : false
        }
        renderContent={() =>
          isGuestModalVisible === "cancelEvent" ||
          isGuestModalVisible === "pauseResumeTicketSales" ||
          isGuestModalVisible === "publishEventKycNotVerified" ||
          isGuestModalVisible === "deleteGuest" ||
          isGuestModalVisible === "duplicateEvent"
            ? renderCancelEventConfirmation()
            : isGuestModalVisible === "createDiscountCode"
            ? renderDiscountCode()
            : renderGuestsConfirmation()
        }
        isDynamicSize={isGuestModalVisible === "cancelEvent" ? true : false}
      />
      <CentralModalWithInput
        title={"Create category"}
        inputTitle={"Category name"}
        inputPlaceholder={"Enter category name"}
        inputValue={
          ticketList[selectedTicketIndex]?.category === "-" ||
          ticketList[selectedTicketIndex]?.category === "Select an option"
            ? ""
            : ticketList[selectedTicketIndex]?.category
        }
        onChangeInputValue={(value) => setNewTicketCategoryValue(value)}
        show={isCategoryModalVisible}
        onClose={() => setIsCategoryModalVisible(false)}
        renderContent={() => renderCustomeCategory()}
        shouldShowButtons={true}
        button1Title={"Cancel"}
        button2Title={"Create category"}
        onButton1Clicked={() => setIsCategoryModalVisible(false)}
        onButton2Clicked={() => {
          setIsCategoryModalVisible(false);
          let tempArray = [...ticketList];
          const tempTicketCategoryArray = [...ticketCategoryList];
          tempTicketCategoryArray.push({
            name: newTicketCategoryValue,
            id: 0,
          });
          const uniqueTempTicketCategoryArray = tempTicketCategoryArray.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.name === item.name)
          );
          tempArray[selectedTicketIndex].category = newTicketCategoryValue;
          tempArray[selectedTicketIndex].categoryId = tempArray[
            selectedTicketIndex
          ]?.categoryId
            ? -2
            : 0;

          setTicketList(tempArray);

          const filteredList = uniqueTempTicketCategoryArray.filter(
            (category) => category.name !== "+ Create category"
          );

          setTicketCategoryList([
            ...filteredList,
            { name: "+ Create category" },
          ]);
        }}
      />
      <CentralModalSuccess
        type={
          isDeleteModalVisible === "discardDiscountCode"
            ? "perosnalDetails"
            : "null"
        }
        icon={
          isDeleteModalVisible === "discountCode" ||
          isDeleteModalVisible === "discardDiscountCode" ||
          isDeleteModalVisible ===
            "disconnectMailChimp" ? null : isDeleteModalVisible === "draft" ? (
            <EditFilled height={24} width={24} />
          ) : String(isDeleteModalVisible)?.includes("addBolt") ? (
            <AddFilled height={24} width={24} />
          ) : (
            <BinFilled height={24} width={24} />
          )
        }
        title={
          isDeleteModalVisible === "entrance"
            ? "Remove entrance?"
            : isDeleteModalVisible === "draft"
            ? "Save your draft?"
            : String(isDeleteModalVisible)?.includes("addBolt")
            ? "Add Bolt On"
            : isDeleteModalVisible === "ticketAddOn"
            ? "Remove Add On?"
            : isDeleteModalVisible === "stageRelease"
            ? "Remove staged release?"
            : isDeleteModalVisible === "discountCode"
            ? "Remove discount code?"
            : isDeleteModalVisible === "discardDiscountCode"
            ? "Discard discount code?"
            : isDeleteModalVisible === "disconnectMailChimp"
            ? "Disconnect Mailchimp account"
            : "Remove ticket?"
        }
        description={
          isDeleteModalVisible === "entrance"
            ? "Are you sure you want to remove this entrance from your event?"
            : isDeleteModalVisible === "draft"
            ? "Are you sure you want to save this event as a draft? You will need to fill in all required details to publish this event."
            : String(isDeleteModalVisible)?.includes("addBolt")
            ? "Select an option below"
            : isDeleteModalVisible === "ticketAddOn"
            ? "Are you sure you want to remove this add on from your event?"
            : isDeleteModalVisible === "stageRelease"
            ? "Are you sure you want to remove this staged release from your ticket?"
            : isDeleteModalVisible === "discountCode"
            ? "Are you sure you want to remove this discount code?  You will not be able to recover this discount code."
            : isDeleteModalVisible === "discardDiscountCode"
            ? "Are you sure you want to discard this discount code? Any unsaved changes will be lost."
            : isDeleteModalVisible === "disconnectMailChimp"
            ? `Are you sure you want to disconnect your Mailchimp account? You won’t be able to access any linked communication features within Twotixx Box Office.`
            : "Are you sure you want to remove this ticket from your event?"
        }
        shouldShowButtons={true}
        button1Title={
          isDeleteModalVisible === "entrance"
            ? "Yes, remove entrance"
            : isDeleteModalVisible === "draft"
            ? "Save draft"
            : String(isDeleteModalVisible)?.includes("addBolt")
            ? "Confirm Bolt On"
            : isDeleteModalVisible === "ticketAddOn"
            ? "Yes, remove add on"
            : isDeleteModalVisible === "disconnectMailChimp"
            ? "Yes, confirm"
            : "Yes, remove"
        }
        button2Title={
          isDeleteModalVisible === "draft" ||
          String(isDeleteModalVisible)?.includes("addBolt")
            ? "Cancel"
            : "No, cancel"
        }
        onButton1Clicked={() => {
          if (isDeleteModalVisible === "ticket") {
            const newArray = [
              ...ticketList.slice(0, selectedTicketIndex),
              ...ticketList.slice(selectedTicketIndex + 1),
            ];
            setTicketList(newArray);
            setIsTicketOrAddOnDeleted("tickets");
          } else if (isDeleteModalVisible === "stageRelease") {
            const newData = ticketList.map((item, index) => {
              if (index === selectedTicketIndex) {
                return {
                  ...item,
                  stageReleases: item.stageReleases.filter(
                    (innerItem, innerIndex) =>
                      innerIndex !== selectedStageReleaseIndex
                  ),
                };
              } else {
                // If the index doesn't match, keep the item as it is
                return item;
              }
            });
            setTicketList(newData);
          } else if (String(isDeleteModalVisible)?.includes("addBolt")) {
            updateEventRequest();
          } else if (isDeleteModalVisible === "ticketAddOn") {
            const newArray = [
              ...ticketAddOnList.slice(0, selectedAddOnIndex),
              ...ticketAddOnList.slice(selectedAddOnIndex + 1),
            ];
            setTicketAddOnList(newArray);
            setIsTicketOrAddOnDeleted("addOn");
          } else if (isDeleteModalVisible === "discountCode") {
            deleteEventDiscountCodeRequest();
          } else if (isDeleteModalVisible === "disconnectMailChimp") {
            disconnectMailChimpAccountRequest();
          } else {
            //savingForDraft();
          }
          setIsDeleteModalVisible(false);
        }}
        onButton2Clicked={() => {
          if (isDeleteModalVisible === "discardDiscountCode") {
            setIsGuestModalVisible("createDiscountCode");
          }
          setIsDeleteModalVisible(false);
        }}
        show={
          isDeleteModalVisible === "entrance" ||
          isDeleteModalVisible === "ticket" ||
          isDeleteModalVisible === "stageRelease" ||
          isDeleteModalVisible === "draft" ||
          isDeleteModalVisible === "ticketAddOn" ||
          isDeleteModalVisible === "discountCode" ||
          isDeleteModalVisible === "discardDiscountCode" ||
          isDeleteModalVisible === "disconnectMailChimp" ||
          String(isDeleteModalVisible)?.includes("addBolt")
            ? true
            : false
        }
        isDisabledButton1={
          String(isDeleteModalVisible)?.includes("addBolt") &&
          ticketList[
            parseInt(
              String(isDeleteModalVisible)?.substring(
                String(isDeleteModalVisible)?.indexOf("addBolt") + 7
              )
            )
          ]?.isAddOn === null
            ? true
            : false
        }
        onClose={() => setIsDeleteModalVisible(false)}
      />
      <CentralModalSuccessV2
        icon={<SuccessFilledGreen />}
        backgroundHoverBtn={"var(--greenSuccess)"}
        title={"Your Event is Ready to Publish!"}
        description={
          "Congratulations! Your account is set up, and you’re now ready to publish your event."
        }
        shouldShowButtons={true}
        button1Title={"Publish event"}
        button2Title={"Back to event"}
        onButton1Clicked={() => updateEventRequest("publish")}
        onButton2Clicked={() => setIsReadyToPublishModalVisible(false)}
        show={isReadyToPublishModalVisible === true}
      />
      <div className="header-event-details">
        <div className="main-body-content">
          <div className="home-header-sideNav">
            <SideBar
              key={sideBarReloadKey}
              onChangeOption={(index) => {
                navigate("/Home", {
                  state: { selectedIndex: index },
                });
              }}
              createEventClicked={() => navigate("/CreateEvent")}
              onGoToEventClicked={(eventId, item) => {
                console.log("item&eventId", item, eventId);
                navigate(
                  `/EventDetails/${
                    item?.draftEventId ? item.draftEventId : eventId
                  }`,
                  {
                    state: {
                      eventId: eventId,
                      nonActiveEventDetails: item,
                    },
                  }
                );
                setNonActiveEventDetails(item);
                setSelectedTicketType("All sales");
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }, 400);
              }}
            />
          </div>
          <div className="rht-body-inner-content">
            {eventType === "draft1" ||
            (item?.isApproved ? !item.isApproved : null) ? (
              <div
                style={{
                  backgroundColor:
                    eventType === "draft1" ? "#fef3dd" : "#F0F6FE",
                }}
                className="draft-banner-wrapper"
              >
                <div className="draft-banner-container">
                  {eventType === "draft1" ? (
                    <EditFilled width={24} height={24} fill="#D97126" />
                  ) : (
                    <ClockFilled width={24} height={24} />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: 16,
                    }}
                  >
                    <Text
                      textWrap={"wrap"}
                      color={eventType === "draft1" ? "#D97126" : "#3A7DE9"}
                      marginTop={4}
                      variant={"r200"}
                    >
                      {eventType === "draft1" &&
                      isStripeDetailsSubmitted !== true &&
                      userInfo?.kycStatus !== "Verified"
                        ? "This event is saved as a draft. Please complete your account setup before publishing."
                        : eventType === "draft1" &&
                          isStripeDetailsSubmitted === true &&
                          userInfo?.kycStatus === "Verified"
                        ? "This event is saved as a draft. To publish it, simply click 'Publish' below."
                        : "You need to complete your account setup to be able to start selling tickets for the event."}
                    </Text>
                  </div>
                  {/* </div>
                  ))} */}
                </div>
              </div>
            ) : null}
            <div className="event-details-content-div">
              <MenuLink
                showAnyButton={true}
                btn1Label={
                  getTimeDiffStatus(item?.startDate, item?.endDate) !==
                    "Expired" && item?.isPublished === true
                    ? "Share"
                    : false
                }
                btn1LabelClicked={() => {
                  setIsCopiedURLVisible(true);
                  // const currentUrl = window.location.href;
                  const currentUrl = `${
                    process.env.REACT_APP_WEBSITE_URL
                  }/event-details/${replaceWhiteSpaceWithHyphen(
                    item.name
                  )}-ticket-${item?.id}`;
                  navigator.clipboard.writeText(currentUrl);
                }}
                btn4LabelClicked={() =>
                  setIsGuestModalVisible("duplicateEvent")
                }
                btn3Label={
                  item?.isPublished !== true &&
                  isStripeDetailsSubmitted === true &&
                  userInfo?.kycStatus === "Verified" &&
                  checkForActiveDraft() === true
                    ? "Publish"
                    : getTimeDiffStatus(item?.startDate, item?.endDate) !==
                        "Expired" && item?.isPublished === true
                    ? "View"
                    : false
                }
                btn3LabelClicked={() => {
                  if (
                    item?.isPublished !== true &&
                    isStripeDetailsSubmitted === true &&
                    userInfo?.kycStatus === "Verified" &&
                    checkForActiveDraft() === true
                  ) {
                    updateEventRequest("publish");
                  } else {
                    window.open(
                      `${
                        process.env.REACT_APP_WEBSITE_URL
                      }/event-details/${replaceWhiteSpaceWithHyphen(
                        item.name
                      )}-ticket-${item?.id}`,
                      "_blank"
                    );
                  }
                }}
                btn2Label={getTimeDiffStatus(item?.startDate, item?.endDate) ===
                    "Expired" ? false : "Cancel"}
                btn2LabelClicked={() => setIsGuestModalVisible("cancelEvent")} // deleteEventRequest() navigate("/BusinessSignUp")
                style={{ marginLeft: 0 }}
                labelArray={[
                  { label: "Home", id: 1 },
                  { label: item?.name, id: 2 },
                ]}
                withCog={false}
                onMenuLinkPress={(ind) => ind === 0 && navigate("/Home")}
              />
              <div
                style={{ paddingTop: 24 }}
                className="event-details-top-after-menulink-container"
              >
                <div className="event-details-tittle">
                  <Text variant={"m600"}>{item?.name}</Text>
                </div>

                <div className="event-details-event-scroll-container">
                  <div className="event-detailsTop-btns-inner">
                    {eventOptions.map((items, ind) => {
                      return (
                        <div key={ind} style={{ marginRight: 12 }}>
                          <Tags
                            disabled={false}
                            paddingVertical={8}
                            label={items.name}
                            selected={
                              ind == selectedEventOptionIndex ? true : false
                            }
                            onChangeSelection={() =>
                              segmentBtnPressedEvent(ind)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* {isStripeDetailsSubmitted !== "no" && */}
              {isStripeDetailsSubmitted !== "no" &&
              (isStripeDetailsSubmitted !== true ||
                userInfo?.kycStatus !== "Verified") ? (
                <div
                  style={{ marginLeft: 24, marginRight: 24, width: "auto" }}
                  className="account-verifications-complete-account-setup-container account-setup-requiredBlock"
                >
                  <div className="generic-common-column-div account-setup-required-lft">
                    <Text variant="b300">{`Account Setup Required`}</Text>
                    <Text marginTop={16} variant="r200">
                      {isStripeDetailsSubmitted !== true &&
                      userInfo?.kycStatus !== "Verified"
                        ? `You must verify your account and set up a payment gateway before you can publish your event.`
                        : userInfo?.kycStatus !== "Verified"
                        ? `You must verify your account before you can publish your event.`
                        : "You must set up a payment gateway before you can publish your event."}
                    </Text>
                  </div>

                  {isStripeDetailsSubmitted !== true &&
                  userInfo?.kycStatus !== "Verified" ? (
                    <div className="generic-common-row-div account-setup-required-rht">
                      <ButtonPrimary
                        width={198}
                        style={{ marginLeft: "24px" }}
                        label={"Verify your account"}
                        onPress={() => verifyAccountRequest()}
                      />
                      <ButtonPrimary
                        isSecondary={true}
                        width={244}
                        style={{ marginLeft: "16px" }}
                        label={"Set up payment gateway"}
                        onPress={() => stripeConnect()}
                      />
                    </div>
                  ) : (
                    <ButtonPrimary
                      width={userInfo?.kycStatus !== "Verified" ? 198 : 244}
                      style={{ marginLeft: "24px" }}
                      label={
                        userInfo?.kycStatus !== "Verified"
                          ? "Verify your account"
                          : "Set up payment gateway"
                      }
                      onPress={() =>
                        userInfo?.kycStatus !== "Verified"
                          ? verifyAccountRequest()
                          : stripeConnect()
                      }
                    />
                  )}
                </div>
              ) : null}
              {selectedEventOptionIndex === 0 && renderOverview()}
              {selectedEventOptionIndex === 1 && renderEventDetails()}
              {selectedEventOptionIndex === 2 && renderVenue()}
              {selectedEventOptionIndex === 3 && renderRequirement()}
              {selectedEventOptionIndex === 4 && renderFees()}
              {selectedEventOptionIndex === 5 && renderTicket()}
              {selectedEventOptionIndex === 6 && renderAddOn()}
              {selectedEventOptionIndex === 7 && renderGuests()}
              {selectedEventOptionIndex === 8 && renderDiscount()}
              {selectedEventOptionIndex === 9 && renderCommunications()}

              {(selectedEventOptionIndex === 5 ||
                selectedEventOptionIndex === 6 ||
                selectedEventOptionIndex === 7) && (
                <ButtonPrimary
                  isSecondary={true}
                  label={
                    selectedEventOptionIndex === 5
                      ? "Add ticket type"
                      : selectedEventOptionIndex === 6
                      ? "Create Add-on"
                      : "Add another ticket"
                  }
                  onPress={() =>
                    onAddNewItemClicked(
                      selectedEventOptionIndex === 5
                        ? "ticket"
                        : selectedEventOptionIndex === 6
                        ? "addOn"
                        : "guest"
                    )
                  }
                  width={328}
                  style={{
                    alignSelf: "center",
                    marginTop:
                      selectedEventOptionIndex === 5 && ticketList.length === 0
                        ? 32
                        : null,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default EventDetails;
