import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import { ReactComponent as BinFilled } from "../../assets/svgs/BinFilled.svg";
import { ReactComponent as EditFilled } from "../../assets/svgs/EditFilled.svg";
import { ReactComponent as AddFilled } from "../../assets/svgs/AddFilled.svg";
import Text from "../../Component/Common/Text";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import HeaderNew from "../../Component/Header/HeaderNew";
import EventDetails from "../../Component/CreateEvent/EventDetails/EventDetails";
import VenueAddress from "../../Component/CreateEvent/Venue/VenueAddress";
import SocialLink from "../../Component/BusinessSignUp/SocialLink";
import VenueMap from "../../Component/CreateEvent/Venue/VenueMap";
import TotalTickets from "../../Component/CreateEvent/Ticket/TotalTickets";
import Ticket from "../../Component/CreateEvent/Ticket/Ticket";
import TicketAddOn from "../../Component/CreateEvent/TicketAddOn/TicketAddOnNew";
import Timing from "../../Component/CreateEvent/EventDetails/Timing";
import KYC from "../../Component/CreateEvent/Requirement/KYC";
import RefundPolicy from "../../Component/CreateEvent/Requirement/RefundPolicy";
import Fees from "../../Component/CreateEvent/Fees/Fees";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import CentralModalSuccess from "../../Component/Common/CentralModals/CentralModalSuccess";
import CentralModalWithInput from "../../Component/Common/CentralModals/CentralModalWithInput";
import CentralCustomModal from "../../Component/Common/CentralModals/CentralCustomModal";
import RadioButton from "../../Component/Common/Controls/RadioButton";
import GetCroppedImage from "../../Component/Common/GetCroppedImage";
import {
  BANNER_CONTENT,
  EVENT_DATA,
  SHOW_DRAFT,
  SELECTED_EVENT_DATA,
  SHOW_ACTIVITY_INDICATOR,
} from "../../actions";
import "./CreateEvent.css";
import {
  createEvent,
  getEventList,
  getInactiveDraftEventList,
  venueUserInUser,
  getStripeAccountStatus,
  StripeAccountConnect,
  getUrlKycVerify,
  createEventWithInactiveDraft,
} from "../../services/api";

function CreateEvent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const categoryList = useSelector(
    (state) => state.eventCategoryData?.eventCategoryData
  );
  const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const countryList = useSelector((state) => state.countryData?.countryData);
  const userInfo = useSelector((state) => state.userData?.userData);
  const showDraft = useSelector(
    (state) => state.showDraftEvent?.showDraftEvent
  );
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventDescriptionApp, setEventDescriptionApp] = useState("");
  const [eventCurrency, setEventCurrency] = useState("Select an option");
  const [eventCurrencyId, setEventCurrencyId] = useState("");
  const [venueName, setVenueName] = useState("");
  // const [capacity, setCapacity] = useState(10000000);
  const [venueDescription, setVenueDescription] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");

  const [venueAddress, setVenueAddress] = useState(false);
  const [venueSearchAddress, setVenueSearchAddress] = useState("");
  // const [venueAddress, setVenueAddress] = useState({
  //   lat: 22.582,
  //   lng: 88.352,
  // });
  const [gateKeeperUserName, setGateKeeperUserName] = useState("");
  const [gateKeeperPassword, setGateKeeperPassword] = useState("");
  const [gateKeeperUserNameAssistiveText, setGateKeeperUserNameAssistiveText] =
    useState("");
  const [gateKeeperPasswordAssistiveText, setGateKeeperPasswordAssistiveText] =
    useState("");
  const [kycType, setKycType] = useState(1);
  const [isRefundAcceptable, setIsRefundAcceptable] = useState(null);
  const [minimumAge, setMinimumAge] = useState("e.g. 18");
  const [addFeesToTicketPrice, setAddFeesToTicketPrice] = useState(null);
  const [isAddBookingFees, setIsAddBookingFees] = useState(0);
  const [entranceList, setEntranceList] = useState([
    { name: "", isSaved: false },
  ]);
  const [isCustomModalVisible, setIsCustomModalVisible] = useState(false);
  const [totalTicketQuantity, setTotalTicketQuantity] = useState("");
  const [ticketList, setTicketList] = useState([
    {
      releaseType: "automatic",
      releaseDate: "DD/MM/YYYY",
      releaseTime: "-",
      releaseEndDate: "DD/MM/YYYY",
      releaseEndTime: "-",
      name: "",
      quantity: "",
      description: "",
      category: "Select an option",
      currency: eventCurrency === "Select an option" ? "-" : eventCurrency,
      price: "",
      selectedAgeIndex: null,
      minimumAge: "",
      maximumAge: "",
      stageReleases: [
        {
          stageOfferName: "",
          stageQuantity: "",
          stageReleaseDate: "DD/MM/YYYY",
          stageReleaseTime: "-",
          stageReleaseEndDate: "DD/MM/YYYY",
          stageReleaseEndTime: "-",
          stageCurrency: "-",
          stagePrice: "",
          stageCurrencyId: 0,
          showActivateNextRelease: false,
          activateNextRelease: null,
          autoTriggerNextRelease: true,
          toHaveAccessibleErrorMessageStageRelease: [],
        },
      ],
      isSaved: false,
      currencyId: 0,
      stageCurrencyId: 0,
      toHaveAccessibleErrorMessageTicket: [],
      isAddOn: null,
      addOnAvailabilities: [],
    },
  ]);

  const [ticketAddOnList, setTicketAddOnList] = useState([]);
  const [addOnCategoryList, setAddOnCategoryList] = useState([]);
  const [ticketCategoryList, setTicketCategoryList] = useState([
    { name: "General Admission" },
    { name: "VIP Admission" },
    { name: "Early Bird" },
    { name: "Full Weekend Admission" },
    { name: "Full Weekend Camping" },
  ]);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [bannerImage, setBannerImage] = useState("");
  const [bannerImageBase64, setBannerImageBase64] = useState("");
  const [eventCategory, setEventCategory] = useState(["Select an option"]);
  const [eventCategoryId, setEventCategoryId] = useState([]);
  const [startDate, setStartDate] = useState("DD/MM/YYYY");
  const [endDate, setEndDate] = useState("DD/MM/YYYY");
  const [startTime, setStartTime] = useState("00:00 ");
  const [endTime, setEndTime] = useState("00:00 ");
  const [selectedEventOptionIndex, setSelectedEventOptionIndex] = useState(0);
  const [selectedEntranceIndex, setSelectedEntranceIndex] = useState(0);
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0);
  const [selectedStageReleaseIndex, setSelectedStageReleaseIndex] = useState(0);
  const [selectedAddOnIndex, setSelectedAddOnIndex] = useState(0);
  const [newTicketCategoryValue, setNewTicketCategoryValue] = useState("");
  const [shouldShowPublishEventButton, setShouldShowPublishEventButton] =
    useState(false);
  const [
    toHaveAccessibleErrorMessageEventDetails,
    setToHaveAccessibleErrorMessageEventDetails,
  ] = useState([]);
  const [
    toHaveAccessibleErrorMessageTiming,
    setToHaveAccessibleErrorMessageTiming,
  ] = useState([]);
  const [
    toHaveAccessibleErrorMessageVenueDetails,
    setToHaveAccessibleErrorMessageVenueDetails,
  ] = useState([]);
  const [
    toHaveAccessibleErrorMessageVenueAddress,
    setToHaveAccessibleErrorMessageVenueAddress,
  ] = useState([]);
  const [
    toHaveAccessibleErrorMessageVenueMap,
    setToHaveAccessibleErrorMessageVenueMap,
  ] = useState([]);
  const [toHaveAccessibleErrorMessageKyc, setToHaveAccessibleErrorMessageKyc] =
    useState([]);
  const [
    toHaveAccessibleErrorMessageRefundPolicy,
    setToHaveAccessibleErrorMessageRefundPolicy,
  ] = useState([]);
  const [
    toHaveAccessibleErrorMessageFees,
    setToHaveAccessibleErrorMessageFees,
  ] = useState([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [navigationParamsEventDetails, setNavigationParamsEventDetails] =
    useState({});
  const [nonActiveEventDetails, setNonActiveEventDetails] = useState(false);
  const menuLinkOptions = [
    { label: "Home", id: 1 },
    { label: "Create Event", id: 2 },
  ];

  const [eventOptions, setEventOptions] = useState([
    { name: "Event details", id: 1, isCompleted: false },
    { name: "Venue", id: 2, isCompleted: false },
    { name: "Requirements", id: 5, isCompleted: false },
    { name: "Fees", id: 3, isCompleted: false },
    { name: "Tickets", id: 3, isCompleted: false },
    { name: "Add ons", id: 3, isCompleted: false },
  ]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [countryId, setCountryId] = useState("");

  const [bookingPercentValue, setBookingPercentValue] = useState(5);

  const [toCropImage, setToCropImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isStripeDetailsSubmitted, setIsStripeDetailsSubmitted] =
    useState("no");
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await GetCroppedImage(
        toCropImage,
        croppedAreaPixels
      );
      console.log("donee", { croppedImage });
      if(isCustomModalVisible === "posterCrop")
        {
         setBannerImage(croppedImage)
        }
        else
        {
         console.log("selectedAddOnIndex", selectedAddOnIndex, toCropImage)
         let tempArray = [...ticketAddOnList];
             tempArray[selectedAddOnIndex].addOnImage = croppedImage;
             setTicketAddOnList(tempArray);
        }
      // setBannerImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    const filteredList = ticketCategoryList.filter(
      (category) => category.name !== "+ Create category"
    );

    setTicketCategoryList([...filteredList, { name: "+ Create category" }]);
  }, []);

  useEffect(() => {
    const allCompleted = eventOptions.every((option) => option.isCompleted);
    if (allCompleted) {
      setShouldShowPublishEventButton(true);
    } else {
      setShouldShowPublishEventButton(false);
    }
  }, [eventOptions]);

  useEffect(() => {
    async function fetchData() {
      try {
        const imageBlob = await fetchImage(bannerImage);
        const base64Data = await blobToBase64(imageBlob);
        setBannerImageBase64(base64Data);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }

    bannerImage !== "" && fetchData();
  }, [bannerImage]);

  useEffect(() => {
    async function fetchAddOnData() {
      try {
        const imageBlob = await fetchImage(ticketAddOnList[selectedAddOnIndex]?.addOnImage);
        const base64Data = await blobToBase64(imageBlob);
        let tempArray = [...ticketAddOnList];
        tempArray[selectedAddOnIndex].addOnBase64Image = base64Data;
        setTicketAddOnList(tempArray);
      } catch (error) {
        console.error("Error converting image:", error);
      }
    }

    ticketAddOnList[selectedAddOnIndex]?.addOnImage !== "" && fetchAddOnData();
  }, [ticketAddOnList[selectedAddOnIndex]?.addOnImage]);

  useEffect(() => {
    userInfo?.stripeAccountId && getStripeAccountStatusRequest();
  }, [userInfo]);

  // function getISOStringDate(dateString) {
  //   const [date, time] = dateString.split(" ");
  //   const [day, month, year] = date.split("/");
  //   const [hours, minutes] = time.split(":");
  //   const isoDateString = new Date(
  //     `${year}-${month}-${day}T${hours}:${minutes}:00`
  //   ).toISOString();
  //   return isoDateString;
  // }

  function getISOStringDate(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const isoString = `${year}-${month}-${day}T${timePart}:00.000Z`;
    return isoString;
  }

  let namesToMatch =
    eventCategory?.length > 0 && new Set(eventCategory.map((v) => v));

  const createEventRequest = async (flag, isActiveDraft = true) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    let isoStartDateString =
      startDate !== "DD/MM/YYYY" && startTime !== "00:00 "
        ? getISOStringDate(`${startDate} ${startTime}`)
        : "";
    let isoEndDateString =
      endDate !== "DD/MM/YYYY" && endTime !== "00:00 "
        ? getISOStringDate(`${endDate} ${endTime}`)
        : "";

    let commonIds = categoryList
      .filter((option) => eventCategory.includes(option.name))
      .map((option) => option.id);

    addOnCategoryList.map((category) => {});
    const tempAddOnArray = ticketAddOnList.map((addOn) => ({
      id: 0,
      name: addOn.name,
      imageDataBase64Enc: addOn.addOnBase64Image || "",
      imageDataContentType: "image/png",
      quantity: addOn.quantity,
      currency: eventCurrency || "",
      currencyId: eventCurrencyId || 0,
      price: addOn.price,
      description: addOn.description,
      id: 0,
      eventTicketTypeId: 0,
      addOnAvailabilities: addOn.addedToId,
      isAddOn: true,
      bookingFee: 0,
      bookingFeePct: bookingPercentValue || 5,
      addFeesToTicketPrice: addFeesToTicketPrice === 0 ? true : false,
      addBookingFeeToTicketPrice: isAddBookingFees === 0 ? false : true,
    }));

    const tempTicketArray = ticketList.map((ticket, index) => ({
      id: 0,
      indexId: index,
      name: ticket.name || "",
      description: ticket.description || "",
      eventTicketTypeCategory: { name: ticket.category || "" },
      price: ticket.price || 0,
      currencyId: eventCurrencyId || 0,
      quantity: ticket.quantity || "",
      ticketPrice: {},
      maxNoOfTickets: ticket.quantity,
      autoReleaseTickets: ticket.releaseType === "automatic" ? true : false,
      onSaleFromDate:
        ticket.releaseType !== "automatic" &&
        ticket.releaseDate !== "DD/MM/YYYY"
          ? getISOStringDate(`${ticket.releaseDate} ${ticket.releaseTime}`)
          : null,
      onSaleToDate:
        ticket.releaseType === "manual"
          ? getISOStringDate(
              `${ticket.releaseEndDate} ${ticket.releaseEndTime}`
            )
          : null,
      platformFee: 0,
      ticketGuardFee: 0,
      totalFees: 0,
      bookingFee: 0,
      bookingFeePct: bookingPercentValue || 5,
      addFeesToTicketPrice: addFeesToTicketPrice === 0 ? true : false,
      addBookingFeeToTicketPrice: isAddBookingFees === 0 ? false : true,
      maxUserTicketQty: totalTicketQuantity || 0,
      eventTicketTypeAgeCategory:
        ticket.selectedAgeIndex === 0
          ? "GeneralAdmission"
          : ticket.selectedAgeIndex === 1
          ? "Adult"
          : "Child",
      minimumAgeLimit: ticket.minimumAge || 0,
      maximumAgeLimit: ticket.maximumAge || 100,
      isAddOn: false,

      eventTicketTypeStageReleases:
        ticket.releaseType === "staged" && ticket.stageReleases.length > 0
          ? ticket.stageReleases.map((stage) => ({
              id: 0,
              offerName: stage.stageOfferName || "",
              quantity: stage.stageQuantity || "",
              price: stage.stagePrice || 0,
              currencyId: eventCurrencyId || 0,
              enabled: true,
              startDate:
                stage.activateNextRelease === true
                  ? null
                  : stage.stageReleaseDate !== "DD/MM/YYYY" &&
                    stage.stageReleaseTime !== "-"
                  ? getISOStringDate(
                      `${stage.stageReleaseDate} ${stage.stageReleaseTime}`
                    )
                  : null,
              endDate:
                stage.activateNextRelease === true
                  ? null
                  : stage.stageReleaseEndDate !== "DD/MM/YYYY" &&
                    stage.stageReleaseEndTime !== "-"
                  ? getISOStringDate(
                      `${stage.stageReleaseEndDate} ${stage.stageReleaseEndTime}`
                    )
                  : null,
              activeNextRelease: stage.activateNextRelease,
              platformFee: 0,
              ticketGuardFee: 0,
              totalFees: 0,
              bookingFee: 0,
              bookingFeePct: bookingPercentValue || 5,
              addFeesToTicketPrice: addFeesToTicketPrice === 0 ? true : false,
              addBookingFeeToTicketPrice: isAddBookingFees === 0 ? false : true,
            }))
          : [],
    }));

    const eventPayload = {
      id: 0,
      name: eventName || "",
      description: eventDescription || "",
      descriptionApp: eventDescriptionApp || "",
      eventImageDataBase64Enc: bannerImageBase64 || "",
      eventImageDataContentType: "image/png",
      startDate: isoStartDateString || "",
      endDate: isoEndDateString || "",
      eventCategoryId: eventCategoryId || [],
      EventCategoryIds: commonIds || [],
      currencyId: eventCurrencyId || 0,
      type: commonIds,
      eventCategoryNames: eventCategory || [],
      promoterId: userInfo?.businesses[userInfo?.businesses?.length - 1]?.id,
      isPublished: flag === "draft" ? false : true,
      eventTicketTypeCategories: ticketCategoryList,
      venue: {
        address: {
          addressLine1: addressLine1 || "",
          addressLine2: addressLine2 || "",
          city: city || "",
          postalCode: postalCode || "",
          countryId: countryId || "",
          country: country || "",
          latitude: venueAddress?.lat || 0,
          longitude: venueAddress?.lng || 0,
        },
        venueEntrances: entranceList?.map((entrance) => ({
          id: 0,
          venueId: 0,
          name: entrance.name || "",
          entranceUsers: [
            {
              userId: 0,
              userName: gateKeeperUserName,
              password: gateKeeperPassword,
            },
          ],
          // venueUserName: gateKeeperUserName,
          // venueUserPassword: gateKeeperPassword,
        })),
        name: venueName || "",
        description: venueDescription || "",
        // capacity: capacity || "",
      },
      ticketTypes: [...tempTicketArray, ...tempAddOnArray],

      ticketRequirements: {
        kycRequired: kycType === 0 ? true : false,
        kycMinimumAgeLimit:
          minimumAge === "-" || minimumAge === "e.g. 18" ? null : minimumAge,
        refundsAllowed:
          isRefundAcceptable === null && !isActiveDraft
            ? null
            : isRefundAcceptable === 1
            ? true
            : false,
        // refundsAllowed:
        //   isRefundAcceptable === null || isRefundAcceptable === false
        //     ? false
        //     : true,
      },
    };

    console.log("eventPayload::::::::::::", eventPayload);

    const newPl = JSON.parse(JSON.stringify(eventPayload));

    try {
      const result = isActiveDraft
        ? await createEvent(newPl)
        : await createEventWithInactiveDraft(newPl);
      if (result.status) {
        userInfo?.kycStatus === "Verified" &&
          isStripeDetailsSubmitted === true &&
          dispatch({
            type: BANNER_CONTENT,
            payload: {
              text: eventName + ` has been created successfully.`,
              headingText: "",
              showBanner: true,
              type: "Success",
              withClose: true,
              withIcon: true,
              position: "top",
            },
          });
        getEventData(
          flag === "draft" ? false : true,
          result.response,
          isoStartDateString,
          isoEndDateString,
          isActiveDraft
        );
      } else {
        //  const errorMessage = JSON.stringify(result.response);
        const errorMessage = getErrorMessages(result.response);
        dispatch({
          type: BANNER_CONTENT,
          payload: {
            text: errorMessage,
            headingText: "Error Creating Events",
            showBanner: true,
            type: "Error",
            withClose: true,
            withIcon: true,
            position: "top",
            width: 391,
          },
        });
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const getEventData = async (
    isPublished,
    eventId,
    startDateString,
    endDateString,
    isActiveDraft = true
  ) => {
    const result = isActiveDraft
      ? await getEventList({
          isPublished: isPublished,
          type:
            isPublished === false
              ? "All"
              : getTimeDiffStatus(startDateString, endDateString) === "Expired"
              ? "Past"
              : "Current",
        })
      : await getInactiveDraftEventList({});
    if (result.status) {
      !isPublished &&
        !showDraft &&
        dispatch({
          type: SHOW_DRAFT,
          payload: true,
        });
      if (isPublished === false) {
        localStorage.setItem("selectedSegmentIndex", 2);
      } else if (
        getTimeDiffStatus(startDateString, endDateString) === "Expired"
      ) {
        console.log("expired");
        localStorage.setItem("selectedSegmentIndex", 1);
      } else {
        console.log("current");
        localStorage.setItem("selectedSegmentIndex", 0);
      }
      isActiveDraft
        ? dispatch({ type: EVENT_DATA, payload: result.response })
        : getDraftEventData(result.response);
      isActiveDraft &&
        dispatch({
          type: SELECTED_EVENT_DATA,
          payload:
            result.response?.findIndex((item) => item?.eventId === eventId) + 3,
        });
      if (
        userInfo?.kycStatus !== "Verified" ||
        isStripeDetailsSubmitted !== true
      ) {
        setNavigationParamsEventDetails({
          type: isPublished === false ? "draft1" : "valid",
          eventId: eventId,
          event: {},
          nonActiveEventDetails: !isActiveDraft
            ? result.response[result.response?.length - 1 || 0]
            : false,
        });
        !isActiveDraft &&
          setNonActiveEventDetails(
            result.response[result.response?.length - 1 || 0]
          );
        setIsCustomModalVisible("completeAccountSetup");
      } else {
        navigate(`/EventDetails/${eventId}`, {
          state: {
            type: isPublished === false ? "draft1" : "valid",
            eventId: eventId,
            event: {},
            nonActiveEventDetails:
              result.response[result.response?.length - 1 || 0],
          },
        });
      }
    } else {
      dispatch({ type: EVENT_DATA, payload: [] });
    }
  };

  const getDraftEventData = async (nonActiveEventDetails) => {
    const result = await getEventList({
      isPublished: false,
      type: "All",
    });
    if (result.status) {
      let totalDrafts = [...result.response, ...nonActiveEventDetails];
      dispatch({ type: EVENT_DATA, payload: totalDrafts });
      dispatch({
        type: SELECTED_EVENT_DATA,
        payload: totalDrafts.length + 2,
      });
    } else {
      dispatch({
        type: SELECTED_EVENT_DATA,
        payload: nonActiveEventDetails.length + 2,
      });
      dispatch({ type: EVENT_DATA, payload: nonActiveEventDetails });
    }
  };

  const checkValidationUserName = async (value) => {
    const result = await venueUserInUser({ userName: value });
    if (result.response === false) {
      setGateKeeperUserNameAssistiveText("");
      return true;
    } else {
      setGateKeeperUserNameAssistiveText("Username already exists");
      return false;
    }
  };

  function getTimeDiffStatus(startDate, endDate) {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (currentDate < start) {
      // Event is upcoming
      const diffTime = start - currentDate;
      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diffTime % (1000 * 60)) / 1000);

      if (days > 0) {
        return "Upcoming";
      } else {
        return `${hours}h ${minutes}m ${seconds}s`;
      }
    } else if (currentDate >= start && currentDate <= end) {
      // Event is live
      return "Live";
    } else {
      // Event has expired
      return "Expired";
    }
  }

  const getStripeAccountStatusRequest = async () => {
    const result = await getStripeAccountStatus({
      stripeAccountId: userInfo?.stripeAccountId,
    });
    if (result.status) {
      setIsStripeDetailsSubmitted(result.response.isDetailsSubmitted);
    }
  };

  const stripeConnect = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await StripeAccountConnect({
      AccountType: "express",
      EmailAddress: userInfo?.emailAddress,
      CountryCode: "US",
      returnURL: `${process.env.REACT_APP_WEB_URL}/EventDetails/${navigationParamsEventDetails?.eventId}`,
    });
    if (result.status) {
      localStorage.setItem("isAccountVerificationRunning", true);
      window.open(result.response.url, "_self");
    } else {
      const errorMessage =
        result?.response?.response || "Error creating stripe connect request";
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: errorMessage,
          headingText: "",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const verifyAccountRequest = async () => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getUrlKycVerify({
      emailAddress: userInfo?.emailAddress,
      returnURL: `${process.env.REACT_APP_WEB_URL}/EventDetails/${navigationParamsEventDetails?.eventId}`,
    });
    if (result.status) {
      localStorage.setItem("isAccountVerificationRunning", true);
      window.open(result.response, "_self");
    } else {
      const errorMessage =
        result?.response?.response || "Error creating KYC request";
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: errorMessage,
          headingText: "",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const convertFieldName = (field) => {
    // Remove special characters like '$.' and split by camelCase
    let fieldName = field.replace("$.", "").replace(/([A-Z])/g, " $1");

    // Capitalize the first letter of the field
    return fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  };

  // Function to get a user-friendly error message
  const getErrorMessages = (errorResponse) => {
    const errorMessages = [];

    if (errorResponse.errors) {
      // Loop through the errors
      Object.keys(errorResponse.errors).forEach((field) => {
        const fieldName = convertFieldName(field); // Convert to a readable field name
        const message = errorResponse.errors[field][0]; // Get the error message
        errorMessages.push(
          `${fieldName} is invalid. Please make sure all the mandatory field(s) is filled out throughout different sections before you save any event.`
        ); // Format it as you need
      });
    }

    return errorMessages;
  };

  const onSaveAndContinueClicked = () => {
    switch (selectedEventOptionIndex) {
      case 0:
        if (
          eventName?.trim().length >= 1 &&
          eventDescription?.trim().length >= 1 &&
          eventDescriptionApp?.trim().length >= 1 &&
          eventCategory[0] !== "Select an option" &&
          eventCurrency !== "Select an option" &&
          bannerImage.length > 0 &&
          startDate !== "DD/MM/YYYY" &&
          endDate !== "DD/MM/YYYY" &&
          startTime !== "00:00 " &&
          endTime !== "00:00 "
        ) {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[0].isCompleted = true;
          setEventOptions(tempEventOptions);
          setToHaveAccessibleErrorMessageEventDetails([]);
          setToHaveAccessibleErrorMessageTiming([]);
          setSelectedEventOptionIndex(1);
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 400);
        } else {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[0].isCompleted = false;
          setEventOptions(tempEventOptions);
          const tempEventDetailsErrorArray = [];
          const tempTimingErrorArray = [];
          eventName?.trim().length < 1
            ? tempEventDetailsErrorArray.push("name")
            : tempEventDetailsErrorArray.filter((item) => item !== "name");
          eventDescription?.trim().length < 1
            ? tempEventDetailsErrorArray.push("description")
            : tempEventDetailsErrorArray.filter(
                (item) => item !== "description"
              );
          eventDescriptionApp?.trim().length < 1
            ? tempEventDetailsErrorArray.push("descriptionApp")
            : tempEventDetailsErrorArray.filter(
                (item) => item !== "descriptionApp"
              );
          eventCategory[0] === "Select an option"
            ? tempEventDetailsErrorArray.push("category")
            : tempEventDetailsErrorArray.filter((item) => item !== "category");
          eventCurrency === "Select an option"
            ? tempEventDetailsErrorArray.push("currency")
            : tempEventDetailsErrorArray.filter((item) => item !== "currency");
          bannerImage.length < 1
            ? tempEventDetailsErrorArray.push("banner")
            : tempEventDetailsErrorArray.filter((item) => item !== "banner");
          startDate === "DD/MM/YYYY"
            ? tempTimingErrorArray.push("startDate")
            : tempTimingErrorArray.filter((item) => item !== "startDate");
          endDate === "DD/MM/YYYY"
            ? tempTimingErrorArray.push("endDate")
            : tempTimingErrorArray.filter((item) => item !== "endDate");
          startTime === "00:00 "
            ? tempTimingErrorArray.push("startTime")
            : tempTimingErrorArray.filter((item) => item !== "startTime");
          endTime === "00:00 "
            ? tempTimingErrorArray.push("endTime")
            : tempTimingErrorArray.filter((item) => item !== "endTime");
          setToHaveAccessibleErrorMessageEventDetails(
            tempEventDetailsErrorArray
          );
          setToHaveAccessibleErrorMessageTiming(tempTimingErrorArray);
        }
        break;
      case 1:
        if (
          venueName?.trim().length >= 1 &&
          venueDescription?.trim().length >= 1 &&
          // capacity?.trim().length >= 1 &&
          addressLine1?.trim().length >= 1 &&
          postalCode?.trim().length >= 1 &&
          country?.trim().length >= 1 &&
          city?.trim().length >= 1 &&
          venueAddress
          // &&
          // ((entranceList.some((entrance) => entrance?.name?.trim() !== "") &&
          //   gateKeeperUserName?.trim().length > 0 &&
          //   gateKeeperPassword?.trim().length > 0 &&
          //   gateKeeperUserNameAssistiveText !== "Username already exists" &&
          //   gateKeeperUserNameAssistiveText !== "*User name is required") ||
          //   entranceList.every((entrance) => entrance?.name?.trim() === ""))
        ) {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[1].isCompleted = true;
          setEventOptions(tempEventOptions);
          setToHaveAccessibleErrorMessageVenueDetails([]);
          setToHaveAccessibleErrorMessageVenueAddress([]);
          setToHaveAccessibleErrorMessageVenueMap([]);
          setGateKeeperUserNameAssistiveText("");
          setGateKeeperPasswordAssistiveText("");
          setSelectedEventOptionIndex(2);
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 400);
        } else {
          setIsDeleteModalVisible("draft");
          // const tempEventOptions = [...eventOptions];
          // tempEventOptions[1].isCompleted = false;
          // setEventOptions(tempEventOptions);
          // const tempVenueDetailsErrorArray = [];
          // const tempVenueAddressErrorArray = [];
          // const tempVenueMapErrorArray = [];

          // venueName?.trim().length < 1
          //   ? tempVenueDetailsErrorArray.push("venueName")
          //   : tempVenueDetailsErrorArray.filter((item) => item !== "venueName");

          // venueDescription?.trim().length < 1
          //   ? tempVenueDetailsErrorArray.push("venueDescription")
          //   : tempVenueDetailsErrorArray.filter(
          //       (item) => item !== "venueDescription"
          //     );

          // // capacity?.trim().length < 1
          // //   ? tempVenueDetailsErrorArray.push("capacity")
          // //   : tempVenueDetailsErrorArray.filter((item) => item !== "capacity");

          // addressLine1?.trim().length < 1
          //   ? tempVenueAddressErrorArray.push("addressLine1")
          //   : tempVenueAddressErrorArray.filter(
          //       (item) => item !== "addressLine1"
          //     );

          // postalCode?.trim().length < 1
          //   ? tempVenueAddressErrorArray.push("postalCode")
          //   : tempVenueAddressErrorArray.filter(
          //       (item) => item !== "postalCode"
          //     );

          // country?.trim().length < 1
          //   ? tempVenueAddressErrorArray.push("country")
          //   : tempVenueAddressErrorArray.filter((item) => item !== "country");

          // city?.trim().length < 1
          //   ? tempVenueAddressErrorArray.push("city")
          //   : tempVenueAddressErrorArray.filter((item) => item !== "city");

          // venueAddress === "" || (!venueAddress?.lat && !venueAddress?.lng)
          //   ? tempVenueMapErrorArray.push("map")
          //   : tempVenueMapErrorArray.filter((item) => item !== "map");

          // // gateKeeperUserName?.trim().length < 1
          // //   ? setGateKeeperUserNameAssistiveText("*User name is required")
          // //   : checkValidationUserName(gateKeeperUserName);

          // // gateKeeperPassword?.trim().length < 1
          // //   ? setGateKeeperPasswordAssistiveText("*Password is required")
          // //   : setGateKeeperPasswordAssistiveText("");

          // setToHaveAccessibleErrorMessageVenueDetails(
          //   tempVenueDetailsErrorArray
          // );
          // setToHaveAccessibleErrorMessageVenueAddress(
          //   tempVenueAddressErrorArray
          // );
          // setToHaveAccessibleErrorMessageVenueMap(tempVenueMapErrorArray);
        }
        break;

      case 2:
        if (
          (kycType === 0 &&
            minimumAge !== "e.g. 18" &&
            isRefundAcceptable !== null) ||
          (kycType === 1 && isRefundAcceptable !== null)
        ) {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[2].isCompleted = true;
          setEventOptions(tempEventOptions);
          setToHaveAccessibleErrorMessageKyc([]);
          setToHaveAccessibleErrorMessageRefundPolicy([]);
          setSelectedEventOptionIndex(3);
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 400);
        } else {
          setIsDeleteModalVisible("draft");
          // const tempEventOptions = [...eventOptions];
          // tempEventOptions[2].isCompleted = false;
          // setEventOptions(tempEventOptions);
          // const tempKycMinAgeErrorArray = [];
          // const tempRefundPolicyErrorArray = [];

          // kycType === 0 && minimumAge === "e.g. 18"
          //   ? tempKycMinAgeErrorArray.push("kycMinAge")
          //   : tempKycMinAgeErrorArray.filter((item) => item !== "kycMinAge");

          // isRefundAcceptable === null
          //   ? tempRefundPolicyErrorArray.push("refundPolicy")
          //   : tempRefundPolicyErrorArray.filter(
          //       (item) => item !== "refundPolicy"
          //     );

          // setToHaveAccessibleErrorMessageKyc(tempKycMinAgeErrorArray);
          // setToHaveAccessibleErrorMessageRefundPolicy(
          //   tempRefundPolicyErrorArray
          // );
        }
        break;

      case 3:
        if (addFeesToTicketPrice !== null) {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[3].isCompleted = true;
          setEventOptions(tempEventOptions);
          setToHaveAccessibleErrorMessageFees([]);
          setSelectedEventOptionIndex(4);
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 400);
        } else {
          setIsDeleteModalVisible("draft");
          // const tempEventOptions = [...eventOptions];
          // tempEventOptions[3].isCompleted = false;
          // setEventOptions(tempEventOptions);
          // const tempAddFeesTicketPriceErrorArray = [];

          // addFeesToTicketPrice === null
          //   ? tempAddFeesTicketPriceErrorArray.push("addFeesToTicketPrice")
          //   : tempAddFeesTicketPriceErrorArray.filter(
          //       (item) => item !== "addFeesToTicketPrice"
          //     );

          // setToHaveAccessibleErrorMessageFees(tempAddFeesTicketPriceErrorArray);
        }
        break;

      case 4:
        ticketList.map((ticket, index) => {
          if (
            ticket?.name.trim().length >= 1 &&
            ticket?.description.trim().length >= 1 &&
            ticket?.category !== "-" &&
            ticket?.currency !== "Select an option" &&
            ticket?.quantity.trim().length >= 1 &&
            (ticket?.releaseType === "staged" ||
              (ticket?.releaseType !== "staged" &&
                ticket?.price.trim().length >= 1)) &&
            (ticket?.selectedAgeIndex === 0 ||
              (ticket?.minimumAge !== "" &&
                // ticket?.maximumAge !== "" &&
                ticket?.selectedAgeIndex !== null)) &&
            (ticket?.releaseType === "automatic" ||
              ticket?.releaseType === "staged" ||
              (ticket?.releaseType === "manual" &&
                ticket?.releaseDate !== "DD/MM/YYYY" &&
                ticket?.releaseTime !== "-" &&
                ticket?.releaseEndDate !== "DD/MM/YYYY" &&
                ticket?.releaseEndTime !== "-")) &&
            ((ticket?.releaseType !== "staged" &&
              ticket?.stageReleases?.every(
                (obj) =>
                  obj.stageOfferName === "" &&
                  obj.stageQuantity === "" &&
                  obj.stagePrice === "" &&
                  obj.stageReleaseDate === "DD/MM/YYYY" &&
                  obj.stageReleaseTime === "-" &&
                  obj.stageReleaseEndDate === "DD/MM/YYYY" &&
                  obj.stageReleaseEndTime === "-"
              )) ||
              (ticket?.releaseType === "staged" &&
                ticket?.stageReleases?.length > 0 &&
                ticket?.stageReleases?.every(
                  (obj) =>
                    obj.stageOfferName !== "" &&
                    obj.stageQuantity !== "" &&
                    obj.stagePrice !== "" &&
                    ((obj.activateNextRelease === false &&
                      obj.stageReleaseDate !== "DD/MM/YYYY" &&
                      obj.stageReleaseTime !== "-" &&
                      obj.stageReleaseEndDate !== "DD/MM/YYYY" &&
                      obj.stageReleaseEndTime !== "-") ||
                      obj.activateNextRelease === true)
                ) &&
                parseInt(
                  ticket.stageReleases.reduce(
                    (acc, release) => acc + parseInt(release.stageQuantity, 10),
                    0
                  )
                ) <= parseInt(ticket.quantity)))
          ) {
            let tempArray = [...ticketList];
            tempArray[index].toHaveAccessibleErrorMessageTicket = [];
            tempArray[index]?.stageReleases.map((stageRelease) => {
              stageRelease.toHaveAccessibleErrorMessageStageRelease = [];
            });

            setTicketList(tempArray);
          } else {
            const tempEventOptions = [...eventOptions];
            tempEventOptions[4].isCompleted = false;
            setEventOptions(tempEventOptions);

            const tempTicketErrorArray = [];

            if (ticket?.releaseType === "manual") {
              ticket?.releaseDate === "DD/MM/YYYY"
                ? tempTicketErrorArray.push("releaseDate")
                : tempTicketErrorArray.filter((item) => item !== "releaseDate");

              ticket?.releaseTime === "-"
                ? tempTicketErrorArray.push("releaseTime")
                : tempTicketErrorArray.filter((item) => item !== "releaseTime");

              ticket?.releaseEndDate === "DD/MM/YYYY"
                ? tempTicketErrorArray.push("releaseEndDate")
                : tempTicketErrorArray.filter(
                    (item) => item !== "releaseEndDate"
                  );

              ticket?.releaseEndTime === "-"
                ? tempTicketErrorArray.push("releaseEndTime")
                : tempTicketErrorArray.filter(
                    (item) => item !== "releaseEndTime"
                  );
            }

            ticket?.name?.trim().length < 1
              ? tempTicketErrorArray.push("ticketName")
              : tempTicketErrorArray.filter((item) => item !== "ticketName");

            ticket?.description?.trim().length < 1
              ? tempTicketErrorArray.push("description")
              : tempTicketErrorArray.filter((item) => item !== "description");

            ticket?.category === "-"
              ? tempTicketErrorArray.push("category")
              : tempTicketErrorArray.filter((item) => item !== "category");

            ticket?.currency === "Select an option"
              ? tempTicketErrorArray.push("currency")
              : tempTicketErrorArray.filter((item) => item !== "currency");

            ticket?.price?.trim().length < 1
              ? tempTicketErrorArray.push("ticketPrice")
              : tempTicketErrorArray.filter((item) => item !== "ticketPrice");

            ticket?.quantity?.trim().length < 1
              ? tempTicketErrorArray.push("quantity")
              : tempTicketErrorArray.filter((item) => item !== "quantity");

            let tempArray = [...ticketList];
            tempArray[index].toHaveAccessibleErrorMessageTicket =
              tempTicketErrorArray;
            // setTicketList(tempArray);

            ticket?.selectedAgeIndex === null
              ? tempTicketErrorArray.push("selectedAgeOptionIndex")
              : tempTicketErrorArray.filter(
                  (item) => item !== "selectedAgeOptionIndex"
                );

            (ticket?.selectedAgeIndex === 1 ||
              ticket?.selectedAgeIndex === 2) &&
            ticket?.minimumAge === ""
              ? tempTicketErrorArray.push("minAge")
              : tempTicketErrorArray.filter((item) => item !== "minAge");

            // (ticket?.selectedAgeIndex === 1 ||
            //   ticket?.selectedAgeIndex === 2) &&
            // ticket?.maximumAge === ""
            //   ? tempTicketErrorArray.push("maxAge")
            //   : tempTicketErrorArray.filter((item) => item !== "maxAge");

            ticket?.stageReleases?.length === 0
              ? tempTicketErrorArray.push("stageReleasesCountZero")
              : tempTicketErrorArray.filter(
                  (item) => item !== "stageReleasesCountZero"
                );

            ticket?.stageReleases.map((stageRelease, ind) => {
              const tempstageReleaseErrorArray = [];
              stageRelease?.stageOfferName?.trim().length < 1
                ? tempstageReleaseErrorArray.push("stageOfferName")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "stageOfferName"
                  );

              const totalStageReleasesQuantity = ticket?.stageReleases.reduce(
                (acc, release) => acc + parseInt(release.stageQuantity),
                0
              );
              parseInt(totalStageReleasesQuantity) > parseInt(ticket.quantity)
                ? tempTicketErrorArray.push("quantity2")
                : tempTicketErrorArray.filter((item) => item !== "quantity2");

              stageRelease?.stageQuantity?.trim().length < 1
                ? tempstageReleaseErrorArray.push("stageQuantity")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "stageQuantity"
                  );

              stageRelease?.stagePrice?.trim().length < 1
                ? tempstageReleaseErrorArray.push("stageTicketPrice")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "stageTicketPrice"
                  );

              stageRelease?.stageReleaseDate === "DD/MM/YYYY"
                ? tempstageReleaseErrorArray.push("releaseDate")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "releaseDate"
                  );

              stageRelease?.stageReleaseTime === "-"
                ? tempstageReleaseErrorArray.push("releaseTime")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "releaseTime"
                  );

              stageRelease?.stageReleaseEndDate === "DD/MM/YYYY"
                ? tempstageReleaseErrorArray.push("releaseEndDate")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "releaseEndDate"
                  );

              stageRelease?.stageReleaseEndTime === "-"
                ? tempstageReleaseErrorArray.push("releaseEndTime")
                : tempstageReleaseErrorArray.filter(
                    (item) => item !== "releaseEndTime"
                  );

              ticket?.releaseType === "staged" &&
              stageRelease.stageOfferName === "" &&
              stageRelease.stageQuantity === "" &&
              stageRelease.stagePrice === "" &&
              stageRelease.stageReleaseDate === "DD/MM/YYYY" &&
              stageRelease.stageReleaseTime === "-" &&
              stageRelease.stageReleaseEndDate === "DD/MM/YYYY" &&
              stageRelease.stageReleaseEndTime === "-"
                ? tempTicketErrorArray.push("selectLeastOneStage")
                : tempTicketErrorArray.filter(
                    (item) => item !== "selectLeastOneStage"
                  );

              tempArray[index].stageReleases[
                ind
              ].toHaveAccessibleErrorMessageStageRelease =
                tempstageReleaseErrorArray;
            });
            console.log("temparr", tempArray);

            setTicketList(tempArray);
          }
        });

        const keyToCheck = "toHaveAccessibleErrorMessageTicket";
        const keyToCheckStageRelease =
          "toHaveAccessibleErrorMessageStageRelease";
        const allAccessible = ticketList.every(
          (obj) =>
            Array.isArray(obj[keyToCheck]) &&
            obj[keyToCheck].length === 0 &&
            obj.stageReleases.every(
              (stageRelease) =>
                Array.isArray(stageRelease[keyToCheckStageRelease]) &&
                stageRelease[keyToCheckStageRelease].length === 0
            )
        );
        if (allAccessible) {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[4].isCompleted = true;
          tempEventOptions[5].isCompleted = true;
          setEventOptions(tempEventOptions);
          setSelectedEventOptionIndex(5);
          const tempCategoryArray = [];
          ticketList.map((ticket, index) => {
            ticket.name !== "" &&
              tempCategoryArray.push({ name: ticket?.name, id: index });
          });
          setAddOnCategoryList(tempCategoryArray);
          let tempArrayAddOn = [...ticketAddOnList];
          tempArrayAddOn.map((addOn, index) => {
            const filteredArr = tempCategoryArray.filter(
              (items) => !addOn.addedTo?.includes(items.name)
            );
            addOn.addOnCategoryList = filteredArr;
          });
          setTicketAddOnList(tempArrayAddOn);

          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }, 400);
        } else {
          setIsDeleteModalVisible("draft");
        }
        break;

      case 5:
        ticketAddOnList.map((addOn, index) => {
          if (
            addOn?.name.trim().length >= 1 &&
            addOn?.description.trim().length >= 1 &&
            addOn?.price.trim().length >= 1 &&
            addOn?.quantity.trim().length >= 1 &&
            addOn?.addedTo?.length > 0 &&
            addOn?.addedTo?.includes("Select an option") === false
          ) {
            let tempArray = [...ticketAddOnList];
            tempArray[index].toHaveAccessibleErrorMessageTicketAddOn = [];
            setTicketAddOnList(tempArray);
          } else {
            const tempEventOptions = [...eventOptions];
            tempEventOptions[5].isCompleted = false;
            setEventOptions(tempEventOptions);

            const tempAddOnErrorArray = [];

            addOn?.name?.trim().length < 1
              ? tempAddOnErrorArray.push("name")
              : tempAddOnErrorArray.filter((item) => item !== "name");

            addOn?.description?.trim().length < 1
              ? tempAddOnErrorArray.push("description")
              : tempAddOnErrorArray.filter((item) => item !== "description");

            addOn?.price?.trim().length < 1
              ? tempAddOnErrorArray.push("price")
              : tempAddOnErrorArray.filter((item) => item !== "price");

            addOn?.quantity?.trim().length < 1
              ? tempAddOnErrorArray.push("quantity")
              : tempAddOnErrorArray.filter((item) => item !== "quantity");

            addOn?.addedTo?.includes("Select an option") === true ||
            addOn?.addedTo?.length === 0
              ? tempAddOnErrorArray.push("addedTo")
              : tempAddOnErrorArray.filter((item) => item !== "addedTo");

            let tempArray = [...ticketAddOnList];
            tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
              tempAddOnErrorArray;

            setTicketAddOnList(tempArray);
          }
        });
        const keyToCheck1 = "toHaveAccessibleErrorMessageTicketAddOn";
        const allAccessible1 = ticketAddOnList.every(
          (obj) =>
            Array.isArray(obj[keyToCheck1]) && obj[keyToCheck1].length === 0
        );
        if (allAccessible1) {
          const tempEventOptions = [...eventOptions];
          tempEventOptions[5].isCompleted = true;
          setEventOptions(tempEventOptions);
          setIsDeleteModalVisible("draft");
        } else {
          setIsDeleteModalVisible("draft");
        }

        break;
    }
  };

  const handleNavigation = (path, flag, item, type) => {
    const areEntranceAllItemsNotBlank = entranceList.every((obj) =>
      Object.values(obj).every((value) => value !== "" || value !== false)
    );

    const requiredKeys = [
      "name",
      "quantity",
      "startDate",
      "startMonth",
      "startYear",
      "endDate",
      "endMonth",
      "endYear",
      "startTime",
      "endTime",
    ];

    const areTicketSpecificKeysNotBlank = ticketList.every((obj) =>
      requiredKeys.every((key) => obj[key] !== "")
    );

    if (
      eventName !== "" &&
      eventDescription !== "" &&
      eventDescriptionApp !== "" &&
      bannerImage !== "" &&
      eventCategory[0] !== "Select an option" &&
      startDate !== "" &&
      endDate !== "" &&
      startTime !== "" &&
      endTime !== "" &&
      venueName !== "" &&
      venueDescription !== "" &&
      venueAddress !== "" &&
      addressLine1 !== "" &&
      addressLine2 !== "" &&
      postalCode !== "" &&
      country !== "" &&
      city !== "" &&
      areEntranceAllItemsNotBlank &&
      areTicketSpecificKeysNotBlank
    ) {
      setIsDeleteModalVisible("draft");
    } else {
      flag === "savedEvent"
        ? navigate(`/EventDetails/${item?.eventId}`, {
            state: {
              type: type,
              eventId: item.eventId,
              event: item,
            },
          })
        : path === "/Home"
        ? navigate("/Home", {
            state: { selectedIndex: flag },
          })
        : navigate(path);
    }
  };

  const segmentBtnPressedEvent = (index) => {
    if (index != selectedEventOptionIndex) {
      setSelectedEventOptionIndex(index);
    }
  };

  const onCountryChange = (selectedCountryName) => {
    setCountry(selectedCountryName);
    let tempArray = [...toHaveAccessibleErrorMessageVenueAddress];
    tempArray = tempArray.filter((item) => item !== "country");
    setToHaveAccessibleErrorMessageVenueAddress([]);
    const foundCountry = countryList.find(
      (country) => country.name === selectedCountryName
    );
    if (foundCountry) {
      setCountryId(foundCountry.id);
    } else {
      setCountryId("");
    }
  };

  const onEventCategoryChange = (selectedCategoryName, index) => {
    let tempCategoryArray = [...eventCategory];
    tempCategoryArray[index] = selectedCategoryName;
    setEventCategory(tempCategoryArray);

    const foundCategory = categoryList.find(
      (category) => category.name === selectedCategoryName
    );
    if (foundCategory) {
      let tempCategoryIds = [...eventCategoryId];
      tempCategoryIds.push(foundCategory.id);
      setEventCategoryId(tempCategoryIds);
    }
  };

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

  async function fetchImage(url) {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  const renderEventDetails = () => {
    return (
      <div className="CreateEventBody">
        <EventDetails
          eventName={eventName}
          eventDescription={eventDescription}
          eventDescriptionApp={eventDescriptionApp}
          bannerImage={bannerImage}
          eventCategory={eventCategory}
          eventCurrency={eventCurrency}
          onEventNameChange={(value) => {
            setEventName(value);
            let tempArray = [...toHaveAccessibleErrorMessageEventDetails];
            tempArray = tempArray.filter((item) => item !== "name");
            setToHaveAccessibleErrorMessageEventDetails(tempArray);
          }}
          onEventDescriptionChange={(value) => {
            setEventDescription(value);
            let tempArray = [...toHaveAccessibleErrorMessageEventDetails];
            tempArray = tempArray.filter((item) => item !== "description");
            setToHaveAccessibleErrorMessageEventDetails(tempArray);
          }}
          onEventDescriptionAppChange={(value) => {
            setEventDescriptionApp(value);
            let tempArray = [...toHaveAccessibleErrorMessageEventDetails];
            tempArray = tempArray.filter((item) => item !== "descriptionApp");
            setToHaveAccessibleErrorMessageEventDetails(tempArray);
          }}
          onBannerImageChange={(value) => {
            if (value) {
              setToCropImage(value);
              setIsCustomModalVisible("posterCrop");
              let tempArray = [...toHaveAccessibleErrorMessageEventDetails];
              tempArray = tempArray.filter((item) => item !== "banner");
              setToHaveAccessibleErrorMessageEventDetails(tempArray);
            } else {
              setBannerImage(value);
            }
          }}
          onEventCategoryChange={(value, index) => {
            onEventCategoryChange(value, index);
            let tempArray = [...toHaveAccessibleErrorMessageEventDetails];
            tempArray = tempArray.filter((item) => item !== "category");
            setToHaveAccessibleErrorMessageEventDetails(tempArray);
          }}
          onEventCurrencyChange={(value, id) => {
            setEventCurrency(value);
            setEventCurrencyId(id);
            let tempArray = [...ticketList];
            tempArray[0].stageReleases[0].stageCurrency = value;
            setTicketList(tempArray);
            let tempErrorArray = [...toHaveAccessibleErrorMessageEventDetails];
            tempErrorArray = tempErrorArray.filter(
              (item) => item !== "currency"
            );
            setToHaveAccessibleErrorMessageEventDetails(tempErrorArray);
          }}
          onAddAnotherCategoryClicked={() => {
            let tempArray = [...eventCategory];
            tempArray.push("Select an option");
            setEventCategory(tempArray);
          }}
          onRemoveCategoryClicked={(ind) => {
            let tempArray = [...eventCategory];
            const newAddedToArray = [
              ...tempArray.slice(0, ind),
              ...tempArray.slice(ind + 1),
            ];
            tempArray = newAddedToArray;
            setEventCategory(tempArray);
          }}
          showAddAnotherCategory={
            eventCategory[eventCategory.length - 1] !== "Select an option" ||
            eventCategory.length === 0
              ? true
              : false
          }
          shouldShowSubmitButton={false}
          toHaveAccessibleErrorMessage={
            toHaveAccessibleErrorMessageEventDetails
          }
        />

        <Timing
          setSelectedStartDateString={(value) => {
            setStartDate(value);
            let tempArray = [...toHaveAccessibleErrorMessageTiming];
            tempArray = tempArray.filter((item) => item !== "startDate");
            setToHaveAccessibleErrorMessageTiming(tempArray);
          }}
          setSelectedEndDateString={(value) => {
            setEndDate(value);
            let tempArray = [...toHaveAccessibleErrorMessageTiming];
            tempArray = tempArray.filter((item) => item !== "endDate");
            setToHaveAccessibleErrorMessageTiming(tempArray);
          }}
          setSelectedStartTimeString={(value) => {
            setStartTime(value);
            let tempArray = [...toHaveAccessibleErrorMessageTiming];
            tempArray = tempArray.filter((item) => item !== "startTime");
            setToHaveAccessibleErrorMessageTiming(tempArray);
          }}
          setSelectedEndTimeString={(value) => {
            setEndTime(value);
            let tempArray = [...toHaveAccessibleErrorMessageTiming];
            tempArray = tempArray.filter((item) => item !== "endTime");
            setToHaveAccessibleErrorMessageTiming(tempArray);
          }}
          selectedStartDateString={startDate}
          selectedEndDateString={endDate}
          selectedStartTimeString={startTime}
          selectedEndTimeString={endTime}
          shouldShowSubmitButton={false}
          toHaveAccessibleErrorMessage={toHaveAccessibleErrorMessageTiming}
        />
      </div>
    );
  };

  const renderVenue = () => {
    return (
      <div>
        <EventDetails
          forVenue={true}
          eventName={venueName}
          eventDescription={venueDescription}
          // capacity={capacity}
          onEventNameChange={(value) => {
            setVenueName(value);
            let tempArray = [...toHaveAccessibleErrorMessageVenueDetails];
            tempArray = tempArray.filter((item) => item !== "venueName");
            setToHaveAccessibleErrorMessageVenueDetails(tempArray);
          }}
          onEventDescriptionChange={(value) => {
            setVenueDescription(value);
            let tempArray = [...toHaveAccessibleErrorMessageVenueDetails];
            tempArray = tempArray.filter((item) => item !== "venueDescription");
            setToHaveAccessibleErrorMessageVenueDetails(tempArray);
          }}
          // onCapacityChange={(value) => {
          //   setCapacity(value);
          //   let tempArray = [...toHaveAccessibleErrorMessageVenueDetails];
          //   tempArray = tempArray.filter((item) => item !== "capacity");
          //   setToHaveAccessibleErrorMessageVenueDetails(tempArray);
          // }}
          shouldShowSubmitButton={false}
          toHaveAccessibleErrorMessage={
            toHaveAccessibleErrorMessageVenueDetails
          }
        />

        <VenueMap
          city={city}
          currentLatLng={venueAddress}
          searchAddress={venueSearchAddress}
          setSearchAddress={(value) => setVenueSearchAddress(value)}
          onCurrentLatLngChange={(value) => {
            setVenueAddress(value);
            console.log("valuesetVenueAddress", value);
            let tempArray = [...toHaveAccessibleErrorMessageVenueMap];
            tempArray = tempArray.filter((item) => item !== "map");
            setToHaveAccessibleErrorMessageVenueMap(tempArray);
          }}
          onLocationDetailsChange={(value) => {
            setAddressLine1(value.address1 || "");
            setCity(value.city || "");
            setCountry(value.country || "");
            setPostalCode(value.postalCode || "");
            const foundCountry = countryList.find(
              (country) => country.name === value.country
            );
            if (foundCountry) {
              setCountryId(foundCountry.id);
            } else {
              setCountryId("");
            }
          }}
          shouldShowSubmitButton={false}
          toHaveAccessibleErrorMessage={toHaveAccessibleErrorMessageVenueMap}
        />

        <VenueAddress
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          postalCode={postalCode}
          country={country}
          city={city}
          onAddressLine1Change={(value) => {
            setAddressLine1(value);
            let tempArray = [...toHaveAccessibleErrorMessageVenueAddress];
            tempArray = tempArray.filter((item) => item !== "addressLine1");
            setToHaveAccessibleErrorMessageVenueAddress(tempArray);
          }}
          onAddressLine2Change={(value) => setAddressLine2(value)}
          onPostalCodeChange={(value) => {
            setPostalCode(value);
            let tempArray = [...toHaveAccessibleErrorMessageVenueAddress];
            tempArray = tempArray.filter((item) => item !== "postalCode");
            setToHaveAccessibleErrorMessageVenueAddress(tempArray);
          }}
          onCountryChange={onCountryChange}
          onCityChange={(value) => {
            setCity(value);
            let tempArray = [...toHaveAccessibleErrorMessageVenueAddress];
            tempArray = tempArray.filter((item) => item !== "city");
            tempArray = tempArray.filter((item) => item !== "country");
            setToHaveAccessibleErrorMessageVenueAddress(tempArray);
          }}
          shouldShowSubmitButton={false}
          toHaveAccessibleErrorMessage={
            toHaveAccessibleErrorMessageVenueAddress
          }
        />

        <div
          className="EntrancesCont"
          style={{ marginLeft: 28, marginRight: 28, marginBottom: "40px" }}
        >
          <SocialLink
            gateKeeperUserName={gateKeeperUserName}
            gateKeeperPassword={gateKeeperPassword}
            gateKeeperUserNameAssistiveText={gateKeeperUserNameAssistiveText}
            gateKeeperPasswordAssistiveText={gateKeeperPasswordAssistiveText}
            onGateKeeperUserNameChange={(value) => {
              setGateKeeperUserName(value);
              setGateKeeperUserNameAssistiveText("");
            }}
            onGateKeeperPasswordChange={(value) => {
              setGateKeeperPassword(value);
              setGateKeeperPasswordAssistiveText("");
            }}
            addressLine2={addressLine2}
            type={"entrance"}
            data={entranceList}
            onSocialListChange={(value) => setEntranceList(value)}
            checkValidationUserName={(value) => checkValidationUserName(value)}
          />
        </div>
      </div>
    );
  };

  const renderRequirement = () => {
    return (
      <div>
      <KYC ticketGuardFee={`${currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.symbol || ''}${currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.ticketGuardFee || ''}`}
          type={kycType}
          minimumAge={minimumAge}
          onChangeKYCType={(value) => setKycType(value)}
          onChangeMinimumAge={(value) => setMinimumAge(value)}
          shouldShowSubmitButton={false}
          toHaveAccessibleErrorMessage={toHaveAccessibleErrorMessageKyc}
        /> 

        <RefundPolicy
          isRefundAccepted={isRefundAcceptable}
          onRefundAcceptedChange={(value) => {
            setIsRefundAcceptable(value);
            let tempArray = [...toHaveAccessibleErrorMessageRefundPolicy];
            tempArray = tempArray.filter((item) => item !== "refundPolicy");
            setToHaveAccessibleErrorMessageRefundPolicy(tempArray);
          }}
          toHaveAccessibleErrorMessage={
            toHaveAccessibleErrorMessageRefundPolicy
          }
        />
      </div>
    );
  };

  const renderFees = () => {
    return (
      <div>
        <Fees
          feesObject={currencyList?.find((x) => x.id === eventCurrencyId)}
          shouldShowSubmitButton={false}
          addTenPercentageBooking={isAddBookingFees}
          onAddTenPercentageBookingChange={(value) =>
            setIsAddBookingFees(value)
          }
          onAddFeesToTicketPriceChange={(value) => {
            setAddFeesToTicketPrice(value);
            let tempArray = [...toHaveAccessibleErrorMessageFees];
            tempArray = tempArray.filter(
              (item) => item !== "addFeesToTicketPrice"
            );
            setToHaveAccessibleErrorMessageFees(tempArray);
          }}
          addFeesToTicketPrice={addFeesToTicketPrice}
          onChangeBookingPrice={(value) => setBookingPercentValue(value)}
          bookingPercentVal={bookingPercentValue}
          currencySymbol={
            currencyList?.find((x) => x.id === eventCurrencyId)?.symbol || ""
          }
          toHaveAccessibleErrorMessage={toHaveAccessibleErrorMessageFees}
          feeLineItemArray={
            kycType === 0
              ? [
                  {
                    label1: "Platform fee",
                    label2: currencyList?.find((x) => x.id === eventCurrencyId)
                      ?.platformFee
                      ? currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.symbol
                        ? `${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFeePct
                          }% +  ${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                          }${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFee
                          }
              `
                        : `${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFeePct
                          }% + ${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFee
                          }
    `
                      : "",
                    label3: `${
                      currencyList?.find((x) => x.id === eventCurrencyId)
                        ?.platformFeePct
                    }% of ticket price`,
                  },
                  {
                    label1: "TicketGuard™ fee",
                    label2: currencyList?.find((x) => x.id === eventCurrencyId)
                      ?.ticketGuardFee
                      ? currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.symbol
                        ? `${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                          }${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.ticketGuardFee
                          }
          `
                        : `${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.ticketGuardFee
                          }
`
                      : "",
                  },
                  {
                    label1: "Booking fee",
                    label2: currencyList?.find((x) => x.id === eventCurrencyId)
                      ?.symbol
                      ? `${bookingPercentValue}% per ticket (${
                          currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                        })`
                      : `${bookingPercentValue}% per ticket`,
                    label3:
                      "Fee will vary depending on the price of your ticket(s)",
                  },
                ]
              : [
                  {
                    label1: "Platform fee",
                    label2: currencyList?.find((x) => x.id === eventCurrencyId)
                      ?.platformFee
                      ? currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.symbol
                        ? `${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFeePct
                          }% +  ${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.symbol
                          }${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFee
                          }
              `
                        : `${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFeePct
                          }% + ${
                            currencyList?.find((x) => x.id === eventCurrencyId)
                              ?.platformFee
                          }
    `
                      : "",
                    label3: `${
                      currencyList?.find((x) => x.id === eventCurrencyId)
                        ?.platformFeePct
                    }% of ticket price`,
                  },
                  {
                    label1: "Booking fee",
                    label2: currencyList?.find((x) => x.id === eventCurrencyId)
                      ?.symbol
                      ? `${bookingPercentValue}% per ticket (${
                          currencyList?.find((x) => x.id === eventCurrencyId)
                            ?.symbol
                        })`
                      : `${bookingPercentValue}% per ticket`,
                    label3:
                      "Fee will vary depending on the price of your ticket(s)",
                  },
                ]
          }
        />
      </div>
    );
  };

  const kYCOptions = [
    {
      text: "Add Bolt ON",
      value: true,
    },
    {
      text: "Add Bolt OFF",
      value: false,
    },
  ];

  const renderTicket = () => {
    return (
      <div>
        <TotalTickets
          ticketQuantity={totalTicketQuantity}
          onTicketQuantityChange={(value) => setTotalTicketQuantity(value)}
        />

        {ticketList.map((item, index) => {
          return (
            <div>
              <Ticket
                eventStartDate={startDate}
                eventStartTime={startTime === "00:00 " ? "00:00" : startTime}
                fees={{
                  platformFee: currencyList?.find(
                    (x) => x.id === eventCurrencyId
                  )?.platformFee,
                  platformFeePct: currencyList?.find(
                    (x) => x.id === eventCurrencyId
                  )?.platformFeePct,
                  ticketGuardFee:
                    kycType === 0
                      ? currencyList?.find((x) => x.id === eventCurrencyId)
                          ?.ticketGuardFee
                      : 0,
                  bookingPercentValue: bookingPercentValue,
                  isAddBookingFees: isAddBookingFees,
                  isAddFeesToTicketPrice: addFeesToTicketPrice,
                  maxBookingFee: currencyList?.find(
                    (x) => x.id === eventCurrencyId
                  )?.maxBookingFee,
                }}
                index={index}
                ticketReleaseType={item.releaseType}
                ticketReleaseDate={item.releaseDate}
                ticketReleaseTime={item.releaseTime}
                ticketReleaseEndDate={item.releaseEndDate}
                ticketReleaseEndTime={item.releaseEndTime}
                ticketName={item.name}
                ticketQuantity={item.quantity}
                ticketDescription={item.description}
                ticketCategory={item.category}
                ticketCurrency={
                  eventCurrency === "Select an option" ? "-" : eventCurrency
                }
                ticketPrice={item.price}
                selectedAgeIndex={item.selectedAgeIndex}
                minAge={item.minimumAge}
                maxAge={item.maximumAge}
                stageReleases={item.stageReleases}
                ticketCategoryList={ticketCategoryList}
                toHaveAccessibleErrorMessage={
                  item.toHaveAccessibleErrorMessageTicket
                }
                onChangeReleaseType={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].releaseType = value;
                  setTicketList(tempArray);
                }}
                onTicketReleaseDateChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].releaseDate = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "releaseDate"
                    );
                  setTicketList(tempArray);
                }}
                onTicketReleaseTimeChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].releaseTime = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "releaseTime"
                    );
                  setTicketList(tempArray);
                }}
                onTicketReleaseEndDateChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].releaseEndDate = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "releaseEndDate"
                    );
                  setTicketList(tempArray);
                }}
                onTicketReleaseEndTimeChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].releaseEndTime = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "releaseEndTime"
                    );
                  setTicketList(tempArray);
                }}
                onTicketNameChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].name = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "ticketName"
                    );
                  setTicketList(tempArray);
                }}
                onTicketQuantityChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].quantity = value;
                  console.log(
                    "tempArray[index].toHaveAccessibleErrorMessageTicket",
                    tempArray[index].toHaveAccessibleErrorMessageTicket
                  );
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "quantity"
                    );
                  setTicketList(tempArray);
                }}
                onTicketDescriptionChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].description = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "description"
                    );
                  setTicketList(tempArray);
                }}
                onTicketCategoryChange={(value) => {
                  let tempArray = [...ticketList];
                  if (value === "+ Create category") {
                    setIsCategoryModalVisible(true);
                    setSelectedTicketIndex(index);
                    tempArray[index].category = "Select an option";
                  } else {
                    tempArray[index].category = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicket =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicket?.filter(
                        (item) => item !== "category"
                      );
                  }
                  setTicketList(tempArray);
                }}
                onTicketCurrencyChange={(value) => {
                  let tempArray = [...ticketList];
                  let currencyId = currencyList.find(
                    (currency) => currency.name === value
                  )?.id;
                  tempArray[index].currency = value;
                  tempArray[index].currencyId = currencyId;

                  setTicketList(tempArray);
                }}
                onTicketPriceChange={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].price = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "ticketPrice"
                    );
                  setTicketList(tempArray);
                }}
                onChangeSelectedAgeIndex={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].selectedAgeIndex = value;
                  tempArray[index].minimumAge = "";
                  tempArray[index].maximumAge = "";
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "selectedAgeOptionIndex"
                    );
                  setTicketList(tempArray);
                }}
                onChangeMinAge={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].minimumAge = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "minAge"
                    );
                  setTicketList(tempArray);
                }}
                onChangeMaxAge={(value) => {
                  let tempArray = [...ticketList];
                  tempArray[index].maximumAge = value;
                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "maxAge"
                    );
                  setTicketList(tempArray);
                }}
                onStageOfferNameChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].stageOfferName = value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "stageOfferName"
                  );
                  setTicketList(tempArray);
                }}
                onStageQuantityChange={(value, ind, event) => {
                  setSelectedTicketIndex(ind);
                  let tempArray = [...ticketList];

                  // const totalStageQuantity = tempArray[
                  //   ind
                  // ]?.stageReleases.reduce(
                  //   (acc, release) => acc + parseInt(release?.stageQuantity, 10),
                  //   0
                  // );

                  // const maxQuantity = parseInt(tempArray[ind]?.quantity, 10);

                  // // Calculate the new total if the current input is added
                  // const newTotalQuantity =
                  //   totalStageQuantity -
                  //   parseInt(
                  //     tempArray[ind]?.stageReleases[ind]?.stageQuantity,
                  //     10
                  //   ) +
                  //   parseInt(value, 10);

                  // if (newTotalQuantity > maxQuantity) {
                  //   tempArray[index].stageReleases[ind].stageQuantity =
                  //     maxQuantity;
                  // } else {
                  //   tempArray[index].stageReleases[ind].stageQuantity = value;
                  // }
                  tempArray[index].stageReleases[ind].stageQuantity = value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ]?.toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "stageQuantity"
                  );
                  setTicketList(tempArray);
                }}
                onStageReleaseDateChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].stageReleaseDate = value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "releaseDate"
                  );
                  setTicketList(tempArray);
                }}
                onStageReleaseTimeChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].stageReleaseTime = value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "releaseTime"
                  );
                  setTicketList(tempArray);
                }}
                onStageReleaseEndDateChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].stageReleaseEndDate =
                    value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "releaseEndDate"
                  );
                  setTicketList(tempArray);
                }}
                onStageReleaseEndTimeChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].stageReleaseEndTime =
                    value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "releaseEndTime"
                  );
                  setTicketList(tempArray);
                }}
                onStageCurrencyChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  let currencyId = currencyList.find(
                    (currency) => currency.name === value
                  )?.id;
                  tempArray[index].stageReleases[ind].stageCurrency = value;
                  tempArray[index].stageReleases[ind].stageCurrencyId =
                    currencyId;
                  setTicketList(tempArray);
                }}
                onStagePriceChange={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].stagePrice = value;
                  tempArray[index].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease = tempArray[
                    index
                  ].stageReleases[
                    ind
                  ].toHaveAccessibleErrorMessageStageRelease?.filter(
                    (item) => item !== "stageTicketPrice"
                  );
                  setTicketList(tempArray);
                }}
                onChangeActivateNextRelease={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].activateNextRelease =
                    value;
                  setTicketList(tempArray);
                }}
                onChangeAutoTriggerNextRelease={(value, ind) => {
                  let tempArray = [...ticketList];
                  tempArray[index].stageReleases[ind].autoTriggerNextRelease =
                    value;
                  setTicketList(tempArray);
                }}
                onClinkingNewCategory={() => {
                  setIsCategoryModalVisible(true);
                  setSelectedTicketIndex(index);
                }}
                shouldShowSubmitButton={false}
                addAnotherStageRelease={(indd) => {
                  let tempArray = [...ticketList];
                  // const tempArray = ticketList.map((ticketType) => {
                  //   ticketType.stageReleases.forEach((release) => {
                  //     release.showActivateNextRelease = Boolean(
                  //       release.stageReleaseDate !== "DD/MM/YYYY" &&
                  //         release.stageReleaseTime !== "-" &&
                  //         release.stageReleaseEndDate !== "DD/MM/YYYY" &&
                  //         release.stageReleaseEndTime !== "-" &&
                  //         release.stageOfferName !== "" &&
                  //         release.stageQuantity !== "" &&
                  //         release.stagePrice !== ""
                  //     );

                  //     release.activateNextRelease = Boolean(
                  //       release.stageReleaseDate !== "DD/MM/YYYY" &&
                  //         release.stageReleaseTime !== "-" &&
                  //         release.stageReleaseEndDate !== "DD/MM/YYYY" &&
                  //         release.stageReleaseEndTime !== "-" &&
                  //         release.stageOfferName !== "" &&
                  //         release.stageQuantity !== "" &&
                  //         release.stagePrice !== ""
                  //     );
                  //   });

                  //   return ticketType;
                  // });

                  tempArray[index].toHaveAccessibleErrorMessageTicket =
                    tempArray[index].toHaveAccessibleErrorMessageTicket?.filter(
                      (item) => item !== "stageReleasesCountZero"
                    );

                  tempArray[index].stageReleases.push({
                    stageOfferName: "",
                    stageQuantity: "",
                    stageReleaseDate:
                      tempArray[index].stageReleases[indd].stageReleaseEndDate,
                    // indd === 0
                    //   ? "DD/MM/YYYY"
                    //   : tempArray[index].stageReleases[indd]
                    //       ?.activateNextRelease === false
                    //   ? tempArray[index].stageReleases[indd]
                    //       .stageReleaseEndDate
                    //   : "DD/MM/YYYY",
                    stageReleaseTime:
                      tempArray[index].stageReleases[indd].stageReleaseEndTime,
                    // indd === 0
                    //   ? "-"
                    //   : tempArray[index].stageReleases[indd]
                    //       ?.activateNextRelease === false
                    //   ? tempArray[index].stageReleases[indd]
                    //       .stageReleaseEndTime
                    //   : "-",
                    stageReleaseEndDate: "DD/MM/YYYY",
                    stageReleaseEndTime: "-",
                    stageCurrency:
                      eventCurrency === "Select an option"
                        ? "-"
                        : eventCurrency,
                    stageCurrencyId: 0,
                    stagePrice: "",
                    showActivateNextRelease: false,
                    activateNextRelease:
                      indd === 0
                        ? null
                        : tempArray[index].stageReleases[indd]
                            ?.activateNextRelease,
                    autoTriggerNextRelease: true,
                    toHaveAccessibleErrorMessageStageRelease: [],
                  });

                  // tempArray[index].stageReleases.push({
                  //   stageOfferName: "",
                  //   stageQuantity: "",
                  //   stageReleaseDate: "DD/MM/YYYY",
                  //   stageReleaseTime: "-",
                  //   stageReleaseEndDate: "DD/MM/YYYY",
                  //   stageReleaseEndTime: "-",
                  //   stageCurrency:
                  //     eventCurrency === "Select an option"
                  //       ? "-"
                  //       : eventCurrency,
                  //   stagePrice: "",
                  //   stageCurrencyId: 0,
                  //   showActivateNextRelease: false,
                  //   activateNextRelease: null,
                  //   autoTriggerNextRelease: true,
                  //   toHaveAccessibleErrorMessageStageRelease: [],
                  // });
                  setTicketList(tempArray);
                }}
                onDeleteStageReleaseClicked={(ind) => {
                  setSelectedTicketIndex(index);
                  setSelectedStageReleaseIndex(ind);
                  setIsDeleteModalVisible("stageRelease");
                }}
                onDeleteClicked={() => {
                  setSelectedTicketIndex(index);
                  setIsDeleteModalVisible("ticket");
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderAddOn = () => {
    return (
      <div>
        {ticketAddOnList.length === 0 ? (
          <div className="add-on-empty-container">
            <Text marginBottom={16} variant={"m500"}>
              {"Add-ons (optional)"}
            </Text>

            <Text marginBottom={40} variant={"r300"}>
              {"Create customised Add Ons and attach them to your tickets"}
            </Text>

            <ButtonPrimary
              isSecondary={true}
              label={"Create add-on"}
              width={328}
              onPress={() => {
                const tempEventOptions = [...eventOptions];
                tempEventOptions[5].isCompleted = false;
                setEventOptions(tempEventOptions);
                let tempArray = [...ticketAddOnList];
                tempArray.push({
                  name: "",
                  addOnImage: "",
                  addOnBase64Image: "",
                  quantity: "",
                  description: "",
                  currency:
                    eventCurrency === "Select an option" ? "-" : eventCurrency,
                  price: "",
                  addedTo: ["Select an option"],
                  addedToId: [
                    {
                      eventTicketTypeId: 0,
                      eventTicketTypeIndex: "",
                    },
                  ],
                  isSaved: false,
                  toHaveAccessibleErrorMessageTicketAddOn: [],
                  addOnCategoryList: addOnCategoryList || [],
                });
                setTicketAddOnList(tempArray);
              }}
            />
          </div>
        ) : (
          ticketAddOnList.map((item, index) => {
            return (
              <div>
                {/* <TicketAddOn
                  index={index}
                  addOnName={item.name}
                  addOnQuantity={item.quantity}
                  addOnDescription={item.description}
                  currencyList={[]}
                  addOnCategoryList={item.addOnCategoryList || []}
                  addOnCurrency={item.currency}
                  addOnPrice={item.price}
                  addOnAddedTo={item.addedTo}
                  toHaveAccessibleErrorMessageTicketAddOn={
                    item.toHaveAccessibleErrorMessageTicketAddOn
                  }
                  onNameChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].name = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "name"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onQuantityChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].quantity = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "quantity"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onDescriptionChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].description = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "description"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onCurrencyChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].currency = value;
                    setTicketAddOnList(tempArray);
                  }}
                  onPriceChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].price = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "price"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onAddedToChange={(value, ind, rowIndex) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].addedTo[rowIndex] = value;
                    tempArray[index].addedToId[rowIndex].eventTicketTypeIndex =
                      addOnCategoryList.findIndex(
                        (item) => item.name === value
                      );
                    const filteredArr = addOnCategoryList.filter(
                      (items) => !item.addedTo?.includes(items.name)
                    );
                    tempArray[index].addOnCategoryList = filteredArr;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "addedTo"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  shouldShowSubmitButton={false}
                  showAddAnotherAddOn={
                    ticketAddOnList[index].addedTo[
                      ticketAddOnList[index].addedTo.length - 1
                    ] !== "Select an option" ||
                    ticketAddOnList[index].addedTo.length === 0
                      ? true
                      : false
                  }
                  addAnotherAddOn={() => {
                    let tempArray = [...ticketAddOnList];
                    tempArray.push({
                      name: "",
                      quantity: "",
                      description: "",
                      currency: "£",
                      price: "",
                      addedTo: ["Select an option"],
                      addedToId: [
                        {
                          eventTicketTypeId: 0,
                          eventTicketTypeIndex: "",
                        },
                      ],
                      isSaved: false,
                      toHaveAccessibleErrorMessageTicketAddOn: [],
                      addOnCategoryList: addOnCategoryList || [],
                    });
                    setTicketAddOnList(tempArray);
                  }}
                  onRemoveAddOnClicked={(ind) => {
                    let tempArray = [...ticketAddOnList];
                    const newAddedToArray = [
                      ...tempArray[index].addedTo.slice(0, ind),
                      ...tempArray[index].addedTo.slice(ind + 1),
                    ];
                    const newAddedToIdArray = [
                      ...tempArray[index].addedToId.slice(0, ind),
                      ...tempArray[index].addedToId.slice(ind + 1),
                    ];
                    tempArray[index].addedTo = newAddedToArray;
                    tempArray[index].addedToId = newAddedToIdArray;

                    const filteredArr = addOnCategoryList.filter(
                      (items) => !item.addedTo?.includes(items.name)
                    );

                    tempArray[index].addOnCategoryList = filteredArr;
                    setTicketAddOnList(tempArray);
                  }}
                  onAddAnotherAddOnClicked={() => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].addedTo?.push("Select an option");
                    tempArray[index].addedToId?.push({
                      eventTicketTypeId: 0,
                      eventTicketTypeIndex: "",
                    });
                    setTicketAddOnList(tempArray);
                  }}
                  onDeleteClicked={() => {
                    setSelectedAddOnIndex(index);
                    setIsDeleteModalVisible("ticketAddOn");
                  }}
                /> */}
                <TicketAddOn
                  index={index}
                  addOnName={item.name}
                  addOnImage={item.addOnImage}
                  addOnQuantity={item.quantity}
                  addOnDescription={item.description}
                  currencyList={[]}
                  addOnCategoryList={item.addOnCategoryList || []}
                  addOnCurrency={item.currency}
                  addOnPrice={item.price}
                  addOnAddedTo={item.addedTo}
                  toHaveAccessibleErrorMessageTicketAddOn={
                    item.toHaveAccessibleErrorMessageTicketAddOn
                  }
                  onNameChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].name = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "name"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onAddOnImageChange={(value) => { 
                    if (value) {
                    setToCropImage(value);
                    setSelectedAddOnIndex(index);
                    setIsCustomModalVisible("addOnImageCrop");
                  } else {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].addOnImage = value;
                    setTicketAddOnList(tempArray);
                  }
                }}
                  onQuantityChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].quantity = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "quantity"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onDescriptionChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].description = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "description"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onCurrencyChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].currency = value;
                    setTicketAddOnList(tempArray);
                  }}
                  onPriceChange={(value) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].price = value;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "price"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  onAddedToChange={(value, ind, rowIndex) => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].addedTo[rowIndex] = value;
                    tempArray[index].addedToId[rowIndex].eventTicketTypeIndex =
                      addOnCategoryList.findIndex(
                        (item) => item.name === value
                      );
                    const filteredArr = addOnCategoryList.filter(
                      (items) => !item.addedTo?.includes(items.name)
                    );
                    tempArray[index].addOnCategoryList = filteredArr;
                    tempArray[index].toHaveAccessibleErrorMessageTicketAddOn =
                      tempArray[
                        index
                      ].toHaveAccessibleErrorMessageTicketAddOn.filter(
                        (item) => item !== "addedTo"
                      );
                    setTicketAddOnList(tempArray);
                  }}
                  shouldShowSubmitButton={false}
                  showAddAnotherAddOn={
                    ticketAddOnList[index].addedTo[
                      ticketAddOnList[index].addedTo.length - 1
                    ] !== "Select an option" ||
                    ticketAddOnList[index].addedTo.length === 0
                      ? true
                      : false
                  }
                  addAnotherAddOn={() => {
                    let tempArray = [...ticketAddOnList];
                    tempArray.push({
                      name: "",
                      quantity: "",
                      description: "",
                      currency: "£",
                      price: "",
                      addedTo: ["Select an option"],
                      addedToId: [
                        {
                          eventTicketTypeId: 0,
                          eventTicketTypeIndex: "",
                        },
                      ],
                      isSaved: false,
                      toHaveAccessibleErrorMessageTicketAddOn: [],
                      addOnCategoryList: addOnCategoryList || [],
                    });
                    setTicketAddOnList(tempArray);
                  }}
                  onRemoveAddOnClicked={(ind) => {
                    let tempArray = [...ticketAddOnList];
                    const newAddedToArray = [
                      ...tempArray[index].addedTo.slice(0, ind),
                      ...tempArray[index].addedTo.slice(ind + 1),
                    ];
                    const newAddedToIdArray = [
                      ...tempArray[index].addedToId.slice(0, ind),
                      ...tempArray[index].addedToId.slice(ind + 1),
                    ];
                    tempArray[index].addedTo = newAddedToArray;
                    tempArray[index].addedToId = newAddedToIdArray;

                    const filteredArr = addOnCategoryList.filter(
                      (items) => !item.addedTo?.includes(items.name)
                    );

                    tempArray[index].addOnCategoryList = filteredArr;
                    setTicketAddOnList(tempArray);
                  }}
                  onAddAnotherAddOnClicked={() => {
                    let tempArray = [...ticketAddOnList];
                    tempArray[index].addedTo?.push("Select an option");
                    tempArray[index].addedToId?.push({
                      eventTicketTypeId: 0,
                      eventTicketTypeIndex: "",
                    });
                    setTicketAddOnList(tempArray);
                  }}
                  onDeleteClicked={() => {
                    setSelectedAddOnIndex(index);
                    setIsDeleteModalVisible("ticketAddOn");
                  }}
                />
              </div>
            );
          })
        )}
      </div>
    );
  };

  const renderAddBolt = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 24,
          marginBottom: 12,
        }}
      >
        <RadioButton
          twoColumn={true}
          vertically={true}
          data={kYCOptions}
          selectIndex={
            ticketList[
              parseInt(
                String(isDeleteModalVisible)?.substring(
                  String(isDeleteModalVisible)?.indexOf("addBolt") + 7
                )
              )
            ]?.isAddOn === true
              ? 0
              : ticketList[
                  parseInt(
                    String(isDeleteModalVisible)?.substring(
                      String(isDeleteModalVisible)?.indexOf("addBolt") + 7
                    )
                  )
                ]?.isAddOn === false
              ? 1
              : null
          }
          onSelect={(item, index) => {
            let tempArray = [...ticketList];
            tempArray[
              parseInt(
                String(isDeleteModalVisible)?.substring(
                  String(isDeleteModalVisible)?.indexOf("addBolt") + 7
                )
              )
            ].isAddOn = item?.value;
            setTicketList(tempArray);
          }}
        />
      </div>
    );
  };

  const renderCustomeCategory = () => {
    return <div></div>;
  };

  const renderCompleteAccountSetup = () => {
    return (
      <div className="account-verify-setup-container">
        <div className="generic-common-divider" />
        <div style={{ paddingTop: "24px" }}>
          <Text variant="r300">
            To publish your event, please complete your account setup by{" "}
            <Text variant="b300" style={{ display: "inline" }}>
              verifying your identity
            </Text>{" "}
            and
            <Text variant="b300" style={{ display: "inline" }}>
              {" "}
              setting up a payment gateway
            </Text>
            . Once done, you can return to publish your event. For now, it’s
            saved as a draft and remains accessible.
          </Text>
        </div>

        {userInfo?.kycStatus !== "Verified" ? (
          <div className="account-verifications-complete-account-setup-container">
            <div className="generic-common-column-div">
              <Text variant="b300">Account verification</Text>
              <Text marginTop={16} variant="r200">
                As a security measure, event promoters are required to verify
                their accounts with government-issued ID.
              </Text>
            </div>
            <ButtonPrimary
              width={198}
              style={{ marginLeft: "24px" }}
              label={"Verify account"}
              onPress={() => verifyAccountRequest()}
            />
          </div>
        ) : null}

        {isStripeDetailsSubmitted !== true ? (
          <div className="account-verifications-complete-account-setup-container">
            <div className="generic-common-column-div">
              <Text variant="b300">Payment gateway setup</Text>
              <Text marginTop={16} variant="r200">
                A payment gateway is needed to securely link ticket sales and
                fees to your events.
              </Text>
            </div>
            <ButtonPrimary
              width={244}
              style={{ marginLeft: "24px" }}
              label={"Set up payment gateway"}
              onPress={() => stripeConnect()}
            />
          </div>
        ) : null}

        <ButtonPrimary
          width={310}
          isSecondary={true}
          label={"Back to event"}
          style={{ marginTop: "32px" }}
          onPress={() => {
            localStorage.setItem("isAccountVerificationRunning", true);
            navigate(`/EventDetails/${navigationParamsEventDetails?.eventId}`, {
              state: navigationParamsEventDetails,
              nonActiveEventDetails: nonActiveEventDetails,
            });
          }}
        />
      </div>
    );
  };

  const renderCropEventBanner = () => {
    return (
      <div
        className="crop-event-banner-container"
        style={{
          margin: "17px",
          width: "700px",
          height: "393.75",
          alignSelf: "center",
        }}
      >
        <Cropper
          // image="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
          image={toCropImage}
          crop={crop}
          zoom={zoom}
          aspect={isCustomModalVisible === "posterCrop" ? 16 / 9 : 1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          ref={imageRef}
        />

        <input
          style={{ width: "100%", marginTop: 24 }}
          type="range"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e) => {
            setZoom(e.target.value);
          }}
          className="zoom-range"
        />

        <div
          className="central-bottom-button-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 24,
            marginBottom: 0,
            justifyContent: "flex-end",
          }}
        >
          <div className="generic-common-row-div">
            <ButtonPrimary
              label={"Use original"}
              onPress={() => {
                setIsCustomModalVisible(false);
               if(isCustomModalVisible === "posterCrop")
               {
                setBannerImage(toCropImage)
               }
               else
               {
                console.log("selectedAddOnIndex", selectedAddOnIndex, toCropImage)
                let tempArray = [...ticketAddOnList];
                    tempArray[selectedAddOnIndex].addOnImage = toCropImage;
                    setTicketAddOnList(tempArray);
               }
                
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              label={"Save"}
              onPress={() => {
                showCroppedImage();
                setIsCustomModalVisible(false);
              }}
              style={{ marginRight: 16 }}
            />
            <ButtonPrimary
              type={"error"}
              isSecondary={true}
              label={"Cancel"}
              onPress={() => setIsCustomModalVisible(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-wrapper-event">
      <CentralCustomModal
        titleVariant={"m500"}
        title={
          isCustomModalVisible === "posterCrop"
            ? "Resize Poster"
            : isCustomModalVisible === "addOnImageCrop"
            ? "Resize Add On Image"
            : "Complete your account setup"
        }
        show={
          isCustomModalVisible === "posterCrop" ||
          isCustomModalVisible === "addOnImageCrop" ||
          isCustomModalVisible === "completeAccountSetup"
        }
        onClose={() =>
          (isCustomModalVisible === "posterCrop" || isCustomModalVisible === "addOnImageCrop")
            ? setIsCustomModalVisible(false)
            : (localStorage.setItem("isAccountVerificationRunning", true),
              navigate(
                `/EventDetails/${navigationParamsEventDetails?.eventId}`,
                {
                  state: navigationParamsEventDetails,
                }
              ))
        }
        showDividerTop={false}
        renderContent={() =>
          (isCustomModalVisible === "posterCrop" || isCustomModalVisible === "addOnImageCrop")
            ? renderCropEventBanner()
            : renderCompleteAccountSetup()
        }
        isDynamicSize={(isCustomModalVisible === "posterCrop" || isCustomModalVisible === "addOnImageCrop") ? false : true}
      />
      <CentralModalWithInput
        title={"Create category"}
        inputTitle={"Category name"}
        inputPlaceholder={"Enter category name"}
        inputValue={
          ticketList[selectedTicketIndex]?.category === "-" ||
          ticketList[selectedTicketIndex]?.category === "Select an option"
            ? ""
            : ticketList[selectedTicketIndex]?.category
        }
        onChangeInputValue={(value) => setNewTicketCategoryValue(value)}
        show={isCategoryModalVisible}
        onClose={() => setIsCategoryModalVisible(false)}
        renderContent={() => renderCustomeCategory()}
        shouldShowButtons={true}
        button1Title={"Cancel"}
        button2Title={"Create category"}
        onButton1Clicked={() => {
          setIsCategoryModalVisible(false);
        }}
        onButton2Clicked={() => {
          if (newTicketCategoryValue?.trim() !== "") {
            setIsCategoryModalVisible(false);
            let tempArray = [...ticketList];
            const tempTicketCategoryArray = [...ticketCategoryList];
            tempTicketCategoryArray.push({
              name: newTicketCategoryValue,
            });
            const uniqueTempTicketCategoryArray =
              Array.isArray(tempTicketCategoryArray) &&
              tempTicketCategoryArray.length > 0
                ? tempTicketCategoryArray.filter(
                    (item, index, self) =>
                      index === self.findIndex((t) => t.name === item.name)
                  )
                : [];
            tempArray[selectedTicketIndex].toHaveAccessibleErrorMessageTicket =
              tempArray[
                selectedTicketIndex
              ]?.toHaveAccessibleErrorMessageTicket?.filter(
                (item) => item !== "category"
              );
            tempArray[selectedTicketIndex].category = newTicketCategoryValue;
            setTicketList(tempArray);

            const filteredList = Array.isArray(uniqueTempTicketCategoryArray)
              ? uniqueTempTicketCategoryArray?.filter(
                  (category) => category.name !== "+ Create category"
                )
              : [];

            setTicketCategoryList([
              ...filteredList,
              { name: "+ Create category" },
            ]);
            // setTicketCategoryList(uniqueTempTicketCategoryArray);
          }
        }}
      />
      <CentralModalSuccess
        icon={
          isDeleteModalVisible === "draft" ? (
            <EditFilled height={24} width={24} />
          ) : String(isDeleteModalVisible)?.includes("addBolt") ? (
            <AddFilled height={24} width={24} />
          ) : (
            <BinFilled height={24} width={24} />
          )
        }
        title={
          isDeleteModalVisible === "entrance"
            ? "Remove entrance?"
            : isDeleteModalVisible === "draft"
            ? "Save your draft?"
            : isDeleteModalVisible === "publish"
            ? "Publish event?"
            : String(isDeleteModalVisible)?.includes("addBolt")
            ? "Add Bolt On"
            : isDeleteModalVisible === "ticketAddOn"
            ? "Remove Add On?"
            : isDeleteModalVisible === "stageRelease"
            ? "Remove staged release?"
            : "Remove ticket?"
        }
        description={
          isDeleteModalVisible === "entrance"
            ? "Are you sure you want to remove this entrance from your event?"
            : isDeleteModalVisible === "draft"
            ? "Are you sure you want to save this event as a draft? You will need to fill in all required details to publish this event."
            : isDeleteModalVisible === "publish"
            ? userInfo?.kycStatus !== "Verified"
              ? "To publish this event, please complete the identity verification process first."
              : "Are you sure you want to publish this event? Please ensure all details are correct before publishing."
            : String(isDeleteModalVisible)?.includes("addBolt")
            ? "Select an option below"
            : isDeleteModalVisible === "ticketAddOn"
            ? "Are you sure you want to remove this add on from your event?"
            : isDeleteModalVisible === "stageRelease"
            ? "Are you sure you want to remove this staged release from your ticket?"
            : "Are you sure you want to remove this ticket from your event?"
        }
        shouldShowButtons={true}
        button1Title={
          isDeleteModalVisible === "entrance"
            ? "Yes, remove entrance"
            : isDeleteModalVisible === "draft"
            ? "Save draft"
            : isDeleteModalVisible === "publish"
            ? userInfo?.kycStatus !== "Verified"
              ? "Cancel"
              : "No, cancel"
            : String(isDeleteModalVisible)?.includes("addBolt")
            ? "Confirm Bolt On"
            : isDeleteModalVisible === "ticketAddOn"
            ? "Yes, remove add on"
            : "Yes, remove"
        }
        button2Title={
          isDeleteModalVisible === "draft" ||
          String(isDeleteModalVisible)?.includes("addBolt")
            ? "Cancel"
            : isDeleteModalVisible === "publish"
            ? userInfo?.kycStatus !== "Verified"
              ? "Verify now"
              : "Yes, publish"
            : "No, cancel"
        }
        onButton1Clicked={() => {
          if (isDeleteModalVisible === "entrance") {
            const newArray = [
              ...entranceList.slice(0, selectedEntranceIndex),
              ...entranceList.slice(selectedEntranceIndex + 1),
            ];
            setEntranceList(newArray);
          } else if (isDeleteModalVisible === "ticket") {
            const newArray = [
              ...ticketList.slice(0, selectedTicketIndex),
              ...ticketList.slice(selectedTicketIndex + 1),
            ];
            setTicketList(newArray);
          } else if (isDeleteModalVisible === "stageRelease") {
            const newData = ticketList.map((item, index) => {
              if (index === selectedTicketIndex) {
                return {
                  ...item,
                  stageReleases: item.stageReleases.filter(
                    (innerItem, innerIndex) =>
                      innerIndex !== selectedStageReleaseIndex
                  ),
                };
              } else {
                // If the index doesn't match, keep the item as it is
                return item;
              }
            });
            setTicketList(newData);
          } else if (isDeleteModalVisible === "ticketAddOn") {
            const newArray = [
              ...ticketAddOnList.slice(0, selectedAddOnIndex),
              ...ticketAddOnList.slice(selectedAddOnIndex + 1),
            ];
            setTicketAddOnList(newArray);
          } else {
            isDeleteModalVisible === "draft" &&
              createEventRequest(
                "draft",
                shouldShowPublishEventButton ? true : false
              );
          }
          setIsDeleteModalVisible(false);
        }}
        onButton2Clicked={() => {
          isDeleteModalVisible === "publish" && createEventRequest("publish");
          setIsDeleteModalVisible(false);
        }}
        show={
          isDeleteModalVisible === "entrance" ||
          isDeleteModalVisible === "ticket" ||
          isDeleteModalVisible === "stageRelease" ||
          isDeleteModalVisible === "draft" ||
          isDeleteModalVisible === "ticketAddOn" ||
          isDeleteModalVisible === "publish" ||
          String(isDeleteModalVisible)?.includes("addBolt")
            ? true
            : false
        }
        onClose={() => setIsDeleteModalVisible(false)}
        renderContent={() =>
          String(isDeleteModalVisible)?.includes("addBolt")
            ? renderAddBolt()
            : null
        }
        isDisabledButton1={
          String(isDeleteModalVisible)?.includes("addBolt") &&
          ticketList[
            parseInt(
              String(isDeleteModalVisible)?.substring(
                String(isDeleteModalVisible)?.indexOf("addBolt") + 7
              )
            )
          ]?.isAddOn === null
            ? true
            : false
        }
        isUnsavedDataModal={isDeleteModalVisible === "unsavedChanges"}
        onConfirmUnsavedData={() => {}}
      />
      <div className="main-body-content">
        <div className="home-header-sideNav">
          <SideBar
            onChangeOption={(index) => handleNavigation("/Home", index)}
            createEventClicked={() => handleNavigation("/CreateEvent")}
            onGoToEventClicked={(eventId) =>
              navigate(`/EventDetails/${eventId}`, {
                state: {
                  eventId: eventId,
                },
              })
            }
          />
        </div>
        <div className="event-content-div rht-body-inner-content">
          <HeaderNew
            btn1Title={eventName ? "Save & Exit" : false}
            btn2Title={"Discard"}
            btn3Title={
              shouldShowPublishEventButton &&
              userInfo?.kycStatus === "Verified" &&
              isStripeDetailsSubmitted === true &&
              selectedEventOptionIndex === 5
                ? "Publish"
                : (userInfo?.kycStatus !== "Verified" ||
                    isStripeDetailsSubmitted !== true) &&
                  selectedEventOptionIndex === 5 &&
                  shouldShowPublishEventButton
                ? "Complete"
                : ""
            }
            btn1Clicked={() => setIsDeleteModalVisible("draft")}
            btn2Clicked={() => navigate("/Home")}
            btn3Clicked={() =>
              (userInfo?.kycStatus !== "Verified" ||
                isStripeDetailsSubmitted !== true) &&
              selectedEventOptionIndex === 5 &&
              shouldShowPublishEventButton
                ? createEventRequest("draft")
                : setIsDeleteModalVisible("publish")
            }
            mennLabelArray={menuLinkOptions}
            onMenuLinkPress={(ind) => ind === 0 && handleNavigation("/Home")}
            headerTitle="Create an event"
            subHeaderTitle={"Provide all the details below for this event"}
            eventSectionOptions={eventOptions}
            selectedEventOptionIndex={selectedEventOptionIndex}
            segmentBtnPressedEvent={(ind) => segmentBtnPressedEvent(ind)}
          />

          {selectedEventOptionIndex === 0 && renderEventDetails()}
          {selectedEventOptionIndex === 1 && renderVenue()}
          {selectedEventOptionIndex === 2 && renderRequirement()}
          {selectedEventOptionIndex === 3 && renderFees()}
          {selectedEventOptionIndex === 4 && renderTicket()}
          {selectedEventOptionIndex === 5 && renderAddOn()}

          <div className="createEvnSD-btn-area">
            {(selectedEventOptionIndex === 4 ||
              (selectedEventOptionIndex === 5 &&
                ticketAddOnList.length > 0)) && (
              <ButtonPrimary
                isSecondary={true}
                label={
                  selectedEventOptionIndex === 4
                    ? "Add another ticket"
                    : "Create another Add On"
                }
                onPress={() => {
                  if (selectedEventOptionIndex === 5) {
                    const tempEventOptions = [...eventOptions];
                    tempEventOptions[5].isCompleted = false;
                    setEventOptions(tempEventOptions);
                    let tempArray = [...ticketAddOnList];
                    tempArray.push({
                      name: "",
                      addOnImage: "",
                      addOnBase64Image: "",
                      quantity: "",
                      description: "",
                      currency:
                        eventCurrency === "Select an option"
                          ? "-"
                          : eventCurrency,
                      price: "",
                      addedTo: ["Select an option"],
                      addedToId: [
                        {
                          eventTicketTypeId: 0,
                          eventTicketTypeIndex: "",
                        },
                      ],
                      isSaved: false,
                      toHaveAccessibleErrorMessageTicketAddOn: [],
                      addOnCategoryList: addOnCategoryList || [],
                    });
                    setTicketAddOnList(tempArray);
                  } else {
                    let tempArray = [...ticketList];
                    tempArray.push({
                      releaseType: "automatic",
                      releaseDate: "DD/MM/YYYY",
                      releaseTime: "-",
                      releaseEndDate: "DD/MM/YYYY",
                      releaseEndTime: "-",
                      name: "",
                      quantity: "",
                      description: "",
                      category: "Select an option",
                      currency:
                        eventCurrency === "Select an option"
                          ? "-"
                          : eventCurrency,
                      price: "",
                      selectedAgeIndex: null,
                      minimumAge: "",
                      maximumAge: "",
                      stageReleases: [
                        {
                          stageOfferName: "",
                          stageQuantity: "",
                          stageReleaseDate: "DD/MM/YYYY",
                          stageReleaseTime: "-",
                          stageReleaseEndDate: "DD/MM/YYYY",
                          stageReleaseEndTime: "-",
                          stageCurrency:
                            eventCurrency === "Select an option"
                              ? "-"
                              : eventCurrency,
                          stagePrice: "",
                          stageCurrencyId: 0,
                          showActivateNextRelease: false,
                          activateNextRelease: null,
                          toHaveAccessibleErrorMessageStageRelease: [],
                        },
                      ],
                      isSaved: false,
                      currencyId: 0,
                      isAddOn: null,
                      addOnAvailabilities: [],
                    });
                    setTicketList(tempArray);
                  }
                }}
                width={308}
                style={{ marginRight: 32 }}
              />
            )}

            <ButtonPrimary
              label={
                (userInfo?.kycStatus !== "Verified" ||
                  isStripeDetailsSubmitted !== true) &&
                selectedEventOptionIndex === 5 &&
                shouldShowPublishEventButton
                  ? "Complete event"
                  : "Save & Continue"
              }
              onPress={
                () => {
                  if (
                    (userInfo?.kycStatus !== "Verified" ||
                      isStripeDetailsSubmitted !== true) &&
                    selectedEventOptionIndex === 5 &&
                    shouldShowPublishEventButton &&
                    eventOptions[5].isCompleted
                  ) {
                    createEventRequest("draft");
                  } else if (
                    selectedEventOptionIndex === 5 &&
                    !shouldShowPublishEventButton
                  ) {
                    setIsDeleteModalVisible("draft");
                  } else {
                    onSaveAndContinueClicked();
                  }
                }
                // (userInfo?.kycStatus !== "Verified" ||
                //   isStripeDetailsSubmitted !== true) &&
                // selectedEventOptionIndex === 5 &&
                // shouldShowPublishEventButton
                //   ? (setIsCustomModalVisible("completeAccountSetup"),
                //     createEventRequest("draft"))
                //   : selectedEventOptionIndex === 5 &&
                //     shouldShowPublishEventButton
                //   ? setIsDeleteModalVisible("draft")
                //   : onSaveAndContinueClicked()
              }
              width={308}
              disabled={false}
              style={{ marginRight: 0 }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default CreateEvent;
